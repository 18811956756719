<template>
    <div v-if="arena && arena.marketing" class="ArenaFactBox">
        <div class="container pt-5 pb-5 has-text-white">
            <h3 class="subtitle is-size-3 has-text-grey-light">{{ $t('quick-facts')}}</h3>
            <div class="columns">
                <div class="column is-one-third">
                    <table style="width: 100%">
                        <tr v-for="f in availableFacts" :key="f">
                            <td>{{ $t('fact-' + f) }}</td>
                            <td>{{ atranslate(arena, ['marketing', 'facts', f], '-') }}</td>
                        </tr>
                    </table>
                </div>
                <div class="column is-two-thirds">
                    <p style="white-space: pre-wrap;">
                        {{ atranslate(arena, ['marketing', 'accessibilityText']) }}
                    </p>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { store } from '../store.js'
//import Radar from 'vue-radar'

export default {
    name: 'ArenaFactBox',
    props: ['arena'],
    data () {
        return {
            tab: 'details',
            stats: [
                {name: 'Scary', value: 82, shortName: 'Hej'},
                {name:'Paralell tracks', value:28, shortName: 'Hopp'},
                {name:'Difficulty', value:50, shortName: 'Happ'},
                {name:'Cooperation', value:20, shortName: 'Burk'},
                {name:'Mathematics', value:15, shortName: 'Gurk'},
                {name:'Language skills', value:98, shortName: 'Scary'}
            ],
            availableFacts: [
                'year',
                'setting',
                'geographic-location',
                'time-of-day',
                'decoration-language',
                'lighting',
                'time-limit',
                'room-count'
            ]
        }
    },
  components: {
//      'v-radar': Radar
  },
  computed: {
      language: function () {
          return store.language
      }
  }
}
</script>
<style>
</style>
