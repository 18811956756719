<template>
	<SplashHeader class="pageheader" :image="splashImage" :title="$t('toc-header')"></SplashHeader>
	<div class="content">
		<div class="columns">
			<div class="column box">
				<h3 class="heading is-3">Cancellation and payment</h3>
				<p>

				</p>
			</div>
			<div class="column box">
				<h3 class="heading is-3">Damages</h3>
				<p>

				</p>
			</div>
			<div class="column">

			</div>
		</div>
	</div>
</template>
<script>
	import { store } from '../store'
	import axios from 'axios'
	import SplashHeader from '../components/SplashHeader.vue'
	import dayjs from 'dayjs'
	export default {
		name: 'BookingTocPage',
		data: function () {
			return {
				weather: undefined
			}
		},
		components: {
			SplashHeader
		},
		methods: {
			arenaSlots (arena) {
				return this.slots.filter(a => a.arena == arena.handle)
			},
			arenaBookedSlots (arena) {
				return this.bookedSlots.filter(a => a.arena == arena.handle)
			},
			arenaMergedSlots (arena) {
				return this.arenaSlots(arena).concat(this.arenaBookedSlots(arena)).sort((a, b) => {
					return this.hhmm2min(a.startTime) - this.hhmm2min(b.startTime)
				})
			}
		},
		computed: {
			weatherHeading () {
				return this.$t('today-weather-heading-general')
			},
			forecast () {
				return this.weather.hourly.map(hour => {
					return {
						time: dayjs.unix(hour.dt),
						main: hour.weather[0].main,
						temp: Math.round(hour.temp - 273),
						icon: 'http://openweathermap.org/img/wn/' + hour.weather[0].icon + '@2x.png'
					}
				}).filter(h => h.time < dayjs().hour(22))
			},
			splashImage () {
				return store.customization.startsplash
			},
			arenas () {
				return store.arenas
			},
			schedule () {
				return store.today ? store.today.schedule : undefined
			},
			slots () {
				return store.today ? store.today.slots : []
			},
			bookedSlots () {
				return store.today ? store.today.bookedSlots : []
			},
		},
		mounted: function () {
			axios.get('/apuz/weather.json').then(result => {
				this.weather = result.data.weather
			})
		}
	}
</script>
<style scoped>
</style>
