import { render } from "./ContentPage.vue?vue&type=template&id=5b36ab44"
import script from "./ContentPage.vue?vue&type=script&lang=js"
export * from "./ContentPage.vue?vue&type=script&lang=js"

import "./ContentPage.vue?vue&type=style&index=0&lang=css"
script.render = render
/* hot reload */
if (module.hot) {
  script.__hmrId = "5b36ab44"
  const api = __VUE_HMR_RUNTIME__
  module.hot.accept()
  if (!api.createRecord('5b36ab44', script)) {
    api.reload('5b36ab44', script)
  }
  
  module.hot.accept("./ContentPage.vue?vue&type=template&id=5b36ab44", () => {
    api.rerender('5b36ab44', render)
  })

}

script.__file = "src/views/ContentPage.vue"

export default script