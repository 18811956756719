<template>
    <div class="content-page">
        <div class="cover">
            <img :src="cover" :style="coverstyle" />
            <h1>{{ title }}</h1>
        </div>
        <div class="container px-3">
            <div class="content" v-html="content"></div>
        </div>
    </div>
</template>
<script>
    import { store } from '../store'

    export default {
        name: 'ContentPage',
        components: {
        },
        computed: {
            language: function () { return this.$route.params.language },
            handle: function () { return this.$route.params.handle },
            page: function () {
                if (store.pages[this.language] && store.pages[this.language][this.handle]) {
                    return store.pages[this.language][this.handle]
                }
                return {}
            },
            content: function () {
                return this.page ? this.page.content : undefined
            },
            title: function () {
                return this.page ? this.page.title : undefined
            },
            coverstyle () {
                if (this.page && this.page.covertop) {
                    let s = 'object-position: center ' + this.page.covertop + '%'
                    return s
                }
                return ''
            },
            cover () {
                return '/apuz/venue/static/images/' + (this.page.cover ? this.page.cover : 'footer.png')
            }
        }
    }

</script>
<style>
    .content-page .cover {
        position: relative;
        z-index: -1;
    }

    .content-page .cover h1 {
        position: absolute;
        top: calc(25vh - 20px);
        width: 100%;
        color: white;
        text-shadow: 0 0 5px black;
        text-align: center;
        font-size: 50px;
    }

    .content-page .cover img {
        width: 100%;
        height: 40vh;
        object-fit: cover;
    }

    .content-page {
        margin-bottom: 100px;
    }
</style>
