<template>
    <div class="arena-start">

      <div class="hero is-medium is-dark welcome" :style="splashstyle">
        <div class="hero-body">
          <div class="container ">
            <div class="has-text-centered" style="margin: 0 auto auto; max-width: 600px; text-shadow: 0 0 3px rgba(0, 0, 0, 1);">
              <h1 class="title">{{ $t('start-top-heading') }}</h1>
              <h2 class="subtitle">{{ $t('start-top-intro') }}</h2>
            </div>

            <div class="is-pulled-right mt-6" style="margin-bottom: -3rem;">
              <div v-for="m in startMessages" :key="m.text" class="box mt-6" :class="m.class" style="max-width: 500px;">
                {{ m.text }}
                <br /><br />
                <span class="author" v-if="m.author">{{ m.author }}</span>

                <figure v-if="m.avatar" class="image is-64x64 is-pulled-right">
                  <img class="is-rounded" :src="'/apuz/venue/static/images/' + m.avatar + '?w=100'" style="height: 100%; object-fit: cover;">
                </figure>

                <a v-if="m.href" class="has-text-link" style="display: block;" :href="m.href">
                  {{ m.linktext }}
                </a>
                <div class="is-clearfix"></div>
              </div>
            </div>
          </div>
        </div>
      </div>

			<div style="position: absolute; margin-top: -100px; width: 100%; min-height: 75px;">
				<div v-if="openStatus != 'unknown'" class="box has-background-primary has-text-white m-6 has-text-centered">
					<span v-if="openStatus == 'pending'">
						{{ $t('start-schedule-will-open', {opening: today.schedule.opening, closing: today.schedule.closing}) }}
					</span>
					<span v-if="openStatus == 'open'">
						{{ $t('start-schedule-open', {closing: today.schedule.closing}) }}
					</span>
					<span v-if="openStatus == 'closed'">
						{{ $t('start-schedule-closed-today') }}
					</span>
					&nbsp;
					<span v-if="['pending', 'open'].includes(openStatus) && today.slots.length > 0">
                        {{ $t('start-available-slots', {slots: today.slots.length}) }}
					</span>
					<span v-if="['pending', 'open'].includes(openStatus) && today.slots.length == 0">
						{{ $t('start-fully-booked') }}
					</span>
					<br />

					<router-link :to="bookingLink()">
						<button class="button is-small">{{ $t('book-now') }}</button> &nbsp;
					</router-link>

					<router-link :to="todayLink()">
						<button class="button is-small">{{ $t('todays-schedule') }}</button>
					</router-link>

				</div>
			</div>


      <template v-for="a in arenas">
        <StartPageArenaCountdown v-if="a.marketing.startPageLayout == 'countdown'" :arena="a" :key="a.handle" />
        <StartPageArenaDefault v-else :arena="a" :key="a.handle" />
      </template>

      <div class="has-background-black">
        <TeamphotoCollage></TeamphotoCollage>
      </div>

      <BookingSteps></BookingSteps>


      <section v-if="perfectPages.length > 0" class="has-background-dark has-text-white" style="padding-top: 10vh; padding-bottom: 10vh;">
        <div class="container">
          <h2 class="subtitle has-text-white">{{ $t('start-perfect-heading') }}</h2>
          <div class="columns">
            <div v-for="p in perfectPages" :key="p.handle" class="column">
              <a :href="'/' + p.language + '/pages/' + p.handle">
                <div class="card">
                  <figure class="image is-4by3">
                    <img :src="'/apuz/venue/static/images/' + p.cover + '?w=480'" style="object-fit: cover;"/>
                  </figure>
                  <div class="card-content">
                    <h5 class="subtitle">{{ p.title }}</h5>
                    <p>{{ p.intro }}</p>
                  </div>
                </div>
              </a>
            </div>
          </div>
        </div>
      </section>

      <AudienceCards></AudienceCards>


    </div>
</template>
<script>
    import TeamphotoCollage from '../components/TeamphotoCollage.vue'
    import BookingSteps from '../components/BookingSteps.vue'
    import StartPageArenaDefault from '../components/StartPageArenaDefault.vue'
    import StartPageArenaCountdown from '../components/StartPageArenaCountdown.vue'
    import AudienceCards from '../components/AudienceCards.vue'
    import { store } from '../store'

    export default {
        name: 'StartPage',
        data: function () {
          return {}
        },
        components: {
            TeamphotoCollage,
            StartPageArenaDefault,
            StartPageArenaCountdown,
            BookingSteps,
            AudienceCards
        },
        computed: {
					openStatus () {
						if (this.today) {

							const o = this.hhmm2min(this.today.schedule.opening)
							const c = this.hhmm2min(this.today.schedule.closing)

							const today = new Date()
							const mintoday = today.getMinutes() + 60 * today.getHours()


							if (c - 60 < o) {
								return 'closed'
							}
							if (o > mintoday) {
								return 'pending'
							}
							if (c > mintoday) {
								return 'open'
							}
							return 'closed'
						}
						else {
							return 'unknown'
						}
					},
					today () {
						return store.today
					},
          arenas: function () {
            return store.arenas
          },
          perfectPages: function () {
            return store.getPages().filter(p => p.startprio).sort((a, b) => a.startprio - b.startprio)
          },
          startMessages: function () {
            return store.startMessages.filter(m => m.language == store.language)
          },
          splashstyle () {
            return 'padding-top: 200px; padding-bottom: 200px; background-image: url("/apuz/venue/static/images/' + store.customization.startsplash + '"); background-size: cover; background-position: center center;'
          }
        },
        watch: {
          startMessages: function () {
            for (let i = 0; i < this.startMessages.length; i++) {
              setTimeout(() => { this.startMessages[i].class = 'zoomIn' }, 500 + 800 * i)
            }
          }
				},
				mounted () {
					setTimeout(() => {
						document.getElementById('metadescription').content = this.atranslate(store.venue, 'salesPitchText')
					}, 1000)
				}
    }
</script>
<style>
  .arena-hero {
    background-size: cover;
    background-position: center;
  }

  .arena-hero .hero-body {
    background: radial-gradient(circle, transparent 10%, black 75%);
    box-shadow: 0 0 50px inset black;
  }
  .arena-hero .hero-body .container {
    background: rgba(0, 0, 0, 0.45);
    border-radius: 3px;
  }

  .arena-start .opacity0 { opacity: 0; }

    .arena-start .welcome {
        background-size: cover;
    }

  .arena-start .shadow {
    text-shadow: 1px 1px 1px rgba(0, 0, 0, 1);
  }

    .arena-start .arenas {
        background: url('/apuz/venue/static/start/arena-list-bg.jpg') #232323;
        background-size: cover;
        padding-top: 5vh;
        padding-bottom: 5vh;
    }

   .arena-start .arenas h1 {
     text-align: center;
     color: white;
     font-size: 50px;
    }

</style>
