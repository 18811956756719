<template>
  <section class="hero is-info is-large booking-steps is-medium-section has-text-centered" :style="bgstyle">

    <div class="container is-max-desktop">
      <h3 class="title has-text-white is-size-3 pt-5 has-text-centered">{{ $t('booking-steps-title') }}</h3>
      <p class="py-5">{{ $t('booking-steps-intro') }}</p>

      <div class="content">
        <div class="columns is-multiline has-text-centered is-gapless">
        <div v-for="step in ['first', 'second', 'third', 'fourth']" class="column" :key="'booking-step-' + step">
          <i class="fa" :class="'fa-' + $t('booking-steps-' + step + '-icon')"></i>
          <h4 class="subtitle is-5">{{ $t('booking-steps-' + step + '-title') }}</h4>
          <p>{{ $t('booking-steps-' + step + '-text') }}</p>
        </div>
      </div>
      </div>
    </div>

    <div style="clear: both; height: 150px;"></div>

  </section>
</template>
<script>
  import { store } from '../store'
  export default {
    name: 'BookingSteps',
    computed: {
      language: function () { return store.language },
      bgstyle () {
        return 'padding-top: 200px; background-image: url("/apuz/venue/static/images/' + store.customization.whatisthisbg + '"); background-size: cover; background-position: center center;'
      }
    }
  }
</script>
<style>
.booking-steps {
  margin-top: -100px;
}

.booking-steps .columns:before {
  border-bottom: 2px dotted rgba(255, 255, 255, 0.2);
  width: 78%;
  height: 0px;
  position: absolute;
  top: 95px;
  content: '';
  left: 11%;
}
.booking-steps .fa {
  font-size: 50px;
  padding-top: 25px;
  padding-bottom: 25px;
}
</style>
