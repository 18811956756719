import { render } from "./EmailConfirmation.vue?vue&type=template&id=31db5d1e"
import script from "./EmailConfirmation.vue?vue&type=script&lang=js"
export * from "./EmailConfirmation.vue?vue&type=script&lang=js"
script.render = render
/* hot reload */
if (module.hot) {
  script.__hmrId = "31db5d1e"
  const api = __VUE_HMR_RUNTIME__
  module.hot.accept()
  if (!api.createRecord('31db5d1e', script)) {
    api.reload('31db5d1e', script)
  }
  
  module.hot.accept("./EmailConfirmation.vue?vue&type=template&id=31db5d1e", () => {
    api.rerender('31db5d1e', render)
  })

}

script.__file = "src/components/EmailConfirmation.vue"

export default script