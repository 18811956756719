<template>
  <VueWeb />
</template>

<script>
  import VueWeb from './components/VueWeb.vue'
  import { store } from './store'

  export default {
    name: 'ApuzVueweb',
    components: {
      VueWeb,
    },
    computed: {
      darkMode(){
        return store.darkMode
      },
      arenas: function () {
        return store.arenas
      }
    },
    watch: {
      darkMode(){
        if(store.darkMode){
          document.documentElement.classList.add('dark')
        }
        else {
          document.documentElement.classList.remove('dark')
        }
      }
    },
    mounted(){
      document.documentElement.setAttribute('data-theme', 'light')
    }
  }
</script>

<style>
html, body {
  margin: 0;
  font-family: verdana, Arial, Sans-serif;
}
</style>
