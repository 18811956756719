<template>
    <div class="arena-page has-background-black">
        <div :style="splashstyle" class="splash has-text-white">
            <h1 class="title is-size-1-tablet is-size-2-mobile has-text-white has-text-centered">
                {{ $at(arena, 'marketing.brandNames') }}
            </h1>
            <div style="max-width: 700px; margin: 10vh auto auto;" class="px-5">
                <p class="has-text-centered has-text-white is-size-4">
                    {{ $at(arena, 'marketing.shortStories') }}
                </p>
                <p class="pt-5">
                    {{ $at(arena, 'marketing.shortFacts') }}
                </p>
            </div>

            <div class="container" style="padding-top: 20vh; padding-bottom: 50vh;">
                <div class="columns">
                    <div class="column is-half">
                        <p class="is-size-4">
                            <i class="fa fa-user-friends fa-fw pr-5"></i> {{ teamsize }} {{ $t('participants') }}<br />
                        </p>
                        <small class="is-size-5 is-italic">
                            {{ $t('arena-teamsize-details', teamsizes) }}
                        </small>
                    </div>
                    <div class="column is-half">
                        <p class="is-size-4">
                            <i class="far fa-clock fa-fw pr-5"></i> 60 {{ $t('minutes') }}<br />
                        </p>
                        <small class="is-size-5 is-italic">
                            {{ $t('fun-positive-disclaimer') }}
                        </small>
                    </div>
                </div>
                <div class="columns">
                    <div class="column is-half">
                        <p v-if="arena" class="is-size-4">
                            <i class="fa fa-tachometer-alt fa-fw pr-5"></i> {{ arena.marketing.successRate }}% {{
                            $t('success-rate') }}
                        </p>
                    </div>
                    <div class="column is-half">
                        <p class="is-size-4">
                            <i class="fa fa-globe-europe fa-fw pr-5"></i> {{ languages }}
                        </p>
                    </div>
                </div>
            </div>
        </div>

        <div style="margin-top: -30vh;">
            <div v-if="video" class="arena-video">
                <i v-if="!playingVideo" @click="startPlayVideo" class="far fa-play-circle"></i>
                <video ref="videoPlayer" style="max-vidth: 100vw; max-height: 75vh" controls playsinline>
                    <source :src="'/apuz/' + handle + '/video/' + language + '/' + video.filename" type="video/mp4" />
                </video>
            </div>
            <TeamphotoCollage v-if="arena" :arena="arena"></TeamphotoCollage>
        </div>

        <ArenaFactBox :arena="arena"></ArenaFactBox>

        <ArenaPhotoAlbum v-if="illustrations.length > 0" :photos="illustrations"></ArenaPhotoAlbum>

        <div class="hero is-medium">
            <div class="hero-body">
                <div class="container is-max-desktop has-text-white">
                    <h3 class="subtitle has-text-grey-light is-size-3">{{ $t('price-availability-header') }}</h3>
                    <div class="columns">
                        <div class="column">
                            <div v-for="day in availability" :key="'availability-' + day.date">
                                <span v-if="day.slots.length == 0">{{ $t('fully-booked', { date: day.date }) }}</span>
                                <span v-else>{{ $t('availability-line', { slots: day.slots.length, date: day.date })
                                    }}</span>
                            </div>
                        </div>
                        <div class="column">
                            <div v-for="e in examples" :key="'example-' + e.label">
                                {{ $t('price-example', {
                                    cheapest: e.cheapest, participants: e.label, currency:
                                $t('currency')}) }}
                            </div>
                        </div>
                        <div class="column">
                            <i class="fa fa-check"></i> {{ $t('booking-pitch-cancel-header') }}<br />
                            <i class="fa fa-check"></i> {{ $t('book-first-pay-later') }}<br />
                            <i class="fa fa-check"></i> {{ $t('satisfaction-guarantee') }}<br />
                            <i class="fa fa-check"></i> {{ $t('teampicture-included') }}
                        </div>
                    </div>
                    <div v-if="pricesLoaded" class="columns is-multiline">
                        <div v-for="e in examples" :key="e.participants" class="column">
                            <div class="card">
                                <header class="card-header has-text-centered">
                                    <h4 class="card-header-title">Fr. {{ e.price }}{{ $t('currency') }}</h4>
                                </header>
                                <div class="card-content">
                                    {{ e.label }}
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="columns">
                        <div class="column is-two-thirds">
                            <p>{{ $t('prebook-short-info') }}</p>
                        </div>
                        <div class="column is-one-third">
                            <router-link :to="bookingLink()">
                                <button class="button is-primary">{{ $t('book-here') }}</button>
                            </router-link>
                        </div>
                    </div>
                </div>
            </div>
        </div>



        <content-block v-if="$at(arena, 'arena.marketing.missionBriefings')" :title="$t('mission-briefing-header')"
            layout="focusbox" class="mission-briefing">
            <div class="container is-max-desktop">
                <p style="margin: 0 auto auto; max-width: 500px; white-space: pre-wrap;">
                    {{ $at(arena, 'marketing.missionBriefings') }}
                </p>
            </div>
        </content-block>



        <div class="hero is-medium">
            <div class="hero-body">
                <div class="container">
                    <h3 class="subtitle is-3 has-text-white has-text-centered">{{ $t('more-challenges') }}</h3>
                    <ArenaStack format="small"></ArenaStack>
                </div>
            </div>
        </div>

    </div>
</template>
<script>
import dayjs from 'dayjs'
import axios from 'axios'
import { store } from '../store'
import { BookingApi } from '../booking.js'
import ContentBlock from '../components/ContentBlock'
import ArenaStack from '../components/ArenaStack'
import ArenaFactBox from '../components/ArenaFactBox'
import ArenaPhotoAlbum from '../components/ArenaPhotoAlbum'
import TeamphotoCollage from '../components/TeamphotoCollage'
export default {
    name: 'ArenaPage',
    data: function () {
        return {
            video: undefined,
            playingVideo: false,
            oldexamples: [],
            examples: [
                { id: 'a', participants: { adults: 2 } },
                { id: 'b', participants: { adults: 2, children: 2 } },
                { id: 'c', participants: { adults: 5 } },
                { id: 'd', participants: { students: 6 } },
            ],
            illustrations: [],
            availability: []
        }
    },
    components: {
        ArenaStack,
        ArenaFactBox,
        TeamphotoCollage,
        ArenaPhotoAlbum,
        ContentBlock
    },
    computed: {
        handle: function () { return this.$route.params.handle },
        arena: function () { return (store.arenas) ? (store.arenas[this.handle]) : null },
        language: function () { return store.language },
        languages() {
            return this.arena && this.arena.languages ? this.arena.languages.map(l => this.$t('language-' + l)).join(', ') : ''
        },
        splashimage() {
            return '/apuz/' + this.handle + '/illustration/titlepage.webp'
        },
        teamsize() {
            if (this.arena) {
                return this.arena['teamsize-min-recommended'] + ' - ' + this.arena['teamsize-max-recommended']
            }
            return ''
        },
        teamsizes() {
            return this.arena ? {
                recmin: this.arena['teamsize-min-recommended'],
                recmax: this.arena['teamsize-max-recommended'],
                absmin: this.arena['teamsize-min'],
                absmax: this.arena['teamsize-max']
            } : {}
        },
        pricesLoaded() {
            return this.examples.some(x => x.price)
        },
        splashstyle() {
            return 'background-image: linear-gradient(#0000, #0a0a0aff), url("' + this.splashimage + '");'
        }
    },
    watch: {
        handle() {
            this.refresh()
        }
    },
    methods: {
        refresh() {
            this.video = undefined
            this.illustrations = []
            this.playingVideo = false
            // This could get really interesting on slow connections where a result comes back after
            // a user navigates to another view....
            axios.get('/apuz/' + this.handle + '/illustrations.json').then((response) => {
                const images = response.data.illustrations.filter(i => i.filename.substr(-4) == '.jpg')
                const videos = response.data.illustrations.filter(i => i.filename.substr(-4) == '.mp4')

                this.illustrations = images.map(i => {
                    i.url = '/apuz/' + this.handle + '/illustration/' + i.filename.substring(0, i.filename.length - 4) + '.webp'
                    return i
                })

                videos.forEach(v => {
                    if (v.filename == 'titlepage.mp4') {
                        this.video = v

                        console.log('Attaching event listener for play event on ')
                        console.log(this.$refs['videoPlayer'])

                        this.$nextTick(() => {
                            this.$refs['videoPlayer'].addEventListener('play', () => {
                                console.log('Event listener fired')
                                this.playingVideo = true
                            })
                        })
                    }
                })

            })
            for (let i = 0; i < 4; i++) {
                this.loadPrices(i)

                let date = dayjs().add(i, 'day').format('YYYY-MM-DD')
                let url = '/booking/slots/' + this.handle + '/' + date + '.json'
                axios.get(url).then((response) => {
                    this.availability.push({
                        date,
                        slots: response.data.slots
                    })
                })
            }
        },
        startPlayVideo() {
            this.$refs['videoPlayer'].play()
            this.playing = false
        },
        loadPrices(attempt = 0) {
            this.examples.forEach(e => {
                let searchOpts = { ...e.participants }
                searchOpts.date = dayjs().add(attempt, 'day').format('YYYY-MM-DD')
                BookingApi.search(searchOpts).then(result => {
                    if (result.length > 0) {
                        let min = Math.min(...result.map(offer => offer.price))
                        if (!e.cheapest || min < e.cheapest) {
                            e.cheapest = min
                        }
                    }
                    if (!e.label) {
                        e.label = Object.keys(e.participants).map(p => {
                            return e.participants[p] + ' ' + this.$t(p)
                        }).join(', ')
                    }
                })
            })
        }
    },
    mounted: function () {
        this.refresh()

        console.log('Arena page loading, this is the arena')
        console.log(this.arena)
    }
}
</script>
<style>
.arena-page {}

.arena-page .arena-video {
    position: relative;
    text-align: center;
    background: black;
    width: 100vw;
}

.arena-page .arena-video i {
    font-size: 25vh;
    color: white;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    cursor: pointer;
}

.arena-page .arena-video video {}

.arena-page .splash {
    padding-top: 200px;
    min-height: 100vh;
    background-size: cover;
    background-position: center center;
    text-shadow: 2px 2px 4px black;
}

.arena-page .splash .facts {
    padding-top: 25vh;
    padding-bottom: 50vh;
}

.illustrations {
    background: #232323;
    color: white;
    text-align: center;
    padding: 5vmin;
}

.illustrations img {
    width: 90vmin;
    max-height: 75vh;
    object-fit: cover;
    margin-bottom: 5vmin;
    margin-bottom: 0;
}

.price-examples .example {
    background: rgba(255, 255, 255, 0.2);
    border: 1px solid rgba(255, 255, 255, 0.5);
    padding: 15px;
    margin: 10px;
    float: left;
}

.factbox table {
    width: 100%;
    max-width: 500px;
    margin: 0 auto auto;
    line-height: 35px;
    font-size: 1.25rem;
}

.factbox table th {
    text-align: left;
}

.factbox tr {
    border-bottom: 1px solid #ababab;
}
</style>
