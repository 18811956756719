import { render } from "./BookingForm.vue?vue&type=template&id=8b196786"
import script from "./BookingForm.vue?vue&type=script&lang=js"
export * from "./BookingForm.vue?vue&type=script&lang=js"
script.render = render
/* hot reload */
if (module.hot) {
  script.__hmrId = "8b196786"
  const api = __VUE_HMR_RUNTIME__
  module.hot.accept()
  if (!api.createRecord('8b196786', script)) {
    api.reload('8b196786', script)
  }
  
  module.hot.accept("./BookingForm.vue?vue&type=template&id=8b196786", () => {
    api.rerender('8b196786', render)
  })

}

script.__file = "src/components/BookingForm.vue"

export default script