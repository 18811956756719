import { render } from "./BookingPaymentTab.vue?vue&type=template&id=637bf4e8"
import script from "./BookingPaymentTab.vue?vue&type=script&lang=js"
export * from "./BookingPaymentTab.vue?vue&type=script&lang=js"
script.render = render
/* hot reload */
if (module.hot) {
  script.__hmrId = "637bf4e8"
  const api = __VUE_HMR_RUNTIME__
  module.hot.accept()
  if (!api.createRecord('637bf4e8', script)) {
    api.reload('637bf4e8', script)
  }
  
  module.hot.accept("./BookingPaymentTab.vue?vue&type=template&id=637bf4e8", () => {
    api.rerender('637bf4e8', render)
  })

}

script.__file = "src/components/BookingPaymentTab.vue"

export default script