<template>
  <header>
    <nav class="navbar is-transparent" style="background: transparent;" role="navigation"
      aria-label="main navigation">
      <div class="navbar-brand">
        <router-link to="/sv/start" class="navbar-item mt-4 ml-4 mr-4 p-0 mb-0">
          <img src="/apuz/venuelogo/banner/bright/transparent.svg"
            style="height: 5em; max-height: 3rem; max-width: 33vw;" />
        </router-link>

        <div style="position: relative; margin-left: auto; display: flex;">
          <router-link :to="'/' + activeLanguage + '/booking'"
            class="button is-success is-small mt-3 is-hidden-desktop">
            {{ $t('book-here') }}
          </router-link>

          <a @click="expandMenu = !expandMenu" :class="{'is-active': expandMenu}" role="button"
            class="has-text-white navbar-burger" aria-label="menu" aria-expanded="false"
            data-target="navbarBasicExample">
            <span aria-hidden="true"></span>
            <span aria-hidden="true"></span>
            <span aria-hidden="true"></span>
          </a>
        </div>
      </div>

      <div id="navbarBasicExample" class="navbar-menu" :key="$route" :class="{'is-active': expandMenu}"
        style="height: 100%; ">
        <div class="navbar-start">
          <div class="navbar-item has-dropdown is-hoverable">
            <a class="navbar-link" :class="navlinkclass">{{ $t('menu-challenges') }}</a>
            <div class="navbar-dropdown">
              <router-link :to="a.href" v-for="a in arenas" :key="a.handle" class="navbar-item">
                {{ atranslate(a, ['marketing', 'brandNames']) }}
              </router-link>
              <hr class="navbar-divider">
              <router-link :to="todayLink()" class="navbar-item">
                {{ $t('menu-item-today') }}
              </router-link>
            </div>
          </div>

          <div class="navbar-item has-dropdown is-hoverable">
            <a class="navbar-link" :class="navlinkclass">{{ $t('menu-private') }}</a>
            <div class="navbar-dropdown">
              <router-link :to="e.path" v-for="e in privateEntries" :key="e.path" class="navbar-item">
                {{ e.title }}
              </router-link>
              <hr class="navbar-divider">
              <template v-for="a in sortedAudiences">
                <router-link :to="'/' + language +'/' + $at(a, 'slug', a.slug, {language})" v-if="a['menu-private']" :key="a.slug" class="navbar-item">
                  {{ $at(a, 'label') }}
                </router-link>
              </template>
            </div>
          </div>

          <div class="navbar-item has-dropdown is-hoverable">
            <a class="navbar-link" :class="navlinkclass">{{ $t('menu-corporate') }}</a>
            <div class="navbar-dropdown">
              <router-link :to="e.path" v-for="e in corporateEntries" :key="e.path" class="navbar-item">
                {{ e.title }}
              </router-link>
              <hr class="navbar-divider">
              <template v-for="a in sortedAudiences">
                <router-link :to="'/' + language +'/' + $at(a, 'slug', a.slug, {language})" v-if="a['menu-corporate']" :key="a.slug" class="navbar-item">
                  {{ $at(a, 'label') }}
                </router-link>
              </template>
              <hr class="navbar-divider">
              <router-link v-if="restaurants.length > 0" :to="'/' + language + '/restaurant-packages'"
                class="navbar-item">{{ $t('menu-restaurant-packages') }}</router-link>
            </div>
          </div>

          <div class="navbar-item has-dropdown is-hoverable">
            <a class="navbar-link" :class="navlinkclass">{{ $t('menu-about') }}</a>
            <div class="navbar-dropdown">
              <router-link :to="e.path" v-for="e in aboutEntries" :key="e.path" class="navbar-item">
                {{ e.title }}
              </router-link>
              <hr class="navbar-divider">
              <router-link :to="'/' + language + '/friends'" class="navbar-item">{{
                $t('menu-item-friends') }}</router-link>
            </div>
          </div>

          <div class="navbar-item has-dropdown is-hoverable">
            <a class="navbar-link" :class="navlinkclass">{{ $t('menu-booking') }}</a>
            <div class="navbar-dropdown">
              <router-link :to="e.path" v-for="e in bookingEntries" :key="e.path" class="navbar-item">
                {{ e.title }}
              </router-link>

              <router-link :to="'/' + language + '/booking'" class="navbar-item">{{ $t('book-here') }}</router-link>
              <router-link :to="'/' + language + '/gift-cards'" class="navbar-item">{{ $t('menu-gift-cards') }}</router-link>
              <router-link :to="'/' + language + '/manage-booking'" class="navbar-item">{{$t('menu-manage-booking') }}</router-link>
              <!--							<router-link :to="'/' + language + '/booking-toc'" class="navbar-item">{{ $t('menu-booking-toc') }}</router-link> -->
              <router-link :to="e.path" v-for="e in bookingEntries" :key="e.path" class="navbar-item">
                {{ e.title }}
              </router-link>
            </div>
          </div>
        </div>

        <div class="navbar-end">
          <div class="navbar-item">
            <div class="buttons">
              <div v-if="bookingEditUrl" class="has-addons field">
                <p class="control">
                  <router-link :to="bookingEditUrl" class="button is-primary">
                    {{ $t('manage-booking-button') }}
                  </router-link>
                </p>
                <p class="control">
                  <button @click="signOut" class="button is-dark">
                    {{ $t('sign-out-button') }}&nbsp;<i class="fa fa-sign-out-alt"></i>
                  </button>
                </p>
              </div>
              <router-link v-else :to="'/' + activeLanguage + '/booking'" class="button is-success">
                {{ $t('book-here') }}
              </router-link>
            </div>
          </div>
          <div class="navbar-item has-dropdown is-hoverable">
            <a class="navbar-link is-uppercase" :class="navlinkclass">
              <i class="fa fa-globe-europe"></i>&nbsp;{{ language}}
            </a>
            <div class="navbar-dropdown">
              <a v-for="l in languages" :href="'/' + l + '/start'" :key="'lng-' + l"
                class="navbar-item">{{ langlabels[l] }}</a>
            </div>
          </div>
        </div>
      </div>
    </nav>
  </header>
</template>
<script>
  import { store } from '../store'

  export default {
    name: 'Menu',
    data: function () {
      return {
        width: window.innerWidth,
        langlabels: {
          'en': 'English',
          'sv': 'Svenska',
          'nl': 'Nederlands',
          'fi': 'Suomalainen',
          'de': 'Deutsche',
          'no': 'Norsk',
          'da': 'Dansk'
        }
      }
    },
    methods: {
      click: function (tab) {
        this.tab = (this.tab == tab) ? undefined : tab
      },
      signOut() {
        store.signOut()
      },
      sortAlphabetically(arr, key) {
        return arr.slice().sort((a, b) => a[key].localeCompare(b[key]));
      }
    },
    computed: {
      restaurants() {
        let restaurants = store.venue ? store.venue.partners : []
        restaurants.forEach(r => {
          let img = '/apuz/venue/static/images/' + r.bgimg
          r.style = 'background-image: url("' + img + '"); background-size: cover; padding-top: 8rem; padding-bottom: 8rem;'
        })

        return restaurants
      },
      audiences() {
        if(store.venue && ["cac","ref","hq","wil","brs"].includes(store.venue.handle)){
          return store.audiences
        }
        return []
      },
      sortedAudiences() {
        const audiencesArray = Object.values(this.audiences)

        const sortedArray = audiencesArray.sort((a, b) => a.label[this.language].localeCompare(b.label[this.language]))

        const sortedObject = {}
        sortedArray.forEach((audience, index) => {
          sortedObject[index] = audience
        })
        return sortedObject
      },
      language: function () { return store.language },
      languages: function () { return store.languages },
      navlinkclass() {
        return this.expandMenu ? '' : 'has-text-white'
      },
      expandMenu: {
        get: function () { return store.expandMenu },
        set: function (expanded) { store.expandMenu = expanded }
      },
      tab: {
        get: function () {
          return store.menuTab
        },
        set: function (tab) {
          store.menuTab = tab
        }
      },
      entries: function () {
        if (store.pages[store.language]) {
          return store.pages[store.language]
        } else {
          return {}
        }
      },
      corporateEntries: function () {
        return Object.values(this.entries).filter(entry => entry.category == 'corporate')
      },
      privateEntries: function () {
        return Object.values(this.entries).filter(entry => entry.category == 'private')
      },
      aboutEntries: function () {
        return Object.values(this.entries).filter(entry => entry.category == 'about')
      },
      bookingEntries: function () {
        return Object.values(this.entries).filter(entry => entry.category == 'booking')
      },
      activeLanguage: function () {
        return store.language
      },
      arenas: function () {
        return store.arenas
      },
      desktop: function () { return this.width > 800 },
      bookingEditUrl() {
        return store.bookingEditUrl()
      }
    },
    mounted: function () {
      window.addEventListener('resize', () => this.width = window.innerWidth)
    }
  }
</script>
<style scoped>
  header {
    position: absolute;
    top: 0;
    width: 100%;
    background: linear-gradient(0deg, rgba(2, 0, 36, 0) 0%, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.47730517988445376) 33%, rgba(0, 0, 0, 0.8918710062149859) 96%);
  }

  .wrapper {
    max-width: 1080px;
    margin: 0 auto auto;
  }

  .overlay {
    position: fixed;
    background: rgba(0, 0, 0, 0.8);
    color: white;
    width: 100vw;
    height: 100vh;
    top: 120px;
    left: 0;
  }

  .navbar-item:hover, .navbar-link:hover {
    background-color: transparent;
  }
</style>
