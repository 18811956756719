<template>
  <section v-if="audiencePages" class="has-background-dark has-text-white"
    style="padding-top: 10vh; padding-bottom: 10vh;">
    <div class="container">
      <h2 class="subtitle has-text-white">{{ $t('audiences-pages-heading') }}</h2>
      <div class="fixed-grid has-3-cols">
        <div class="grid">
          <div v-for="a in splicedAudiencePages" :key="a.handle" class="cell">
            <a :href="translatedSlug(a)">
              <div class="card full-height">
                <figure class="image is-4by3">
                  <img :src="getPictureAudince(a)" style="object-fit: cover;" />
                </figure>
                <div class="card-content">
                  <h5 class="title is-5">{{ $at(a, 'label') }}</h5>
                  <p>{{ $at(a, 'headline') }}</p>
                </div>
              </div>
            </a>
          </div>
        </div>
      </div>
      <div class="is-flex is-justify-content-space-between">
        <div v-for="a in audiencePages.slice(3)" :key="a.handle">
          <a :href="translatedSlug(a)">
            <div class="button">{{ $at(a, 'label') }}</div>
          </a>
        </div>
      </div>
    </div>
  </section>
</template>
<script>
import { store } from '../store'

export default {
  name: 'ArenaStack',
  data: function () {
    return {
      priority: ['family', 'employer', 'friends']
    }
  },
  computed: {
    audiences() {
      return store.audiences
    },
    audiencePages() {
      const audiencesArray = Object.entries(this.audiences).map(([key, value]) => {
        return {
          ...value, 
          handle: key 
        }
      })
      const sortedArray = audiencesArray.sort((a, b) => a.label[store.language].localeCompare(b.label[store.language]))

      if (this.priority) {
        const priorityArray = sortedArray.filter(audience => this.priority.includes(audience.handle))
        const alphaArray = sortedArray.filter(audience => !this.priority.includes(audience.handle))
        return [...priorityArray, ...alphaArray]
      }

      return sortedArray
    },
    splicedAudiencePages() {
      return [...this.audiencePages].splice(0, 3)
    }
  },
  methods: {
    getPictureAudince(audience) {
      let img = '/apuz/venue/static/images/' + audience.landingBlocks.capacity.coverPicture + '?w=480'
      return img
    },
    translatedSlug(audience) {
      let translated_slug = this.$at(audience.slug, [], audience.slug)
      return translated_slug
    }
  }
}
</script>
<style scoped>
.full-height {
  height: 100%;
}
</style>