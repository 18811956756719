import StartPage from './views/StartPage.vue'
import BookingPage from './views/BookingPage.vue'
import TodayPage from './views/TodayPage.vue'
import BookingDetailsPage from './views/BookingDetailsPage.vue'
import ArenaPage from './views/ArenaPage.vue'
import GamePage from './views/GamePage.vue'
import ContentPage from './views/ContentPage.vue'
import BookingLoginPage from './views/BookingLoginPage.vue'
import FriendsPage from './views/FriendsPage.vue'
import GiftCertificate from './views/GiftCertificate.vue'
import GiftCertificateReceipt from './views/GiftCertificateReceipt.vue'
import PurchaseGiftCardPage from './views/PurchaseGiftCardPage.vue'
import SendEmail from './views/SendEmail.vue'
import NotFound from './views/NotFound.vue'
import BookingTocPage from './views/BookingTocPage.vue'
import RestaurantPackagesPage from './views/RestaurantPackagesPage.vue'
import AudienceLandingPage from './views/AudienceLandingPage.vue'

import snippets from '/storage/tmp/merged-snippets.json'
import audiences from '/storage/tmp/audiences.json'

import { atranslate } from './main'

let routes = [
  {
    path: '/:language/start',
    component: StartPage,
    name: 'start'
  },
  {
    path: '/:language/booking',
    component: BookingPage,
    name: 'booking'
  },
  { path: '/:language/' + snippets.en['today-link-href'], component: TodayPage },
  { path: '/:language/' + snippets.sv['today-link-href'], component: TodayPage },
  {
    path: '/:language/booking-details/:date/:id',
    component: BookingDetailsPage
  },
  {
    path: '/:language/arena/:handle',
    component: ArenaPage,
    name: 'arena-page'
  },
  {
    path: '/:language/team/:id',
    component: GamePage,
    name: 'team-page'
  },
  {
    path: '/:language/pages/:handle.html',
    component: ContentPage
  },
  {
    path: '/:language/send-email/:date/:id',
    component: SendEmail
  },
  {
    path: '/:language/gift-certificate/:code',
    component: GiftCertificate
  },
  {
    path: '/:language/gift-certificate-receipt/:campaign/:receipt',
    component: GiftCertificateReceipt
  },
  {
    path: '/:language/gift-cards',
    component: PurchaseGiftCardPage
  },
  {
    path: '/:language/friends',
    component: FriendsPage,
    name: 'friends-page'
  },
  {
    path: '/:language/manage-booking',
    component: BookingLoginPage,
    name: 'booking-login-page'
  },
  {
    path: '/:language/booking-toc',
    component: BookingTocPage,
    name: 'booking-toc-page'
  },
  {
    path: '/:language/restaurant-packages',
    component: RestaurantPackagesPage,
    name: 'restaurant-packages-page'
  },
  {
    path: '/:catchAll(.*)*',
    component: NotFound
  }
]

for(const [handle, audience] of Object.entries(audiences)){
  for(const [language, slug] of Object.entries(audience.slug)){

    let translated_slug = atranslate(slug, [], slug)

    routes.push({
      path: `/${language}/${translated_slug}`,
      component: AudienceLandingPage,
      meta: {audience: {...audience, handle}},
      name: handle + '-landing-page' + '-' + language
    })
  }
}

export {routes}
