import { render } from "./BookingTeamsTab.vue?vue&type=template&id=97cda910"
import script from "./BookingTeamsTab.vue?vue&type=script&lang=js"
export * from "./BookingTeamsTab.vue?vue&type=script&lang=js"
script.render = render
/* hot reload */
if (module.hot) {
  script.__hmrId = "97cda910"
  const api = __VUE_HMR_RUNTIME__
  module.hot.accept()
  if (!api.createRecord('97cda910', script)) {
    api.reload('97cda910', script)
  }
  
  module.hot.accept("./BookingTeamsTab.vue?vue&type=template&id=97cda910", () => {
    api.rerender('97cda910', render)
  })

}

script.__file = "src/components/BookingTeamsTab.vue"

export default script