<template>
  <div class="container">
    <h1 class="title has-text-centered"></h1>
    <div v-if="paymentMethods">
      <div class="fixed-grid has-2-cols is-flex">
        <div class="grid">
          <div class="cell" v-for="(paymentMethod, index) in paymentMethods.slice(0, 2)" :key="index">
            <div class="card">
              <div class="card-image">
                <span class="icon-text has-text-white is-absolute is-align-items-center p-2">
                  <span class="icon is-small">
                    <i :class="payMethods[paymentMethod].icon"></i>
                  </span>
                  <p class="is-size-6-mobile">{{ $t(payMethods[paymentMethod].header) }}</p>
                </span>
                <img src="/apuz/venue/static/images/bachelor-party.png?w=720">
              </div>
              <div class="card-content">
                <p>{{ $t(payMethods[paymentMethod].text) }}</p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div v-for="(paymentMethod, index) in paymentMethods.slice(2)" :key="index" class="column">
        <div class="card">
          <header class="card-header is-clickable" @click="toggleCard(index)">
            <span class="card-header-title icon-text is-align-items-center p-0">
              <span class="icon is-large">
                <i :class="payMethods[paymentMethod].icon"></i>
              </span>
              <p>{{ $t(payMethods[paymentMethod].header) }}</p>
            </span>
            <div class="card-header-icon" >
              <span class="icon">
                <i class="fas fa-angle-down"></i>
              </span>
            </div>
          </header>
          <div v-show="openCards.includes(index)" class="card-content">
            <p>{{ $t(payMethods[paymentMethod].text) }}</p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: 'PaymentBlock',
  data() {
    return {
      payMethods: {
        'invoice': { "header": "payment-invoice-header", "text": "payment-invoice-text", "icon": "fas fa-file-invoice" },
        'online': { "header": "payment-online-header", "text": "payment-online-text", "icon": "fas fa-globe" },
        'card-on-site': { "header": "payment-card-on-site-header", "text": "payment-card-on-site-text", "icon": "far fa-credit-card" },
        'swish-on-site': { "header": "payment-swish-on-site-header", "text": "payment-swish-on-site-text", "icon": "fas fa-mobile-alt" },
        'gift-card': { "header": "payment-gift-card-header", "text": "payment-gift-card-text", "icon": "fas fa-gift" },
        'split-online': { "header": "payment-split-online-header", "text": "payment-split-online-text", "icon": "fas fa-divide" },
      },
      openCards: []
    }
  },
  props: {
    audience: Object
  },
  computed: {
    paymentMethods() {
      return this.audience.landingBlocks.payment.methods
    }
  },
  methods: {
    computeVisibility() {
      return true
    },
    toggleCard(index){
      if (this.openCards.includes(index)) {
        this.openCards = this.openCards.filter(card => card !== index)
      } else {
        this.openCards.push(index)
      }
    },
  }
}
</script>
<style scoped>
.is-absolute {
  position: absolute;
}
</style>
