<template>
  <div class="container">
    <div class="has-text-black" v-html="filePath"></div>
  </div>
</template>
<script>
import { store } from '../../store'

  export default {
    name: 'TextBlock',
    props: {
      filename: Object,
      theme: String
    },
    data() {
      return {
      }
    },
    computed: {
      filePath: function() {
        if (!this.filename || !store || !store.language || !store.textblocks || !store.textblocks[store.language]) {
          return null;
        }
        const file = this.filename[store.language];
        if (file && store.textblocks[store.language][file] != null) {
          return store.textblocks[store.language][file].content;
        }
        return null;
      },
    },
    methods: {
    },
    mounted() {
    }
  }
</script>