import { render } from "./ArenaStackSmallEntry.vue?vue&type=template&id=d4ef7f08&scoped=true"
import script from "./ArenaStackSmallEntry.vue?vue&type=script&lang=js"
export * from "./ArenaStackSmallEntry.vue?vue&type=script&lang=js"

import "./ArenaStackSmallEntry.vue?vue&type=style&index=0&id=d4ef7f08&scoped=true&lang=css"
script.render = render
script.__scopeId = "data-v-d4ef7f08"
/* hot reload */
if (module.hot) {
  script.__hmrId = "d4ef7f08"
  const api = __VUE_HMR_RUNTIME__
  module.hot.accept()
  if (!api.createRecord('d4ef7f08', script)) {
    api.reload('d4ef7f08', script)
  }
  
  module.hot.accept("./ArenaStackSmallEntry.vue?vue&type=template&id=d4ef7f08&scoped=true", () => {
    api.rerender('d4ef7f08', render)
  })

}

script.__file = "src/components/ArenaStackSmallEntry.vue"

export default script