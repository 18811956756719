<template>
  <div class="modal is-active">
    <div @click="$emit('abort')" class="modal-background"></div>
    <div class="modal-card">
      <header class="modal-card-head">
        <p class="modal-card-title">{{ $t('booking-step1-heading') }}  </p>
        <button @click="$emit('abort')" class="delete" aria-label="close"></button>
      </header>
      <section class="modal-card-body">
				<article v-if="bookingFailed" class="message is-danger">
					<div class="message-header">
						<p>{{ $t('booking-failed-title') }}</p>
						<button @click="bookingFailed = false" class="delete" aria-label="delete"></button>
					</div>
					<p v-if="bookingFailReason == 'schedule-error'">
						{{ $t('booking-failed-schedule-error') }}
					</p>
					<p v-if="bookingFailReason == 'server-error'">
						{{ $t('booking-failed-server-error') }}
					</p>
				</article>
				<template v-else>
					<h5 class="subtitle">{{ dateToTranslatedText(offer.date) }}</h5>
					<div v-for="s in offer.slots" :key="s.arena.handle">
						<h5 class="subtitle">{{ s.startTime }} {{ atranslate(s.arena, ['marketing', 'brandNames'], s.arena.handle) }}</h5>
						<p v-if="atranslate(s.arena, ['marketing', 'shortFacts'], '').length > 0">
							{{ atranslate(s.arena, ['marketing', 'shortFacts'], '').substr(0, 100) }}...
						</p>
						<p v-else>
							{{ atranslate(s.arena, ['marketing', 'shortFacts'], '') }}
						</p>
					</div>

					<div class="columns">
						<div class="column field">
							<label class="label">{{ $t('email-input-label') }}</label>
							<input type="email" v-model="booker.email" class="input" :class="{'is-danger': invalidFields.includes('email')}" ref="input-email" name="booking-email" required />
							<p class="help">
								{{ $t('email-disclaimer-text') }}
							</p>
						</div>
						<div v-if="offer.type == 'company'" class="column field">
							<label class="label">{{ $t('organization-input-label') }}</label>
							<input type="freetext" v-model="booker.organization" ref="input-organization" :class="{'is-danger': invalidFields.includes('organization')}" name="booking-organization" class="input" required />
						</div>
					</div>
					<div class="columns">
						<div class="column field">
							<label class="label">{{ $t('name-input-label') }}</label>
							<input type="text" v-model="booker.name" ref="input-name" :class="{'is-danger': invalidFields.includes('name')}" class="input" name="booking-name" required />
						</div>
						<div class="column field">
							<label class="label">{{ $t('phone-input-label') }}</label>
							<input type="phone" v-model.lazy="booker.phone" ref="input-phone" :class="{'is-danger': invalidFields.includes('phone')}" class="input" name="booking-phone" required />
							<p class="help">
								{{ $t('phone-disclaimer-text') }}
							</p>
						</div>
					</div>

					<article class="message is-warning" v-if="offer.warnings.includes('too-many-players')">
						<p class="message-body">{{ $t('too-many-players-text') }}</p>
					</article>
					<article class="message is-warning" v-if="offer.warnings.includes('too-few-players')">
						<p class="message-body">{{ $t('too-few-players-text') }}</p>
					</article>
					<article class="message is-warning" v-if="offer.warnings.includes('offset-slots')">
						<p class="message-body">{{ $t('offset-slots-text') }}</p>
					</article>

					<article class="message is-danger" v-if="invalidFields.length > 0">
						<p v-if="invalidFields.includes('name')" class="message-body">{{ $t('booking-error-invalid-name') }}</p>
						<p v-if="invalidFields.includes('email')" class="message-body">{{ $t('booking-error-invalid-email') }}</p>
						<p v-if="invalidFields.includes('organization')" class="message-body">{{ $t('booking-error-invalid-organization') }}</p>
						<p v-if="invalidFields.includes('phone')" class="message-body">{{ $t('booking-error-invalid-phone') }}</p>
					</article>

					<!--
					<div>
						<label class="checkbox">
							<input type="checkbox">
							{{ $t('booking-terms-checkbox')}}
						</label>
					</div>
					-->

					<article class="message is-warning" v-if="mode == 'offer'">
						Din offert är giltig till och med <strong>{{ offer.reservation_expiry}}</strong>
					</article>
				</template>
      </section>
      <footer class="modal-card-foot">
        <button v-if="!bookingFailed && formOk" @click="performBooking" name="confirm-booking" class="button is-success">
          <span v-if="mode == 'offer'">
						<i v-if="bookingInProgress" class="fas fa-circle-notch fa-spin"></i>
						<i v-else class="fa fa-file-alt"></i>
						{{ $t('booking-request-offer') }}
					</span>
					<span v-else>
						<i v-if="bookingInProgress" class="fas fa-circle-notch fa-spin"></i>
						<i v-else class="fa fa-check"></i>
						{{ $t('reserve-booking-button') }}
					</span>
        </button>
        <button class="button" @click="$emit('abort')">{{ $t('booking-form-cancel-button') }}</button>
      </footer>
    </div>
  </div>
</template>

<script>
import { router } from '../main.js'
import axios from 'axios'
import { store } from '../store'

export default {
  name: 'BookingForm',
  props: ['offer', 'mode'],
  data: function () {
    return {
      bookingInProgress: false,
			bookingFailed: false,
			bookingFailReason: undefined,
      expanded: undefined,
			formOk: true, // This should be a computed
      booker: {
        email: '',
        phone: '',
        name: '',
        organization: ''
      },
      invalidFields: []
    }
	},
	watch: {
		booker: {
			deep: true,
			handler () {
				if (this.booker.phone.length > 0 && this.booker.phone[0] == '0') {
					this.booker.phone = this.countrycode + this.booker.phone.substr(1)
				}
			}
		}
	},
  components: {
  },
  computed: {
		countrycode () {
      return store.venue.publicdetails.countrycode
		},
    arenas: function () {
      console.log(this.offer)
      let arenas = []
      for (let s of this.offer.slots) {
        arenas.push(s.arena)
      }

      return arenas

    }
  },
  methods: {
    performBooking: function () {
      this.invalidFields = []
      let checkFields = ['email', 'phone', 'name']
      if (this.type == 'company') { checkFields.push('company') }
      for (let f of checkFields) {
        if (!this.$refs['input-' + f].checkValidity()) {
          this.invalidFields.push(f)
        }
      }

      if (this.bookingInProgress || this.invalidFields.length > 0) {
        return
      }
      this.bookingInProgress = true
      let payload = JSON.parse(JSON.stringify(this.offer))
      payload.slots.forEach(s => {
        s.arena = s.arena.handle
        s.language = store.language
      })
      payload.booker = this.booker
      payload.mode = this.mode
      payload.code = this.offer.code
      payload.reservation_expiry = this.offer.reservation_expiry
      payload.language = store.language

      axios.post('/booking/' + payload.date + '/create.json', payload).then((response) => {
				if (response.data.id) {
					this.plausible('booking-book')

					try {
						// Report Google Ads conversion tracking
						let callback = function () {
							console.log('Conversion tracked!')
						}
						// eslint-disable-next-line
						gtag('event', 'conversion', {
								'send_to': 'AW-346073430/I0VmCK_gjPoCENbSgqUB',
								'value': payload.price,
								'currency': 'SEK',
								'transaction_id': payload.date + '_' + response.data.id,
								'event_callback': callback
						})
					} catch (err) {
						console.log('Failed to log statistics to Google')
						console.log(err)
					}


					window.localStorage.setItem('sendConfirmationEmail', 'true')
					const destination = '/' + store.language + '/booking-details/' + response.data.date + '/' + response.data.id
					console.log('Redirecting to ' + destination)
					router.push(destination)
				}
				else {
					this.bookingFailed = true
					this.bookingFailReason = response.data.reason
					this.bookingInProgress = false
				}
      }).catch((err) => {
				console.log(err)
				this.bookingInProgress = false
				this.bookingFailed = true
				this.bookingFailReason = 'server-error'
			})

			window.fbq('track', 'Purchase', {value: payload.price, currency: 'SEK'});
    }
  }
}
</script>
