import { render } from "./CapacityBlock.vue?vue&type=template&id=01c6461a&scoped=true"
import script from "./CapacityBlock.vue?vue&type=script&lang=js"
export * from "./CapacityBlock.vue?vue&type=script&lang=js"

import "./CapacityBlock.vue?vue&type=style&index=0&id=01c6461a&scoped=true&lang=css"
script.render = render
script.__scopeId = "data-v-01c6461a"
/* hot reload */
if (module.hot) {
  script.__hmrId = "01c6461a"
  const api = __VUE_HMR_RUNTIME__
  module.hot.accept()
  if (!api.createRecord('01c6461a', script)) {
    api.reload('01c6461a', script)
  }
  
  module.hot.accept("./CapacityBlock.vue?vue&type=template&id=01c6461a&scoped=true", () => {
    api.rerender('01c6461a', render)
  })

}

script.__file = "src/components/blocks/CapacityBlock.vue"

export default script