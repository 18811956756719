<template>
  <div class="container">
    <div class="columns">
      <div class="column is-narrow">
        <figure class="image is-128x128">
          <img class="is-rounded img-cover" :src="'/apuz/avatar/' + $at(advice, 'author') + '.jpg'" alt="Profile Image" />
        </figure>
      </div>
      <div class="column">
        <div>
          <h4 class="title has-text-weight-bold is-size-4 is-italic">“{{ $at(advice, 'heading') }}”</h4>
          <quote class="is-size-5">{{ $at(advice, 'text')  }}</quote>
          <p class="has-text-right is-size-6 is-italic">{{ $at(advice, 'authorname') }}</p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'AdviceBlock',
  props: {advice: Object},
  data() {
    return {}
  },
  computed: {
  },
  methods: {

  }
}
</script>

<style scoped>
.img-cover {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
</style>
