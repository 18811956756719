import { render } from "./EmailFollowup.vue?vue&type=template&id=f9f75470"
import script from "./EmailFollowup.vue?vue&type=script&lang=js"
export * from "./EmailFollowup.vue?vue&type=script&lang=js"
script.render = render
/* hot reload */
if (module.hot) {
  script.__hmrId = "f9f75470"
  const api = __VUE_HMR_RUNTIME__
  module.hot.accept()
  if (!api.createRecord('f9f75470', script)) {
    api.reload('f9f75470', script)
  }
  
  module.hot.accept("./EmailFollowup.vue?vue&type=template&id=f9f75470", () => {
    api.rerender('f9f75470', render)
  })

}

script.__file = "src/components/EmailFollowup.vue"

export default script