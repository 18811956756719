import { render } from "./ContentBlock.vue?vue&type=template&id=10d44d3d&scoped=true"
import script from "./ContentBlock.vue?vue&type=script&lang=js"
export * from "./ContentBlock.vue?vue&type=script&lang=js"

import "./ContentBlock.vue?vue&type=style&index=0&id=10d44d3d&scoped=true&lang=css"
script.render = render
script.__scopeId = "data-v-10d44d3d"
/* hot reload */
if (module.hot) {
  script.__hmrId = "10d44d3d"
  const api = __VUE_HMR_RUNTIME__
  module.hot.accept()
  if (!api.createRecord('10d44d3d', script)) {
    api.reload('10d44d3d', script)
  }
  
  module.hot.accept("./ContentBlock.vue?vue&type=template&id=10d44d3d&scoped=true", () => {
    api.rerender('10d44d3d', render)
  })

}

script.__file = "src/components/blocks/ContentBlock.vue"

export default script