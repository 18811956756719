import { createApp } from 'vue'
import { createI18n } from 'vue-i18n'
import App from './App.vue'
//import axios from 'axios'
import { createRouter, createWebHistory } from 'vue-router'
import snippets from '/storage/tmp/merged-snippets.json'

import Plausible from 'plausible-tracker'

import { store } from './store'
import { routes } from './routes'
import dayjs from 'dayjs'

const LANGUAGES = ['en', 'sv', 'fi', 'nl']

//import 'animate.css'
//require('@/assets/main.scss')
require('/storage/tmp/vueweb.scss')

const plausible = Plausible({
  domain: window.location.hostname
})

plausible.enableAutoPageviews()


const routerHistory = createWebHistory()
const router = createRouter({
  history: routerHistory,
  routes
})



let locale = window.location.pathname.split('/')[1]
if (!LANGUAGES.includes(locale)) {
  window.location = '/en/start'

}

const i18n = createI18n({
  legacy: true,
  locale: locale,
  messages: snippets
})


// We should use a navigation guard on the router, notice the language change and update i18n.global.locale,
// however, it seems that the i18n instance we update is not used inside the components. To move forward
// we instead reload the page and use the createI18n setting for the locale upon re-rendering
const initialLanguage = window.location.pathname.split('/')[1]
router.beforeEach((to, from, next) => {
  store.expandMenu = false

  let lng = 'sv'
/*
  if (navigator.language) {
      const navlang = navigator.language.substring(0, 2).toLowerCase()

      if (LANGUAGES.includes(navlang)) {
        lng = navlang
      }
    }
*/
  store.menuTab = undefined
  store.expandMenu = false

  if (to.path == '/') {
    if(!['en', 'sv', 'nl'].includes(lng)) {
      lng = 'en'
    }

    window.location = '/' + lng + '/start'
  }

  window.scrollTo(0, 0)
  if (to.params.language && to.params.language != initialLanguage) {
    setTimeout(() => {
//      window.location.reload()
    }, 100)
  }

	if (store.venue) {
		document.title = store.venue['name']
	}
	store.venuePromise.then(() => {
		updateHrefLangs(to)



    console.log('Store is')
    console.log(store)
	})

	next()
	window.fbq('track', 'ViewContent', { content_name: to.path })
})


function updateHrefLangs(to) {
	let linktags = Array.from(document.getElementsByTagName('link')).filter(e => e.hreflang)
	for (let i = linktags.length - 1; i >= 0; i--) {
		linktags[i].remove()
	}

	const translatedPages = ['arena-page', 'start', 'booking', 'team-page', 'friends-page']
	if (translatedPages.includes(to.name)) {
		for(const lng of store.languages) {
			let newElem = document.createElement('link')
			newElem.hreflang = lng
			newElem.rel = 'alternate'
			if (to.name === 'arena-page') {
				newElem.href = window.location.protocol + '//' + window.location.hostname + '/' + lng + '/arena/' + to.params.handle
			}
			if (to.name === 'start') {
				newElem.href = window.location.protocol + '//' + window.location.hostname + '/' + lng + '/start'
			}
			if (to.name === 'booking') {
				newElem.href = window.location.protocol + '//' + window.location.hostname + '/' + lng + '/booking'
			}
			if (to.name === 'friends-page') {
				newElem.href = window.location.protocol + '//' + window.location.hostname + '/' + lng + '/friends'
			}
			document.head.append(newElem)
		}
	}
}

function participantsHash(participants) {
  let active = Object.keys(participants).filter(key => participants[key] > 0)
  let  shortened = active.sort().map(key => participants[key] + key[0] )
  return shortened.join('')
}

function replaceWords(str, wordsToReplace, replacementWords) {
  // Create a map from wordsToReplace to replacementWords
  const replaceMap = {};
  wordsToReplace.forEach((word, index) => {
    replaceMap[word] = replacementWords[index];
  });

  // Create a regular expression that matches any of the words to replace
  const regex = new RegExp(wordsToReplace.join('|'), 'g');

  // Use replace with a callback function to perform the replacements
  return str.replace(regex, (matched) => replaceMap[matched]);
}

const app = createApp(App)
app.use(router)
app.use(i18n)

function pad (n, width, z) {
  z = z || '0'
  n = n + ''
  return n.length >= width ? n : new Array(width - n.length + 1).join(z) + n
}

function hhmm2min (hhmm) {
  const parts = hhmm.split(':')
  return parseInt(parts[0]) * 60 + parseInt(parts[1])
}

function min2hhmm (min) {
  return pad(Math.floor(min / 60), 2) + ':' + pad(min % 60, 2)
}

function sec2mmss (secs) {
  return pad(Math.floor(secs / 60), 2) + ':' + pad(secs % 60, 2)
}


function atranslate (dict, path, def, options = {}) {
  const lng = options.language || store.language || 'en'

  if ((typeof path) == 'string') {
    path = path.split('.')
  }

  const translations = path.reduce((obj, key) => (obj || {})[key], dict) || {}
  let translated = translations[lng] || translations['en'] || def

  if (!translated) {
    console.log('Failed to atranslate ' + path + ' from object')
    return ''
  }

  let interpolated = replaceWords(translated,
    ['{city}', '{cityhandle}', '{challenge}'],
    [snippets[lng].cityname, snippets[lng].cityhandle, 'utmaning']
  )

  return interpolated
}

app.mixin({
  methods: {
		plausible () {
//			plausible(event, options)
		},
		bookingLink () {
			return '/' + store.language + '/booking'
		},
		todayLink () {
			return '/' + store.language + '/' + snippets[store.language]['today-link-href']
		},
    playerSummary(participants) {
      let summary = '';
      const keys = Object.keys(participants);
      keys.forEach((key, index) => {
        if(participants[key] > 0){
          summary += `${participants[key]} ${this.$t(key)}`;
          if (index < keys.length - 2) {
            summary += ', ';
          } else if (index === keys.length - 2) {
            summary += ' ' + this.$t('and') + ' ';
          }
        }
      });
      return summary;
    },
    isDesktop() {
      return window.innerWidth > 768
    },
    dateToTranslatedText(date){
      const month = this.$t(dayjs(date).format('MMMM').toLowerCase())
      const day = this.$t(dayjs(date).format('dddd').toLowerCase())
      const dayNumber = dayjs(date).format('D')
      const year = dayjs(date).format('YYYY')

      return `${day}, ${dayNumber} ${month} ${year}`
    },
    atranslate,
    $at (dict, path, def) {
      return this.atranslate(dict, path, def)
    },
    hhmm2min,
    min2hhmm,
    sec2mmss,
    hhmmadd (hhmm, min) {
      return min2hhmm(hhmm2min(hhmm) + min)
    }
  }
})

app.mount('#app')



export { router, participantsHash, atranslate }
