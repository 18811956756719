<template>
  <div class="container mt-5 p-2">
    <div class="fixed-grid" :class="{ 'has-1-cols': !hasTwoColumns, 'has-2-cols': hasTwoColumns }"> 
       <div v-for="(entry, index) in entries" :key="index" class="cell mb-2">
        <div @click="toggle(index)" class="box is-clickable">
          <div class="is-flex is-align-items-center is-justify-content-space-between">
            <p class="title is-4 m-0">{{ $at(entry, 'q') }}</p>
            <span class="icon">
              <i class="fas fa-caret-down" :class="{ 'rotate-180': isOpen(index) }"></i>
            </span>
          </div>
          <p v-show="isOpen(index)" class="pt-2">{{ $at(entry, 'a') }}</p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import all_faqs from '../../faqs.json'

export default {
  props: ['faqs'],
  name: 'FAQBlock',
  data() {
    return {
      openIndexes: []
    }
  },
  computed: {
    entries() {
      return this.faqs.map(f => all_faqs[f])
    },
    hasTwoColumns(){
      return (this.isDesktop() && this.entries.length > 2)
    }
  },
  methods: {
    toggle(index) {
      const idx = this.openIndexes.indexOf(index);
      if (idx !== -1) {
        this.openIndexes.splice(idx, 1)
      } else {
        this.openIndexes.push(index)
      }
    },
    isOpen(index) {
      return this.openIndexes.indexOf(index) !== -1
    }
  },
  mounted() {
    if (this.entries.length <= 2) {
      this.openIndexes.push(0);
    }
  }
}
</script>

<style>
.rotate-180 {
  transform: rotate(180deg);
}

.fixed-grid {
  display: grid;
  gap: 20px;
}

.has-2-cols {
  grid-template-columns: repeat(2, 1fr);
}

.has-1-cols {
  grid-template-columns: 1fr;
}
</style>
