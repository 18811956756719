
<template>
    <div class="splash-header" style="background: #232323;">
        <h1 class="title is-1 has-text-white has-text-centered">{{ $t('restaurant-page-header') }}</h1>
    </div>

		<div class="container py-6">
			<p class="has-text-centered py-6">{{ $t('restaurant-page-intro') }}</p>
		</div>


		<div v-for="r in restaurants" :key="r.name" class="has-text-white py-6" :style="r.style">
			<div class="container is-max-desktop">
				<h2 class="title has-text-white">{{ r.name }}</h2>
				<h6 class="subtitle is-6 has-text-white">
					<i18n-t keypath="restaurant-short-info" tag="p">
						<template #maplink>
							<a :href="r.maplink" target="_blank" class="has-text-white">
								{{ r.streetaddress }}
								<i class="fas fa-external-link-alt"></i>
							</a>
						</template>
						<template #walkingdistance>{{ r.walkingdistance }}</template>
						<template #sitelink>
							<a :href="r.url" target="_blank" class="has-text-white">
								{{ r.url }}
								<i class="fas fa-external-link-alt"></i>
							</a>
						</template>
					</i18n-t>
					<!--
					<i18n-t path="restaurant-short-info" tag="p">
						<template #link>
							<a :href="r.url">{{ r.streetaddaress }}</a>
						</template>
						<template #walkingdistance>{{ r.walkingdistance }}</template>
					</i18n-t>
					-->
				</h6>

				<p>{{ atranslate(r, 'description') }}</p>

				<div class="box">
					<h4 class="title is-4 has-text-centered">{{ $t('restaurant-menu-header') }}</h4>
					<div class="columns is-multiline is-centered">
						<div v-for="d in r.dishes" :key="d.name.en" class="column is-4">
							<div class="py-3">
								<h5 class="subtitle is-6 mb-0 has-text-grey" v-if="d.course">{{ $t('restaurant-menu-course-' + d.course) }}</h5>
								<h3 class="subtitle is-5">{{ atranslate(d, 'name') }}</h3>
								<p style="font-style: italic;">
									{{ atranslate(d, 'description') }}
								</p>

								<div v-for="prop in ['vegan', 'lactose_free', 'gluten_free']" :key="d.name.en + prop">
									<div v-if="['yes', 'on-request'].includes(d[prop])">
										<i v-if="prop == 'vegan'" class="fa fa-leaf has-text-success"></i>
										<i v-else class="fa fa-ban has-text-success"></i>
                                        
                                        {{ $t(prop + '-' + d[prop]) }}
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>

				<div class="box mt-6">
					<h4 class="title is-4 has-text-centered">{{ $t('restaurant-packages-header') }}</h4>
					<div class="columns is-multiline">
						<div v-for="p in r.packages" :key="p.name.en" class="column is-6">
							<div class="level">
								<div class="level-left">
									<h5 class="subtitle">{{ atranslate(p, 'name') }}</h5>
								</div>
								<div class="level-right">
									<p>{{ p.price }}{{ $t('currency') }}</p>
								</div>
							</div>
							<p>{{ atranslate(p, 'description') }}</p>
						</div>
					</div>

					<p class="has-text-grey" style="font-style: italic;">
						{{ $t('restaurant-package-short-info') }}
					</p>
				</div>
			</div>
		</div>

</template>
<script>
    import { store } from '../store'
    export default {
        name: 'PartnerPage',
        data: function () {
            return {
            }
        },
        components: {
        },
        computed: {
					restaurants () {
						let restaurants = store.venue ? store.venue.partners : []
						restaurants.forEach(r => {
							let img = '/apuz/venue/static/images/' + r.bgimg
							r.style = 'background: #232323; background-image: url("' + img + '"); background-size: cover; padding-top: 8rem; padding-bottom: 8rem;'
						})

						return restaurants
					},
					splashImage () {
						return store.customization.startsplash
					},
					language () {
						return store.language
					},
        },
        methods: {
        },
        mounted: function () {
        }
    }
</script>
