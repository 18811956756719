import { render } from "./AgeGuideBlock.vue?vue&type=template&id=3e1824f7&scoped=true"
import script from "./AgeGuideBlock.vue?vue&type=script&lang=js"
export * from "./AgeGuideBlock.vue?vue&type=script&lang=js"

import "./AgeGuideBlock.vue?vue&type=style&index=0&id=3e1824f7&scoped=true&lang=css"
script.render = render
script.__scopeId = "data-v-3e1824f7"
/* hot reload */
if (module.hot) {
  script.__hmrId = "3e1824f7"
  const api = __VUE_HMR_RUNTIME__
  module.hot.accept()
  if (!api.createRecord('3e1824f7', script)) {
    api.reload('3e1824f7', script)
  }
  
  module.hot.accept("./AgeGuideBlock.vue?vue&type=template&id=3e1824f7&scoped=true", () => {
    api.rerender('3e1824f7', render)
  })

}

script.__file = "src/components/blocks/AgeGuideBlock.vue"

export default script