import { render } from "./BookingDetailsPage.vue?vue&type=template&id=0d1e3992"
import script from "./BookingDetailsPage.vue?vue&type=script&lang=js"
export * from "./BookingDetailsPage.vue?vue&type=script&lang=js"

import "./BookingDetailsPage.vue?vue&type=style&index=0&lang=css"
script.render = render
/* hot reload */
if (module.hot) {
  script.__hmrId = "0d1e3992"
  const api = __VUE_HMR_RUNTIME__
  module.hot.accept()
  if (!api.createRecord('0d1e3992', script)) {
    api.reload('0d1e3992', script)
  }
  
  module.hot.accept("./BookingDetailsPage.vue?vue&type=template&id=0d1e3992", () => {
    api.rerender('0d1e3992', render)
  })

}

script.__file = "src/views/BookingDetailsPage.vue"

export default script