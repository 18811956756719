import { render } from "./AdviceBlock.vue?vue&type=template&id=767e3b5c&scoped=true"
import script from "./AdviceBlock.vue?vue&type=script&lang=js"
export * from "./AdviceBlock.vue?vue&type=script&lang=js"

import "./AdviceBlock.vue?vue&type=style&index=0&id=767e3b5c&scoped=true&lang=css"
script.render = render
script.__scopeId = "data-v-767e3b5c"
/* hot reload */
if (module.hot) {
  script.__hmrId = "767e3b5c"
  const api = __VUE_HMR_RUNTIME__
  module.hot.accept()
  if (!api.createRecord('767e3b5c', script)) {
    api.reload('767e3b5c', script)
  }
  
  module.hot.accept("./AdviceBlock.vue?vue&type=template&id=767e3b5c&scoped=true", () => {
    api.rerender('767e3b5c', render)
  })

}

script.__file = "src/components/blocks/AdviceBlock.vue"

export default script