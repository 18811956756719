<template>
    <div class="splash-header" :style="style">
        <h1 class="title is-1 has-text-white has-text-centered">{{ title }}</h1>
        <div v-if="text" style="max-width: 600px; margin: 10vh auto auto;">
            <p class="has-text-centered is-size-4">{{ text }}</p>
        </div>
    </div>
</template>
<script>
    export default {
        props: ['image', 'title', 'text', 'imagepath'],
        computed: {
            style () {
                let img = this.imagepath ? this.imagepath : '/apuz/venue/static/images/' + this.image
                return 'background-image: url("' + img + '");'
            }
        }
    }
</script>
<style>
.splash-header {
    padding-top: 150px;
    padding-bottom: 150px;
    background-size: cover;
    background-position: top center;
    color: white;
    text-shadow: 2px 2px 2px black;
}
</style>
