<template>
  <div>
    <div :style="splashstyle" class="pb-6">
			<h1 v-if="booking" class="title is-1 has-text-white has-text-centered">
        {{ $t('welcome') }}
			</h1>
			<h2 v-if="booking" class="subtitle has-text-centered has-text-white">Booking <em>{{ booking.date }}</em> with reference no. <em>{{ id }}</em></h2>

		</div>

        <article v-if="offerAccepted" class="message is-success">
          <div class="message-header">
            <p>{{ $t('offer-accepted-heading') }}</p>
          </div>
          <div class="message-body">
            {{ $t('offer-accepted-text') }}
          </div>
        </article>

		<div v-if="!offerAccepted && booking && ['offer', 'reservation'].includes(booking.mode)" class="container is-max-desktop has-text-centered">
			<h1 class="title is-size-2 my-6">{{ $t('offer-page-heading') }}</h1>
			<div class="columns is-centered">
				<div class="column is-half">
					<p v-if="booking" class="my-3">
						{{ $t('offer-page-first-text', {expiry: booking.reservation_expiry}) }}
					</p>

					<p class="my-3">
						{{ $t('offer-page-second-text') }}
					</p>

					<p class="my-3">
						{{ $t('offer-page-cancellation-info-text') }}
					</p>

					<div class="buttons has-addons mb-6 has-text-centered is-align-content-center">
						<button @click="reservationModal = 'accept'" class="button is-large is-success is-fullwidth">{{ $t('offer-page-accept-button') }}</button>
						<button @click="reservationModal = 'reject'" class="button is-large is-danger is-fullwidth">{{ $t('offer-page-reject-button') }}</button>
					</div>
				</div>
			</div>

		</div>

    <div v-if="booking && booking.deleted" class="notification is-info">
      <h1 class="title">{{ $t('booking-cancelled-heading')}}</h1>
      <p>
        {{ $t('booking-cancelled-text') }}
      </p>
    </div>

		<div v-if="['accept', 'reject'].includes(reservationModal)" class="modal is-active">
			<div @click="reservationModal = undefeined" class="modal-background"></div>
			<div class="modal-card">
				<header class="modal-card-head">
					<p v-if="reservationModal == 'accept'" class="modal-card-title">{{ $t('offer-accept-heading') }}</p>
					<p v-if="reservationModal == 'reject'" class="modal-card-title">{{ $t('offer-reject-heading') }}</p>
					<button @click="reservationModal = undefined" class="delete" aria-label="close"></button>
				</header>
				<section class="modal-card-body">
					<p v-if="reservationModal == 'accept'">
						{{ $t('offer-accept-modal-text') }}
					</p>
					<p v-if="reservationModal == 'reject'">
						{{ $t('offer-reject-modal-text') }}
					</p>
				</section>
				<footer class="modal-card-foot">
					<button v-if="reservationModal == 'accept'" @click="acceptReservation" class="button is-success">{{ $t('offer-accept-confirm-button') }}</button>
					<button v-if="reservationModal == 'reject'" @click="cancelBooking" class="button is-danger">{{ $t('offer-reject-confirm-button') }}</button>
					<button class="button" @click="reservationModal = undefined">{{ $t('offer-confirm-close-button') }}</button>
				</footer>
			</div>
		</div>

    <div v-if="booking && !booking.deleted" class="booking-details container px-4">


      <div class="tabs is-centered booking-details-tabs">
        <ul>
          <li v-for="t in tabs" :class="t.class" :key="'tab-' + t.handle">
            <a @click="tab = t.handle">
              <span class="is-hidden-mobile icon is-small"><i class="fa" :class="t.icon"></i></span>
              {{ t.label }}
              <span style="display: inline-block: width: 10px; height: 10px; background: red; border-radius: 10px;"></span>
            </a>
          </li>
        </ul>
      </div>
      <div class="mb-5">
        <BookingScheduleTab v-if="tab == 'schedule'" :booking="booking" />
        <BookingTeamsTab    v-if="tab == 'teams'"    :booking="booking" />
        <BookingPaymentTab  v-if="tab == 'payment'"  :booking="booking" />
        <BookingEditTab     v-if="tab == 'edit'"     :booking="booking" />
      </div>
    </div>
  </div>
</template>
<script>
  import axios from 'axios'
//  import { loadStripe } from '@stripe/stripe-js'
  import BookingPaymentTab from '../components/BookingPaymentTab.vue'
  import BookingEditTab from '../components/BookingEditTab.vue'
  import BookingScheduleTab from '../components/BookingScheduleTab.vue'
  import BookingTeamsTab from '../components/BookingTeamsTab.vue'
  import { store } from '../store'

  export default {
    name: 'BookingDetailsPage',
    data: function () {
        return {
            offerAccepted: false,
            reservationModal: undefined,
            showCancelDialogue: false,
            offers: undefined,
            fields: undefined,
            booking: undefined,
            stripe: undefined,
            cardErrorText: undefined,
            stripeClientSecret: undefined,
            card: undefined,
            invoice: {},
            paymethod: undefined,
            sendConfirmationEmail: false,
            sendCancellationEmail: false,
            tab: 'schedule'
        }
    },
    components: {
      BookingPaymentTab,
      BookingTeamsTab,
      BookingEditTab,
      BookingScheduleTab
    },
    computed: {
        id: function () { return this.$route.params.id },
        date: function () { return this.$route.params.date },
        remainingAmount: function () { return 1500 },
        price () { return this.booking ? this.booking.price : '-' },
        splashstyle () {
          return 'padding-top: 100px; background-image: url("/apuz/venue/static/images/' + store.customization.startsplash + '");'
        },
        splitPaymentLink: function () { return '/split-payment/' + this.date + '/' + this.id },
        tabs () {
          return [
            ['schedule', 'calendar-alt'],
            ['teams', 'users'],
            ['payment', 'credit-card'],
            ['edit', 'edit']
          ].map(([handle, icon]) => ({
            handle,
            icon: 'fa-' + icon,
            label: this.$t('tab-' + handle),
            class: this.tab == handle ? 'is-active': ''
          }))
        }
    },
    methods: {
      cancelBooking: function () {
        const url = '/booking/' + this.date + '/' + this.id + '.json'

        console.log('Trying to cancel booking, this is axios')
        console.log(axios)

        axios.post(url, {
          action: 'delete'
        }).then(() => {
          this.sendCancellationEmail = true
        })


      },
      acceptReservation: function () {
					this.booking.mode = 'booking'
					this.updateBooking()
      },
      updateBooking: function () {
        const url = '/booking/' + this.date + '/' + this.id + '.json'
        axios.post(url, {
          action: 'update',
          booking: this.booking
        })
      },
      stripePay: function (event) {
          event.preventDefault()
          axios.post('/booking/' + this.date + '/' + this.id + '/payment.json', {amount: this.remainingAmount}).then(result => {
            console.log(result.data)
            this.stripeClientSecret =result.data.client_secret

            this.stripe.confirmCardPayment(this.stripeClientSecret, {
              payment_method: {
                card: this.card,
                billing_details: {
                  name: this.booking.booker.name
                }
              }
            }).then(function(result) {
                if (result.error) {
                // Show error to your customer (e.g., insufficient funds)
                  console.log(result.error.message);
                } else {
                // The payment has been processed!
                if (result.paymentIntent.status === 'succeeded') {
                    // Show a success message to your customer
                    // There's a risk of the customer closing the window before callback
                    // execution. Set up a webhook or plugin to listen for the
                    // payment_intent.succeeded event that handles any business critical
                    // post-payment actions.
                }
                }
            })
        })
      },
    },
    watch: {
      paymethod: function () {
        if (this.paymethod == 'card') {
          setTimeout(() => {
            this.card = this.elements.create('card')
            this.card.mount('#card-element')
            this.card.on('change', event => this.cardErrorText =event.error ? event.error.message : undefined)
          }, 1500)
        }
      }
    },
    mounted: function () {
      setTimeout(() => {
          axios.get('/booking/' + this.date + '/' + this.id + '.json').then(async result => {
            this.booking = result.data
            if (this.booking) {
              if (this.booking.deleted) {
                store.signOut()
              } else {
                store.signIn(this.date, this.id)
              }
            }

            console.log('This is the booking')
            console.log(this.booking)

            for (let s of this.booking.slots) {
              s.arena_handle = s.arena
              s.arena = store.arenas[s.arena]
            }

/*
            if (this.booking.stripe_key) {
              this.stripe = await loadStripe(this.booking.stripe_key)
              this.elements = this.stripe.elements()
            }
*/

            console.log('This is the value of localStoraget ' + window.localStorage.getItem('sendConfirmationEmail'))
            if (window.localStorage.getItem('sendConfirmationEmail') == 'true') {
              this.sendConfirmationEmail = true
            }
          })
        }, 1500)
    }
  }
</script>
<style>

</style>
