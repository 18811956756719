<template>
    <div v-if="b">
			<div class="columns">
				<div v-for="method in ['onsite', 'invoice', 'online']" :key="method" class="column form-group">
					<label class="radio">
						<input type="radio" name="paymethod" v-model="b.paymethod" :value="method" />
						{{ $t('payment-tab-' + method) }}
					</label>
				</div>
			</div>

			<div v-if="b.paymethod == 'onsite'">
			

				<p>
					We are still working on an online payment solution, currently there is only possible to pay on-site
				</p>
			</div>

			<div v-if="b.paymethod == 'online'">
				<p>
						<span v-html="$t('booking-payment-pretext', { price: booking.price })"></span>
				</p>

				<h2 class="title">{{ remainingAmount }} {{$t('unpaid')}}</h2>
				<p>
						{{ $t('payment-terms-short') }}
				</p>

				<button @click="b.paymethod = 'card'" class="button is-primary">
						<i class="fa fa-credit-card"></i>&nbsp;
						{{ $t('pay-by-card-button')}}
				</button>

				<button class="button">
						<i class="fa fa-keyboard"></i>&nbsp;
						{{ $t('enter-code-button')}}
				</button>

				<button class="button">
						<i class="fa fa-envelope-open-text"></i>&nbsp;
						{{ $t('pay-by-invoice-button')}}
				</button>

				<p v-if="booking">
						<a :href="splitPaymentLink">Split payment</a> if you  are multiple people wanting to share the costs. However {{ booking.booker.name }}, remember
						that you remain our sole contract partner.
				</p>

				<div v-if="b.paymethod == 'card'">
						<form id="payment-form" style="max-width: 1024px;">
								<div id="card-element">
								</div>
								<div v-if="cardErrorText" role="alert"></div>
								<button id="submit" @click="stripePay">Pay</button>
						</form>

						<p>
								Payments are only refunded due to errors on our side, for example if we are not able to run your
								games due to technical, staffing issues or similar.
						</p>
				</div>
			</div>

			<div v-if="b.paymethod == 'invoice'">
				<div class="columns">
					<div class="column">
						<div class="field">
							<label class="label">Fakturamottagare</label>
							<input class="input" type="text" v-model="invoice.recipient" />
						</div>
						<div class="field">
							<label class="label">Organisationsnummer</label>
							<input class="input" type="text" v-model="invoice.orgno" />
						</div>
						<div class="field">
							<label class="label">Ev. avdelning</label>
							<input class="input" type="text" v-model="invoice.division" />
						</div>
					</div>
					<div class="column">
						<div class="field">
							<label class="label">Er referens</label>
							<input class="input" v-model="invoice.reference" />
						</div>
						<div class="field">
							<label class="label">Ert ordernummer eller rekvisitionsnummer</label>
							<input class="input" v-model="invoice.requisition" />
						</div>
					</div>
				</div>


				<div class="field">
					<label class="label">Leveransmetod</label>
					<div class="buttons has-addons">
						<button @click="invoice.method = 'email'" class="button">E-post med bifogad PDF</button>
						<button @click="invoice.method = 'print'" class="button is-selected">Brev</button>
						<button @click="invoice.method = 'e-invoice'" class="button">E-faktura</button>
					</div>
				</div>

				<div v-if="invoice.method == 'email'" class="field">
					<label class="label">E-postadress</label>
					<input v-model="invoice.email" type="email" class="input" />
				</div>

				<div v-if="invoice.method == 'e-invoice'" class="field">
					<p>
						{{ $t('invoicing-einvoice-info') }}
					</p>

					<div class="columns">
						<div class="column">
							<label class="label">GLN-nummer</label>
							<input type="text" class="input" v-model="invoice.gln" />
						</div>
						<div class="column">
							<label class="label">PEPPOL-id</label>
							<input type="text" class="input" v-model="invoice.peppolid" />
						</div>
					</div>
				</div>

				<div v-if="invoice.method == 'print'">
					<div class="columns">
						<div class="column field">
							<label class="label">Gata och husnummer</label>
							<input v-model="invoice.streetaddress" class="input" />
							<small>T.ex. Ågatan 13C, Box 1162</small>
						</div>
						<div class="column field">
							<label class="label">Postnummer</label>
							<input v-model="invoice.postalcode" class="input" />
						</div>
						<div class="column field">
							<label class="label">Ort</label>
							<input class="input" v-model="invoice.city" />
						</div>
					</div>
				</div>

				<button @click="save" class="button is-primary">Spara</button>
			</div>
    </div>
</template>
<script>
import axios from 'axios'
export default {
    props: ['booking'],
		data () {
			return {
				b: undefined,
			}
		},
		methods: {
			save () {
				axios.post('/booking/' + this.booking.date + '/' + this.booking.id + '.json', {
						action: 'update',
						booking: this.b
				}).then(() => {
					this.deleted = true
					this.showCancelDialogue = false
				})
			}
		},
		mounted () {
			// Hope that the no-mutation eslint warning doesn't pick up this :)
			this.b = this.booking

			if(!this.b.invoicedetails) {
				this.b.invoicedetails = {
					method: 'email'
				}
			}
			this.invoice = this.b.invoicedetails
		}

}
</script>
