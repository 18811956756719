<template>
    <div class="hero is-medium is-dark welcome">
      <div class="hero-body">
        <div class="container ">
          <div class="has-text-centered" style="margin: 0 auto auto; max-width: 600px; text-shadow: 0 0 3px rgba(0, 0, 0, 1);">
            <h1 class="title">{{ $t('404-page-title') }}</h1>
            <h2 class="subtitle">{{ $t('404-page-subtitle') }}</h2>
          </div>
        </div>
      </div>
    </div>
</template>
<script>
//    import { store } from '../store'

    export default {
        name: 'StartPage',
        data: function () {
          return {}
        },
        components: {
        },
        computed: {
        },
        watch: {
        }
    }
</script>
<style>

</style>
