<template>
    <table v-if="ready" border="0" cellpadding="0" cellspacing="0" width="100%" style="border-collapse: collapse;">
        <tr>
            <td style="color: #153643; font-family: Arial, sans-serif;" colspan="2">
                <h1 style="font-size: 24px; margin: 0;">{{ $t('booking-email-confirmation-title', {name: firstname}) }}</h1>
                <p style="margin: 0;">
                    <span v-html="$t('booking-email-confirmation-intro', {date: booking.date, time: booking.start, groupsize: groupsize, price: booking.price})"></span>
                </p>
            </td>
        </tr>
        <template v-for="s in booking.slots" :key="'tr1-' + s.arena">
            <tr>
                <td cellpadding="25">
                    <h4>{{ s.startTime }} {{ atranslate(s.arena, ['marketing', 'brandNames']) }}</h4>
                </td>
            </tr>
            <tr>
                <td width="200">
                    <img v-if="s.arena" :src="images[s.arena.handle + '.jpg'][mode]" width="200" />
                </td>
                <td>
                    <p style="margin-left: 25px;">
                        {{ atranslate(s.arena, ['marketing', 'shortFacts']) }}
                    </p>
                </td>
            </tr>
        </template>
				<tr>
					<td colspan="2">
						<center>

							<a :href="bookingPageUrl" :style="{backgroundColor: primarycolor}" style="font-size: 18px; font-family: Helvetica, Arial, sans-serif; font-weight: bold; text-decoration: none; padding: 14px 20px; color: #ffffff; border-radius: 5px; display: inline-block; mso-padding-alt: 0;">
									<!--[if mso]>
									<i style="letter-spacing: 25px; mso-font-width: -100%; mso-text-raise: 30pt;">&nbsp;</i>
									<![endif]-->
									<span style="mso-text-raise: 15pt;">{{ $t('booking-email-edit-button') }} &rarr;</span>
									<!--[if mso]>
									<i style="letter-spacing: 25px; mso-font-width: -100%;">&nbsp;</i>
									<![endif]-->
							</a>

						</center>
					</td>
				</tr>
        <tr>
            <td width="260" style="color: #153643; font-family: Arial, sans-serif; font-size: 16px; line-height: 24px; padding: 25px 0 0 0;">
                <h4>{{ $t('practical-info-short-header') }}</h4>
                <p style="margin: 0;">
                    {{ $t('practical-info-short-text', practicalInfo) }}
                </p>
            </td>
            <td width="260" style="color: #153643; font-family: Arial, sans-serif; font-size: 16px; line-height: 24px; padding: 25px 0 0 0;">
                <h4>{{ $t('teamsize-info-short-header') }}</h4>
                <p style="margin: 0;">
                    {{ $t('teamsize-info-short-text', teamsizeInfo) }}
                </p>
            </td>
        </tr>
    </table>
</template>
<script>
    import { store } from '../store'
    export default {
        props: ['booking', 'mode'],
        computed: {
            firstname () {
              let parts = this.booking.booker.name.split(' ')
              return parts[0].length > 2 ? parts[0] : this.booking.booker.name
            },
            ready () {
                return store && store.customization && store.venue
            },
            venue: function () {
                return store.venue
            },
            primarycolor () {
                return store.venue['graphical-profile'].primarycolor
            },
						groupsize () {
							let sum = 0
							for(let num of Object.values(this.booking.participants)) {
								sum += num
							}
							console.log('Computing groupsize to ' + sum)
							return sum
						},
            bookingPageUrl () {
                return window.location.origin + '/' + store.language + '/booking-details/' + this.booking.date + '/' + this.booking.id
            },
            images () {
                let imgs = {
                }

                for (let s of this.booking.slots) {
                    if (s.arena) {
                        imgs[s.arena.handle + '.jpg'] = {
                            preview: '/apuz/' + s.arena.handle + '/illustration/titlepage.webp',
                            path: '/mnt/gamerunner/arenas/' + s.arena.handle + '/images/en/titlepage.jpg',
                            cid: 'cid:' + s.arena.handle + '.jpg'
                        }
                    }
                }

                return imgs
            },
            teamsizeInfo () {
                return {
                    min_players: this.booking.teamsize_min_recommended,
                    absolute_min_players: this.booking.teamsize_min,
                    max_players: this.booking.teamsize_max_recommended,
                    absolute_max_players: this.booking.teamsize_max
                }
            },
            practicalInfo () {
                return {
                    arrival_minutes: 10
                }
            }
        },
        methods: {
        },
        watch: {
        },
        mounted () {
        }
    }
</script>
