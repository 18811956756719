import { render } from "./TeamphotoCollage.vue?vue&type=template&id=5da8b80e"
import script from "./TeamphotoCollage.vue?vue&type=script&lang=js"
export * from "./TeamphotoCollage.vue?vue&type=script&lang=js"

import "./TeamphotoCollage.vue?vue&type=style&index=0&lang=css"
script.render = render
/* hot reload */
if (module.hot) {
  script.__hmrId = "5da8b80e"
  const api = __VUE_HMR_RUNTIME__
  module.hot.accept()
  if (!api.createRecord('5da8b80e', script)) {
    api.reload('5da8b80e', script)
  }
  
  module.hot.accept("./TeamphotoCollage.vue?vue&type=template&id=5da8b80e", () => {
    api.rerender('5da8b80e', render)
  })

}

script.__file = "src/components/TeamphotoCollage.vue"

export default script