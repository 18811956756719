import { render } from "./StartPageArenaCountdown.vue?vue&type=template&id=0710484a"
import script from "./StartPageArenaCountdown.vue?vue&type=script&lang=js"
export * from "./StartPageArenaCountdown.vue?vue&type=script&lang=js"

import "./StartPageArenaCountdown.vue?vue&type=style&index=0&lang=css"
script.render = render
/* hot reload */
if (module.hot) {
  script.__hmrId = "0710484a"
  const api = __VUE_HMR_RUNTIME__
  module.hot.accept()
  if (!api.createRecord('0710484a', script)) {
    api.reload('0710484a', script)
  }
  
  module.hot.accept("./StartPageArenaCountdown.vue?vue&type=template&id=0710484a", () => {
    api.rerender('0710484a', render)
  })

}

script.__file = "src/components/StartPageArenaCountdown.vue"

export default script