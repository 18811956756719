<template>
		<SplashHeader class="pageheader" :image="splashImage" :title="$t('friends-page-header')"></SplashHeader>
    <div>

			<div class="container p-6">
				<p style="max-width: 600px; margin: 0 auto auto;" class="has-text-centered">
					{{ $t('friends-page-introtext') }}
				</p>
			</div>


      <div v-for="f in friends" :key="f.name" :style="friendstyle(f)" class="has-text-white">
				<section class="hero">
					<div class="hero-body">
						<h2 class="title is-size-3 has-text-centered has-text-white">{{ f.name }}</h2>
						<div class="columns">
							<div class="column">
								<h4 class="subtitle is-size-4 has-text-white">{{ atranslate(f, 'salesPitchTitle') }}</h4>
								<p>{{ atranslate(f, 'salesPitchText') }}</p>

								<p class="mt-3">
									<i class="fa fa-home"></i>
									{{ f.publicdetails.streetaddress }}
								</p>

								<p class="mt-3">
									<a :href="'https_//' + f.public_web_url + '/'" target="_blank" class="has-text-white">
										<i class="fa fa-globe"></i>
										{{ f.public_web_url }}
									</a>
								</p>

								<p class="mt-3">
									<a :href="'tel:' + f.publicdetails.phonenumber" class="has-text-white">
										<i class="fa fa-phone-alt"></i>
										{{ f.publicdetails.displayphonenumber }}
									</a>
								</p>


							</div>
							<div class="column">
								<img :src="f.mapSrc" style="width: 100%;" />
							</div>
						</div>
					</div>
				</section>

				<section v-for="(handle, index) in Object.keys(f.arenas)" :key="f.name + handle">
					<div class="columns is-desktop is-vcentered m-0">
						<div class="column p-0" v-if="index % 2 == 0 || ismobile" :class="{'is-6': !ismobile}">
							<img :src="'/apuz/friends/' + f.handle + '/' + handle + '/titlepage.jpg'" style="height: 50vh; display: block; max-height: 400px; width: 100%; object-fit: cover;"/>
						</div>
						<div class="column p-6">
							<div>
								<h4 class="title is-size-4 has-text-white">{{ atranslate(f.arenas[handle], 'marketing.brandNames') }}</h4>
								<p>{{ atranslate(f.arenas[handle], 'marketing.shortStories')}}</p>
								<p>{{ atranslate(f.arenas[handle], 'marketing.shortFacts')}}</p>

								<hr />
								<div class="columns">
									<div class="column">
										<i class="fa fa-users"></i>
										{{ f.arenas[handle].basic['teamsize-min-recommended'] }} -
										{{ f.arenas[handle].basic['teamsize-max-recommended'] }}
										{{ $t('participants') }}
									</div>
									<div class="column">
										<i class="fa fa-clock"></i> 60 min.
									</div>
									<div class="column">
										<a :href="'https://' + f.public_web_url + '/' + language + '/arena/' + handle" target="_blank" class="button button-small">
											Läs mer på {{ f.public_web_url }} &nbsp; <i class="fa fa-external-link-alt"></i>
										</a>
									</div>
								</div>

							</div>
						</div>
						<div class="column is-6 p-0" v-if="index % 2 && !ismobile">
							<img :src="'/apuz/friends/' + f.handle + '/' + handle + '/titlepage.jpg'" style="height: 50vh; display: block; max-height: 400px; width: 100%; object-fit: cover;"/>
						</div>
					</div>
				</section>
      </div>

    </div>
</template>
<script>
    import axios from 'axios'
    import { store } from '../store'
		import SplashHeader from '../components/SplashHeader.vue'
    export default {
        name: 'ArenaPage',
        data: function () {
            return {
              friends: [],
							googleApiKey: 'AIzaSyC-a9O6soxnFgOe5PcILRmzK1CcUr7WMrM',
							winwidth: window.innerWidth
            }
        },
        components: {
					SplashHeader
        },
        computed: {
					splashImage () {
						return store.customization.startsplash
					},
					language () {
						return store.language
					},
					ismobile () {
						return this.winwidth <= 1024
					}
        },
        methods: {
					friendstyle(f) {
						const style = {
							backgroundSize: 'cover',
							backgroundPosition: 'center',
							paddingBottom: '50px'
						}

						if (f.customization.generalDarkBg) {
							style.backgroundImage = 'url("/apuz/friends/' + f.handle + '/web-images/' + f.customization.generalDarkBg + '")'
						} else {
							style.background = f.branding.primarycolor
						}

						return style
					},
					onresize () {
						this.winwidth = window.innerWidth
					}
        },
        mounted: function () {
            axios.get('/apuz/friends.json').then((response) => {
                this.friends = response.data.friends

                for (let f of this.friends) {

                  f.address = [
                    f.publicdetails.streetaddress,
                    f.publicdetails.zipcode,
                    f.publicdetails.city
                  ].join('%20')

                  let endpoint = 'https://maps.googleapis.com/maps/api/staticmap?'
                  let params = {
                    zoom: 14,
                    size: '600x300',
                    maptype: 'roadmap',
                    key: this.googleApiKey,
                    markers: 'color:red|label:' + encodeURIComponent(f.name) + '|' + f.address
                  }

                  f.mapSrc = endpoint + Object.keys(params).map(k => encodeURIComponent(k) + '=' + encodeURIComponent(params[k])).join('&')
                }
						})

						window.addEventListener('resize', this.onresize)
        }
    }
</script>
