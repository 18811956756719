<template>
  <div class="team-pictures-block container">
    <section class="section p-0 has-background" :class="classes" :style="hasBackground">
      <div class="is-flex is-flex-direction-center is-flex-direction-column" style="height: 25vh;">
        <h1 class="title is-1 is-color-info">{{ $t('teampicture-included') }}!</h1>
      </div>
    </section>
    <section class="section p-0 has-background" v-for="(game, index) in games" :key="index" :class="classes" :style="hasBackground">
      <div class="is-flex is-flex-direction-center is-flex-direction-column" :style="{ height: isDesktop() ? '100vh' : '50vh' }">
        <img :src="'/teampics/' + game.id + '/800.jpg'" :style="game.style">
        <h2 class="title is-2 is-narrow is-color-info mb-5 mt-5 has-text-centered">{{ game.teamname }}</h2>
        <p class="subtitle has-text-centered">{{ $t('played-arena-at-date', {arena: getArenaName(game.arena), date: dateToTranslatedText(game.date)}) }}</p>
    </div>
    </section>
  </div>
</template>

<script>
import axios from 'axios'
import { store } from '../../store'

export default {
  name: 'TeamPicturesBlock',
  props: {
    blockObj: {
      type: Object
    }
  },
  data() {
    return {
      isSliding: false,
      games: [],
    };
  },
  computed: {
    hasBackground(){
      const background = this.blockObj.background

      if(background){
        if(this.isImageUrl(background)){
          return this.defaultBackground()
        } 
        else {
          return { 'backgroundColor': background }
        }
      }

      return this.defaultBackground()
    },
    classes () {
      let classes = {}
      if (this.hasBackground.class) {
        classes[this.hasBackground.class] = true
      }
      return classes
    },
  },
  methods: {
    computeVisibility() {
      return true
    },
    defaultBackground(){
      return { class: "has-background-dark", darkMode: true }
    },
    defaultTheme(){
      return 'light'
    },
    getArenaName(arenaName){
      return this.$at(store.arenas[arenaName], 'marketing.brandNames')
    },
    isImageUrl(background) {
      const imagePattern = /\.(jpg|jpeg|png|webp|gif)$/i;
      return imagePattern.test(background);
    }
  },
  mounted: function () {
    let url = '/gameinfo/latest.json?hasTeampic=true'

    axios.get(url).then((result) => {
      console.log(result)
      this.games = result.data.games.filter(g => g.hasTeampic).slice(0, 3).map(g => {
        g.style = 'transform: rotate(' + ((Math.random()-0.5) * 10) + 'deg);'
        return g
      })
    })
  },

};
</script>

<style scoped>
.team-pictures-block section {
  position: sticky;
  top: 0%;
}

@media (max-width: 768px) {
  .team-pictures-block section {
    position: sticky;
    top: 10%;
  }
}
</style>
