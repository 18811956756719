<template>
    <div class="container">

        <div class="has-text-centered my-5">
          <button @click="showCancelDialogue = true" class="button is-danger">
            <i class="fa fa-ban"></i>&nbsp;
            Avboka
          </button>
        </div>

        <div v-if="showCancelDialogue" class="modal is-active">
					<div @click="showCancelDialogue = false" class="modal-background"></div>
					<div class="modal-card">
							<header class="modal-card-head">
								<p class="modal-card-title">{{ $t('booking-cancel-confirm-title')}}</p>
								<button @click="showCancelDialogue = false" class="delete" aria-label="close"></button>
							</header>
							<section class="modal-card-body">
							<p>
									{{ $t('booking-cancel-confirm-text')}}
							</p>
							</section>
							<footer class="modal-card-foot">
								<button @click="showCancelDialogue = false" class="button">{{ $t('booking-form-abort-cancel-button') }}</button>
								<button @click="cancelBooking()" class="button is-danger">{{ $t('booking-form-confirm-cancel-button') }}</button>
							</footer>
					</div>
        </div>

				<div v-if="deleted" class="modal is-active">
					<div @click="refresh()" class="modal-background"></div>
					<div class="modal-card">
							<header class="modal-card-head">
								<p class="modal-card-title">{{ $t('booking-deleted') }}</p>
								<button @click="refresh()" class="delete" aria-label="close"></button>
							</header>
							<section class="modal-card-body">
								<p>
								</p>
							</section>
							<footer class="modal-card-foot">
								<button @click="refresh()" class="button">{{ $t('close') }}</button>
							</footer>
					</div>
        </div>

        <h3 class="title is-3">{{ $t('tab-schedule') }}</h3>

        <h5 class="title is-5">{{ hhmmadd(booking.start, -15) }}<span class="has-text-grey">-{{ booking.start }}</span>
            {{ $t('schedule-arrival-heading') }}
        </h5>

        <p class="mb-4">{{ $t('schedule-arrival-text') }}</p>

        <h5 v-for="s in sortedSlots" :key="s.arena.handle + s.startTime" class="title is-5 ">
            {{ s.startTime }}<span class="has-text-grey">-{{ s.endTime }}</span>
            {{ atranslate(s.arena, ['marketing', 'brandNames'])}}
        </h5>


        <p class="mb-4">{{ $t('schedule-games-text') }}</p>

        <h5 class="title is-5">
            {{ booking.end }}<span class="has-text-grey">-{{ hhmmadd(booking.end, 10) }}</span>
            {{ $t('schedule-debriefing-heading') }}
        </h5>

        <p>{{ $t('schedule-debriefing-text') }}</p>
        <p>
            {{ $t('schedule-outro-text') }}
        </p>

        <div class="columns">
          <div class="column">
              <h5 class="title is-5 mt-3">
                  <i class="fa fa-bus-alt"></i>
                  {{ $t('find-us-public-transport-header') }}
              </h5>
              <p>{{ $t('find-us-public-transport-text') }}</p>
          </div>
          <div class="column">
              <h5 class="title is-5 mt-3">
                  <i class="fa fa-car"></i>
                  {{ $t('find-us-car-header') }}
              </h5>
              <p>{{ $t('find-us-car-text') }}</p>
          </div>
      </div>

  </div>
</template>
<script>
    import axios from 'axios'
    export default {
        props: ['booking'],
        data () {
          return {
            deleted: false,
              showCancelDialogue: false
          }
        },
        computed: {
            sortedSlots () {
                return [...this.booking.slots].sort((a, b) => a.startTime.localeCompare(b.startTime))
            }
        },
        methods: {
          refresh () {
            window.location = '/'
          },
          cancelBooking () {
            axios.post('/booking/' + this.booking.date + '/' + this.booking.id + '.json', {
                action: 'delete'
            }).then(() => {
              this.deleted = true
              this.showCancelDialogue = false
            })
          }
        }
    }
</script>
