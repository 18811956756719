<template>
    <div :style="bgstyle" class="start-arena-countdown">
        <div class="hero has-text-white">
            <div class="hero-body">
                <div class="container has-text-centered is-max-desktop">
                    <h1 class="title has-text-white is-size-1">{{ atranslate(arena, ['marketing', 'brandNames'], 'Arena name') }}</h1>
                    <div style="max-width: 600px; margin: 0 auto 2rem;">
                        <div class="columns">
                        <div class="column"><i class="fa fa-user-friends"></i> {{ teamsize }} {{ $t('participants') }}</div>
                        <div class="column"><i class="far fa-clock"></i> 60 {{ $t('minutes') }}</div>
                        <div class="column"><i class="fa fa-tachometer-alt"></i> 80% {{ $t('success-rate') }}</div>
                        </div>

                        <h2 class="is-size-4">{{ atranslate(arena, ['marketing', 'shortStories'], '') }}</h2>
                        <p class="is-size-5">{{ atranslate(arena, ['marketing', 'shortFacts'], '') }}</p>

                        <div class="columns">
                        <div class="column">
                            <a :href="arena.href">
                                <button class="button is-light is-fullwidth is-medium">{{ $t('read-more') }}</button>
                            </a>
                            </div>
                        <div class="column">
                            <router-link :to="bookingLink">
                                <button class="button is-link is-fullwidth is-medium">{{ $t('book-now') }}</button>
                            </router-link>
                        </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="countdown">
            <div class="container is-max-desktop">
                <div class="columns">
                    <div v-for="i in 6" :style="digitstyle(i)" :key="'countdown-digit' + i" class="column digit"></div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
    import { store } from '../store'
    export default {
        props: ['arena'],
        data () {
            return {
                timer: 3600
            }
        },
        computed: {
            bookingLink () {
                return '/' + store.language + '/booking'
            },
            bgstyle () {
                let bgimg = 'url("/apuz/' + this.arena.handle + '/illustration/titlepage.webp?w=1200")'
                let bgfade = 'linear-gradient(0deg, rgba(0, 0, 0, 0.9) 30%, rgba(0, 0, 0, 0) 100%)'
                return `background-image: ${bgfade}, ${bgimg}; background-size: cover;`
            },
            teamsize () {
                return this.arena['teamsize-min-recommended'] + ' - ' + this.arena['teamsize-max-recommended']
            },
            hhmmss () {
                return '00' +  this.pad(Math.floor(this.timer / 60), 2) + ':' + this.pad(this.timer % 60, 2)
            }
        },
        methods: {
            pad (n, width, z) {
                z = z || '0'
                n = n + ''
                return n.length >= width ? n : new Array(width - n.length + 1).join(z) + n
            },
            digitstyle (i) {
                return 'background-image: url("/apuz/' + this.arena.handle + '/illustration/pubweb-countdown-tiles.webp?w=200");' +
                'background-position-y: ' + (parseInt(this.hhmmss[i]) * 11.1) + '%;'
            }
        },
        mounted () {
            setInterval(() => { this.timer = Math.max(0, this.timer - 1) }, 1000)
        }
    }
</script>
<style>
.start-arena-countdown .countdown {
    margin-bottom: -75px;
}
.start-arena-countdown .countdown .digit {
    background-size: 100%;
    padding-top: 35%;
}
</style>
