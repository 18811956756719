import { render } from "./BookingPage.vue?vue&type=template&id=2432c384&scoped=true"
import script from "./BookingPage.vue?vue&type=script&lang=js"
export * from "./BookingPage.vue?vue&type=script&lang=js"

import "./BookingPage.vue?vue&type=style&index=0&id=2432c384&scoped=true&lang=css"
script.render = render
script.__scopeId = "data-v-2432c384"
/* hot reload */
if (module.hot) {
  script.__hmrId = "2432c384"
  const api = __VUE_HMR_RUNTIME__
  module.hot.accept()
  if (!api.createRecord('2432c384', script)) {
    api.reload('2432c384', script)
  }
  
  module.hot.accept("./BookingPage.vue?vue&type=template&id=2432c384&scoped=true", () => {
    api.rerender('2432c384', render)
  })

}

script.__file = "src/views/BookingPage.vue"

export default script