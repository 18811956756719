<template>
    <div :class="classes">
        <h1 v-if="title && layout != 'focusbox'">{{ title }}</h1>
        <div :class="cssclass">
            <h1 v-if="title && layout == 'focusbox'">{{ title }}</h1>
            <slot></slot>
        </div>

        <span style="display: block; height: 0px; clear: both;"></span>
    </div>
</template>
<script>
    export default {
        props: ['title', 'cssclass', 'layout', 'wrapper'],
        name: 'ContentBlock',
        computed: {
            classes: function () {
                let classes = ['content-block']
                if (this.wrapper !== false) {
                    classes.push('wrapper')
                }
                if (this.layout == 'focusbox') {
                    classes.push('focusbox')
                }
                return classes
            }
        }
    }
</script>
<style>
    .content-block {
        color: white;
        padding: 2vmin;
        clear: both;
    }

    .content-block.focusbox > div {
        background: white;
        color: black;
        padding: 25px;
    }

    .content-block h1 {
        text-align: center;
    }

    .content-block > div {
        margin: 0 auto auto;
    }

    .content-block.wrapper > div {
        max-width: 1024px;
    }

</style>
