<template>
    <div class="panel">
        <div class="panel-heading">{{ $t('booking-search-type-header') }}</div>
				<div class="tabs is-centered is-boxed mt-3">
					<ul>
						<li @click="$emit('type', 'private')" :class="{'is-active': type == 'private'}">
							<a>
								<i class="fa fa-user-friends"></i>
								{{ $t('booker-type-private') }}
							</a>
						</li>
						<li @click="$emit('type', 'company')" :class="{'is-active': type == 'company'}">
							<a>
								<i class="fa fa-university"></i>
								{{ $t('booker-type-company') }}
							</a>
						</li>
					</ul>
        </div>

        <!-- Private input -->
        <div class="panel-block is-block">
            <div v-for="pg in groups" :key="pg" class="is-block mt-5">
                <label class="label">
                {{ $t(pg) }}

                </label>
                <div class="field has-addons">
                <div class="control" v-for="i in range(0, 9)" :key="'participant-' + pg + '-' + i">
                    <button
                        @click="lParticipants[pg] = i"
                        class="button is-small"
                        :class="{'is-primary': participants[pg] == i}"
                        :data-count="i" :data-type="pg">
                        {{ i }}
                    </button>
                </div>
                  <div class="is-small select control">
                    <select v-model="lParticipants[pg]" :class="{'is-primary': lParticipants[pg] > 9, 'button': lParticipants[pg] > 9}" class="participants-dropdown" :data-type="pg">
                      <option :value="lParticipants[pg] < 10 ? lParticipants[pg] : 0">{{ $t('more') }}</option>
                      <option v-for="i in range(10, 30)" :key="'participant-' + pg + '-' + i" :value="i">{{ i }}</option>
                    </select>
                  </div>
                </div>

                <p class="help" v-if="$t('participant-short-info-' + pg).length > 0">{{ $t('participant-short-info-' + pg) }}</p>
            </div>
        </div>
    </div>
</template>
<script>
    export default {
        props: ['type', 'participants'],
        emits: ['type', 'participants'],
        data () {
            return {
                moreAdults: false,
                moreStudents: false,
                moreChildren: false,
                moreEmployees: false,
                lParticipants: {}
            }
        },
        computed: {
            groups () {
                console.log('Computing groups, these are my types')
                console.log(this.type)
                return {
                    'private': ['adults', 'students', 'children'],
                    'company': ['employees']
                }[this.type]
            }
        },
        methods: {
            range(i, max) {
            let out = []
            while(i <= max) {
                out.push(i)
                i++
            }
            return out
            },
        },
        watch: {
            lParticipants: {
                deep: true,
                handler () {
                    console.log('We have a change in participants!')
                    this.$emit('participants', this.participants)
                }
            }
        },
        beforeMount () {
            this.lParticipants = this.participants
        }
    }
</script>
