import { render } from "./ArenaPhotoAlbum.vue?vue&type=template&id=191f2c78"
import script from "./ArenaPhotoAlbum.vue?vue&type=script&lang=js"
export * from "./ArenaPhotoAlbum.vue?vue&type=script&lang=js"

import "./ArenaPhotoAlbum.vue?vue&type=style&index=0&lang=css"
script.render = render
/* hot reload */
if (module.hot) {
  script.__hmrId = "191f2c78"
  const api = __VUE_HMR_RUNTIME__
  module.hot.accept()
  if (!api.createRecord('191f2c78', script)) {
    api.reload('191f2c78', script)
  }
  
  module.hot.accept("./ArenaPhotoAlbum.vue?vue&type=template&id=191f2c78", () => {
    api.rerender('191f2c78', render)
  })

}

script.__file = "src/components/ArenaPhotoAlbum.vue"

export default script