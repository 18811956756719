import { render } from "./FAQBlock.vue?vue&type=template&id=3895cbc0"
import script from "./FAQBlock.vue?vue&type=script&lang=js"
export * from "./FAQBlock.vue?vue&type=script&lang=js"

import "./FAQBlock.vue?vue&type=style&index=0&lang=css"
script.render = render
/* hot reload */
if (module.hot) {
  script.__hmrId = "3895cbc0"
  const api = __VUE_HMR_RUNTIME__
  module.hot.accept()
  if (!api.createRecord('3895cbc0', script)) {
    api.reload('3895cbc0', script)
  }
  
  module.hot.accept("./FAQBlock.vue?vue&type=template&id=3895cbc0", () => {
    api.rerender('3895cbc0', render)
  })

}

script.__file = "src/components/blocks/FAQBlock.vue"

export default script