import { reactive } from 'vue'
import { participantsHash } from './main'
import audiences from '/storage/tmp/audiences.json'

import axios from 'axios'
import dayjs from 'dayjs'
import customizations from '/storage/venue/pubweb/customization.json'

export const store = reactive({
  arenas: {},
  pages: {},
  audiences,
  textblocks: {},
	language: window.location.pathname.split('/')[1],
	languages: ['en'],
  menuTab: undefined,
  expandMenu: false,
  startMessages: [],
  customization: {},
  zenmode: false,
  showParticipants: false,
  bookingDate: undefined,
	bookingId: undefined,
	today: undefined,
	bookingEnabledDays: undefined,
  arenasLoaded: undefined,

	getVenueCapacity () {
		return {
			abs_min: Math.min(...Object.values(this.arenas).map(a => a['teamsize-min'])),
			rec_min: Math.min(...Object.values(this.arenas).map(a => a['teamsize-min-recommended'])),
			rec_max: Object.values(this.arenas).map(a => a['teamsize-max-recommended']).reduce((a, b) => a + b, 0),
			abs_max: Object.values(this.arenas).map(a => a['teamsize-max']).reduce((a, b) => a + b, 0)
		}
	},

  getPages: function () {
    return Object.values(this.pages[this.language] || {})
  },
  getTextblocks: function () {
    return Object.values(this.textblocks || {})
  },
  bookingEditUrl: function () {
    return (this.bookingDate && this.bookingId) ? ('/' + this.language + '/booking-details/' + this.bookingDate + '/' + this.bookingId) : false
  },
  getArenaByHandle (handle) {
    return this.arenas[handle]
  },
  signOut: function () {
    window.localStorage.removeItem('booking-id')
    window.localStorage.removeItem('booking-date')
    this.bookingDate = undefined
    this.bookingId = undefined

    window.location = '/'
  },
  signIn: function (date, id) {
    window.localStorage.setItem('booking-id', id)
    window.localStorage.setItem('booking-date', date)
    this.bookingDate = date
    this.bookingId = id
  }
})

export const mutations = {
  setArenas (arenas) {
    if (arenas) {
        for (let a of Object.keys(arenas)) {
          arenas[a].href = '/' + store.language + '/arena/' + a

					for (let key of ['teamsize-max', 'teamsize-max-recommended', 'teamsize-min', 'teamsize-min-recommended']) {
						arenas[a][key] = parseInt(arenas[a][key])
					}
        }

        store.arenas = arenas
    }
  }
}

store.arenasLoaded = new Promise((resolve, reject) => {
  axios.get('/apuz/all-arenas.json').then((result) => {
    mutations.setArenas(result.data.arenas)
    resolve()
  }).catch(() => {
    console.error('Failed to load all-arenas.json!')
    reject('Failed to load arenas!')
  })
})

store.venuePromise = new Promise((resolve, reject) => {
	axios.get('/apuz/venue.json').then((result) => {
		store.venue = result.data

    for (const audience of Object.values(store.audiences)) {
      for (const group of audience['group-templates']) {
        let hash = participantsHash(group.participants)
        group.bestOffer = store.venue.bestOffers[hash]

      }
    }

		store.languages = store.venue.languages
		store.pages = result.data.pages
    store.textblocks = result.data.textblocks

    store.venue.startMessages.forEach(m => {
			m.class = 'opacity0'
			return m
		})
		store.startMessages = store.venue.startMessages

		// This really shouldn't go here!
		document.title = store.venue['name']

		resolve(store.venue)
	}).catch((err) => {
    console.error("Failed to load venue info, giving up!")
    console.error(err)
    reject("Failed to load venue info, giving up!")
	})
})

axios.get('/booking/summary/' + dayjs().format('YYYY-MM-DD') + '.json').then((result) => {
    store.today = result.data
})


store.customization = customizations

if (customizations.themecolor) {
	var meta = document.createElement('meta')
	meta.name = "theme-color"
	meta.content = customizations.themecolor
	document.getElementsByTagName('head')[0].appendChild(meta)
}
