<template>
  <div class="modal is-active">
    <div @click="$emit('close')" class="modal-background"></div>
    <div class="modal-card">

      <header class="modal-card-head">
        <p class="modal-card-title">{{ $t('booking-more-info') }}</p>
        <button @click="$emit('close')" class="delete" aria-label="close"></button>
      </header>

      <section class="modal-card-body">

        <p v-if="offer.slots.length == 1" v-html="$t('offer-info-teamsize-single', teamsizeInfo)"></p>

        <p v-if="offer.slots.length > 1" v-html="$t('offer-info-teamsize-multi', teamsizeInfo)"></p>


        <div class="columns my-3">
          <div v-for="s in offer.slots" :key="s.arena.handle" class="column">
            <h5 class="subtitle">{{ s.startTime }} {{ atranslate(s.arena, ['marketing', 'brandNames'], s.arena.handle) }}</h5>

						<img :src="'/apuz/' + s.arena.handle + '/illustration/titlepage.webp?w=320'" style="object-fit: cover;" />

            <p v-if="atranslate(s.arena, ['marketing', 'shortFacts'], '').length > 0">
              {{ atranslate(s.arena, ['marketing', 'shortFacts'], '').substr(0, 200) }}...
            </p>
            <p v-else>
              {{ atranslate(s.arena, ['marketing', 'shortFacts'], '') }}
            </p>
          </div>
        </div>

        <article class="message is-warning" v-if="offer.warnings.includes('too-many-players')">
          <p class="message-body">{{ $t('too-many-players-text') }}</p>
        </article>
        <article class="message is-warning" v-if="offer.warnings.includes('too-few-players')">
          <p class="message-body">{{ $t('too-few-players-text') }}</p>
        </article>
        <article class="message is-warning" v-if="offer.warnings.includes('offset-slots')">
          <p class="message-body">{{ $t('offset-slots-text') }}</p>
        </article>

        <div class="has-text-centered my-3">
          <button @click="$emit('book')" class="button is-primary">Boka nu</button>
        </div>

        <FaqList :tag="booking"></FaqList>
      </section>
      <footer class="modal-card-foot">

      </footer>
    </div>
  </div>
</template>

<script>
import FaqList from './FaqList.vue'

export default {
  name: 'OfferInfo',
  props: ['offer'],
  data: function () {
    return {
    }
	},
	watch: {
	},
  components: {
    FaqList
  },
  computed: {
    arenas: function () {
      console.log(this.offer)
      let arenas = []
      for (let s of this.offer.slots) {
        arenas.push(s.arena)
      }

      return arenas

    },
    teamsizeInfo () {
      return {
        min_players: this.offer.teamsize_min_recommended,
        absolute_min_players: this.offer.teamsize_min,
        max_players: this.offer.teamsize_max_recommended,
        absolute_max_players: this.offer.teamsize_max
      }
    },
  },
  methods: {
  }
}
</script>
