<template>
    <div class="box has-background-black arena-photo-album">
        <div class="hero-body">
            <h2 class="subtitle is-size-3 has-text-grey-lighter">{{ $t('pictures-from-the-challenge') }}</h2>
        </div>
        <div>
            <a :href="selected.url + '?w=2400'" target="_blank">
                <figure class="image">
                    <img :src="selected.url + '?w=1024'" class="photo" />
                </figure>
            </a>

            <p class="has-text-white has-text-right">{{ $t('pictures-authentic')}}</p>

            <div class="thumbs mt-3" ref="thumbs">
                <div class="slider is-clearfix">
                    <div v-for="(p, i) in photos" :key="p.filename" @click="view(i, $event)">
                        <figure class="image">
                            <img :src="p.url + '?w=128'" />
                        </figure>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
    export default {
        name: 'ArenaPhotoAlbum',
        props: ['photos'],
        data () {
            return {
                selected: this.photos[0]
            }
        },
        methods: {
            view (i, evt) {
                let thumbOffset = evt.target.offsetParent.offsetLeft
                let width = evt.target.getBoundingClientRect().width
                let winwidth = window.innerWidth
                let scroll = thumbOffset + (width / 2) - (winwidth / 2)

                this.selected = this.photos[i]
                this.$refs['thumbs'].scrollLeft = scroll
            }
        }
    }
</script>
<style>
    .arena-photo-album {
        background: #232323;
    }
    .arena-photo-album {

    }
    .arena-photo-album .photo {
        height: 75vh;
        max-height: 100vw;
        object-fit: cover;
    }

    .arena-photo-album .thumbs {
        overflow-x: auto;
        -ms-overflow-style: none;  /* Internet Explorer 10+ */
        scrollbar-width: none;
        width: 100%;
    }
    .arena-photo-album .thumbs::-webkit-scrollbar {
        display: none;  /* Safari and Chrome */
    }

    .arena-photo-album .thumbs .slider > div {
        float: left;
        width: 128px;
        margin: 10px;
    }
    .arena-photo-album .thumbs .slider {
        width: 2000px;
    }

    .arena-photo-album .thumbs figure {
        width: 128px;
        height: 90px;
    }

    .arena-photo-album .photo .column{
        flex-grow: 1 !important;
    }

    .arena-photo-album .thumbs img {
        object-fit: cover;
        height: 100%;
        width: 100%;
        box-shadow: 0 0 5px rgba(0, 0, 0, 0.3);
    }
</style>
