<template>
    <table border="0" cellpadding="0" cellspacing="0" width="100%" style="border-collapse: collapse;">
        <tr>
            <td style="color: #153643; font-family: Arial, sans-serif;" colspan="2">
                <h1 style="font-size: 24px; margin: 0;">{{ $t('booking-email-cancellation-title', {name: firstname}) }}</h1>
                <p style="margin: 0;">
                    <span v-html="html"></span>
                </p>
            </td>
        </tr>
    </table>
</template>
<script>
    import { store } from '../store'
    export default {
        props: ['booking'],
        data () {
        },
        computed: {
          html () {
            return this.$t('booking-email-cancellation-intro', {
              date: this.booking.date,
              time: this.booking.start,
              id: this.booking.id,
              groupsize: this.booking.teamsize,
              price: this.booking.price
            })
          },
            firstname () {
                let parts = this.booking.booker.name.split(' ')
                return parts[0].length > 2 ? parts[0] : this.booking.booker.name
            },
            ready () {
                return store && store.customization
            },
            venue: function () {
                return store.venue
            },
            primarycolor () {
                return store.venue['graphical-profile'].primarycolor
            },
            bookingPageUrl () {
                return window.location.origin + '/' + store.language + '/booking-details/' + this.booking.date + '/' + this.booking.id
            },
            images () {
                let imgs = {
                }
                return imgs
            },
            teamsizeInfo () {
                return {
                    min_players: this.booking.teamsize_min_recommended,
                    absolute_min_players: this.booking.teamsize_min,
                    max_players: this.booking.teamsize_max_recommended,
                    absolute_max_players: this.booking.teamsize_max
                }
            },
            practicalInfo () {
                return {
                    arrival_minutes: 10
                }
            }
        },
        methods: {
        },
        watch: {
        },
        mounted () {
        }
    }
</script>
