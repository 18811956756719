<template>
    <div class="hero is-fullheight is-dark arena-hero" :style="bgstyle">
        <div class="hero-body">
            <div class="container has-text-centered is-max-desktop pt-5">
            <h2 class="title is-size-2">{{ atranslate(arena, ['marketing', 'brandNames'], 'Arena name') }}</h2>

            <div style="max-width: 800px; margin: 0 auto 2rem;">
                <div class="columns">
                <div class="column"><i class="fa fa-user-friends"></i> {{ teamsize }} {{ $t('participants') }}</div>
                <div class="column"><i class="far fa-clock"></i> 60 {{ $t('minutes') }}</div>
                <div class="column"><i class="fa fa-tachometer-alt"></i> {{ arena.marketing.successRate }}% {{ $t('success-rate') }}</div>
                <div class="column">
                    <i class="fa fa-globe-europe"></i> 
                    {{ arena.languages.map(l => $t('language-'+ l)).join(", ") }}
                </div>
                
                </div>

                <h2 class="is-size-5">{{ atranslate(arena, ['marketing', 'shortStories'], '') }}</h2>
                <p class="is-size-6">{{ atranslate(arena, ['marketing', 'shortFacts'], '') }}</p>

                <div class="columns mt-5">
                    <div class="column">
                        <a :href="arena.href">
                            <button class="button is-light is-fullwidth is-medium">{{ $t('read-more') }}</button>
                        </a>
                        </div>
                    <div class="column">
                        <router-link :to="bookingLink()">
                            <button class="button is-link is-fullwidth is-medium">{{ $t('book-now') }}</button>
                        </router-link>
                    </div>
                </div>
            </div>
            </div>
        </div>
    </div>
</template>
<script>
//    import { store } from '../store'
    export default {
        props: ['arena'],
        computed: {
            bgstyle () {
                return 'background-image: url("/apuz/' + this.arena.handle + '/illustration/titlepage.webp?w=1200");'
            },
            teamsize () {
                return this.arena['teamsize-min-recommended'] + ' - ' + this.arena['teamsize-max-recommended']
            }
        }
    }
</script>
