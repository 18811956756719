import { render } from "./ArenaStackEntry.vue?vue&type=template&id=7bb036b1"
import script from "./ArenaStackEntry.vue?vue&type=script&lang=js"
export * from "./ArenaStackEntry.vue?vue&type=script&lang=js"

import "./ArenaStackEntry.vue?vue&type=style&index=0&lang=css"
script.render = render
/* hot reload */
if (module.hot) {
  script.__hmrId = "7bb036b1"
  const api = __VUE_HMR_RUNTIME__
  module.hot.accept()
  if (!api.createRecord('7bb036b1', script)) {
    api.reload('7bb036b1', script)
  }
  
  module.hot.accept("./ArenaStackEntry.vue?vue&type=template&id=7bb036b1", () => {
    api.rerender('7bb036b1', render)
  })

}

script.__file = "src/components/ArenaStackEntry.vue"

export default script