<template>
  <div class="content mb-0 " :key="$route">
    <template v-for="([blockType, Component]) in Object.entries(blockmap)">
      <ContentBlock v-if="blockVisible(blockType)" :key="blockType" :blockType="blockType" :blockComponent="blockmap[blockType]" :audience="audience">
        <template v-slot:content-block>
          <component :is="Component" :audience="audience" :blockObj="audience.landingBlocks[blockType]"></component>
        </template>
      </ContentBlock>
    </template>
  </div>
</template>

<script>
import { store } from '../store'
import { markRaw } from 'vue'
import AdviceBlock from '../components/blocks/AdviceBlock.vue'
import TeamPicturesBlock from '../components/blocks/TeamPicturesBlock.vue'
import AgeGuideBlock from '../components/blocks/AgeGuideBlock.vue'
import CapacityBlock from '../components/blocks/CapacityBlock.vue'
import PriceExamplesBlock from '../components/blocks/PriceExamplesBlock.vue'
import ArenaSelectBlock from '../components/blocks/ArenaSelectBlock.vue'
import FAQBlock from '../components/blocks/FAQBlock.vue'
import ArenaSlidesBlock from '../components/blocks/ArenaSlidesBlock.vue'
import ConceptBlock from '../components/blocks/ConceptBlock.vue'
import RestaurantBlock from '../components/blocks/RestaurantBlock.vue'
import PaymentBlock from '../components/blocks/PaymentBlock.vue'
import OpeningScheduleBlock from '../components/blocks/OpeningScheduleBlock.vue'
import ContentBlock from '../components/blocks/ContentBlock.vue'

export default {
  name: 'AudienceLandingPage',
  data: function () {
    return {
      blockmap: {
        capacity: markRaw(CapacityBlock),
        arenaSlides: markRaw(ArenaSlidesBlock),
        concept: markRaw(ConceptBlock),
        review: markRaw(TeamPicturesBlock),
        priceExamples: markRaw(PriceExamplesBlock),
        restaurant: markRaw(RestaurantBlock),
        ageGuide: markRaw(AgeGuideBlock),
        //arenaSelect: markRaw(ArenaSelectBlock),
        openingHours: markRaw(OpeningScheduleBlock),
        //payment: markRaw(PaymentBlock),
      }
    }
  },
  components: {
    FAQBlock,
    AdviceBlock,
    PriceExamplesBlock,
    TeamPicturesBlock,
    AgeGuideBlock,
    CapacityBlock,
    ArenaSelectBlock,
    ArenaSlidesBlock,
    ConceptBlock,
    RestaurantBlock,
    PaymentBlock,
    OpeningScheduleBlock,
    ContentBlock,
  },
  computed: {
    audience: function () { return this.$route.meta.audience },
    language: function () { return store.language },
  },
  watch: {
    audience() {
      if (this.audience && this.audience['dark-mode']) {
        store.darkMode = this.audience['dark-mode']
      }
    },
  },
  methods: {
    blockVisible(blockKey) {

      if (!this.audience.landingBlocks[blockKey]) {
        return true;
      }

      const block = this.audience.landingBlocks[blockKey]

      if (block.visibility == 'hidden') { return false }
      if (block.visibility == 'visible') { return true }

      if (this.blockmap[blockKey].methods.computeVisibility) {
        return this.blockmap[blockKey].methods.computeVisibility(this.audience)
      }

      console.error('Unable to call computeVisibility on a block with auto visibility')
      console.log(this.blockmap[blockKey])

      return false
    },
  },
  beforeUnmount() {
    store.darkMode = false
  }
}
</script>
