<template>
    <div>
        <SplashHeader class="pageheader" :image="splashImage" :title="$t('booking-search-title')"></SplashHeader>
        <BookingSearchForm @clear="clear" @submit="search($event)" @campaign="campaign = $event"></BookingSearchForm>

        <div v-if="showError" class="error-block">
            <h3>{{ $t('booking-search-fail-header') }}</h3>
            <p>
                {{ $t('booking-search-fail-text') }}
            </p>
            <p>
                {{ errorDetails }}
            </p>
        </div>

        <div v-if="codeNotFound" class="error-block">
            <h3 class="subtitle has-text-white">{{ $t('booking-code-not-found-header') }}</h3>
            <p>
                {{ $t('booking-code-not-found-text') }}
            </p>
        </div>

        <progress v-if="loading" class="progress is-large is-info" max="100">60%</progress>

        <BookingOfferList v-else-if="offers && offers.length > 0" :offers="offers"></BookingOfferList>

        

        <div v-else-if="availability == 'campaign-rejected'" class="notification is-warning">
          <h3 class="title is-3">{{ $t('booking-campaign-rejected-title') }}</h3>
          <p>{{ $t('booking-campaign-rejected-text') }}</p>
          <p>{{ atranslate(campaign, 'rejectMessages') }}</p>
        </div>

        <div v-else-if="availability == 'fully-booked'" class="notification is-warning" data-booking-search-status="fully-booked">
            <h3 class="title is-3">{{ $t('booking-availability-fully-booked-title') }}</h3>
            <p>{{ $t('booking-availability-fully-booked-text') }}</p>
        </div>

        <div v-else-if="availability == 'on-request'" class="notification is-warning">
            <h3 class="title is-3">{{ $t('booking-availability-on-request-title') }}</h3>
            <p>{{ $t('booking-availability-on-request-text') }}</p>
        </div>

        <div v-else-if="availability == 'unavailable'" class="notification is-warning">
            <h3 class="title is-3">{{ $t('booking-availability-unavailable-title') }}</h3>
            <p>{{ $t('booking-availability-unavailable-text') }}</p>
        </div>

        <BookingPitch></BookingPitch>
    </div>
</template>
<script>
    import { store } from '../store'
    import axios from 'axios'
    import BookingSearchForm from '../components/BookingSearchForm.vue'
    import BookingOfferList from '../components/BookingOfferList.vue'
    import SplashHeader from '../components/SplashHeader.vue'
    import BookingPitch from '../components/BookingPitch.vue'

    export default {
        name: 'BookingPage',
        data: function () {
            return {
                offers: undefined,
                fields: undefined,
                errorDetails: undefined,
                searched: false,
                showError: false,
                loading: false,
                availability: undefined,
								codeNotFound: false,
                campaign: null
            }
        },
        components: {
            BookingSearchForm,
            SplashHeader,
            BookingOfferList,
            BookingPitch
        },
        computed: {
          splashImage () {
            return store.customization.startsplash
          }
        },
        methods: {
					clear () {
						this.offers = []
					},
					search: function (fields) {
							this.codeNotFound = false
							this.loading = true
							this.fields = fields

							for (let p of Object.keys(fields.participants)) {
									fields[p] = fields.participants[p]
							}

							delete fields.participants

							this.showError = false
							this.errorDetails = undefined
							axios.get('/booking/' + this.fields.date + '/offers.json', {params: this.fields }).then((response) => {
									this.loading = false
									if (response.data.status == 'failed' && response.data.reason == 'code-not-found') {
										this.codeNotFound = true
										return
									}

									this.availability = response.data.availability
									this.offers = response.data.offerings.map(o => {
											o.slots.map(s => {
													s.arena_handle = s.arena
													s.arena = store.arenas[s.arena]

													return s
											})
											o.warnings = []
											if (o.teamsize > o.teamsize_max_recommended) {
													o.warnings.push('too-many-players')
											}
											if (o.teamsize < o.teamsize_min_recommended) {
													o.warnings.push('too-few-players')
											}

											for (let s of o.slots) {
												if (s.startTime != o.slots[0].startTime) {
													o.warnings.push('offset-slots')
													break
												}
											}
											return o
									})
							}).catch((error) => {
									this.loading = false
									this.showError = true
									this.errorDetails = error.message
							})
					}
        },
    }
</script>
<style scoped>
    .no-offers {
        text-align: center;
        padding: 25px;
    }
</style>
