import { render } from "./FaqList.vue?vue&type=template&id=3f14dcd4"
import script from "./FaqList.vue?vue&type=script&lang=js"
export * from "./FaqList.vue?vue&type=script&lang=js"
script.render = render
/* hot reload */
if (module.hot) {
  script.__hmrId = "3f14dcd4"
  const api = __VUE_HMR_RUNTIME__
  module.hot.accept()
  if (!api.createRecord('3f14dcd4', script)) {
    api.reload('3f14dcd4', script)
  }
  
  module.hot.accept("./FaqList.vue?vue&type=template&id=3f14dcd4", () => {
    api.rerender('3f14dcd4', render)
  })

}

script.__file = "src/components/FaqList.vue"

export default script