<template>
    <div v-if="ready" class="content-page gift-certificate">
			<h1 v-if="!campaign">Loading...</h1>
			<div v-else class="has-text-centered" style="min-height: 80mm">

        <img v-if="theme == 'christmas'" class="christmas-header" src="/static/img/giftcard-christmas-header.webp" />

        <div v-if="theme == 'party'" class="party-header"></div>

				<h1 class="title my-5">{{ title }}</h1>
				<div class="columns">
					<div class="column">
						<p>
							{{ greeting }}
						</p>
					</div>
					<div class="column">
						<p class="signatures">
							{{ signature }}
						</p>
					</div>
				</div>
			</div>


			<div style="min-height: 80mm;">
				<h3 class="subtitle has-text-centered">{{ $at(campaign, 'sellingTitles') }}.</h3>

				<div class="columns is-mobile is-centered">
					<div class="column is-narrow">
						<span class="tag is-primary is-large">
							{{ $t('giftcard-code', {code}) }}
						</span>
					</div>
				</div>

				<p class="has-text-centered my-5">
					{{ $at(venue, 'salesPitchText') }}
				</p>

				<div class="columns">
					<div v-for="arena in sortedArenas" :key="'arena-' + arena.handle" class="column">

						<h3 class="subtitle is-size-6 has-text-centered" style="height: 12mm;">
							{{ $at(arena, 'marketing.brandNames') }}
						</h3>

						<figure class="image is-3by2">
							<img :src="'/apuz/' + arena.handle + '/illustration/titlepage.webp?w=240'" style="width: 100%;" />
						</figure>
						<p class="is-size-7" style="height: 25mm; overflow: hidden;">
							{{ $at(arena, 'marketing.shortStories') }}
						</p>
						<div style="height: 8mm; margin-top: -8mm;"></div>
					</div>
				</div>
			</div>

			<div style="position: fixed; bottom: 0; padding-right: 10mm; padding-bottom: 10mm;">


				<div class="columns">
					<div class="column">
						<h5 class="subtitle is-size-6">{{ $t('giftcard-nice-booking-title') }}</h5>
						<p class="is-size-7">
              {{ $t('giftcard-nice-booking-text') }}
						</p>
					</div>
					<div class="column">
						<h5 class="subtitle is-size-6">{{ $t('giftcard-nice-validity-title') }}</h5>
						<p class="is-size-7">
                            {{ $at(campaign, 'purchasingTerms') }}
						</p>
						<p class="is-size-7">
                            {{ $t('giftcard-nice-validity-text') }}
						</p>
					</div>
				</div>


				<div class="columns">
					<div class="column is-three-quarters">
						<p class="is-size-7">
                          {{ venue.public_web_url }}
                          {{ venue.publicdetails.streetaddress }}
                          {{ venue.publicdetails.zipcode }}
                          {{ venue.publicdetails.city }}
                        </p>
					</div>
					<div class="column">
						<div>
							<img src="/apuz/venuelogo/banner/dark/transparent.svg" style="width: 100%;" />
						</div>
					</div>
				</div>
			</div>

    </div>
</template>
<script>
	import axios from 'axios'
	import { store } from '../store'
	export default {
		name: 'GiftCertificate',
		data () {
				return {
						booking: undefined,
						sent: false,
						campaign: undefined
				}
		},
		components: {
		},
		computed: {
			signature () { return this.params.get('signature') },
			title () { return this.params.get('title') },
			greeting () { return this.params.get('greeting') },
			theme() { return this.params.get('theme') },
      code: function () { return this.$route.params.code },
			arenas: function () {
				return store.arenas
			},
			sortedArenas: function () {
				return Object.keys(this.arenas).sort().map((handle) => this.arenas[handle])
			},
			params () {
				return new URLSearchParams(window.location.search)
			},
			venue () { return store.venue },
			ready () { return this.venue }
		},
		methods: {
		},
		mounted () {
			store.zenmode = true

			axios.get('/booking/campaign/code-info/' + this.code + '.json').then(async result => {
				this.campaign = result.data.campaign
			})
		}
	}
</script>
<style>
  @page {
    size: A4;
    margin: 0;
  }
</style>
<style scoped>
.gift-certificate {
  -webkit-print-color-adjust: exact !important;
  -webkit-print-color-adjust: exact;
		margin: 10mm;
	}

	p {
		font-size: 11pt;
	}

	.signatures {
		font-size: 50px;
		font-family: "giftcard-signature-font"
	}

	@font-face {
		font-family: "giftcard-signature-font";
		src: url("/webfonts/BilboSwashCaps-Regular.ttf") format("truetype");
	}
  .christmas-header {
    margin: -10mm;
    margin-bottom: -40mm;
    object-fit: cover;
    height: 70mm;
    width: 210mm;
  }

  .party-header {
    background-image: url('/static/img/giftcard-party-header.webp');
    margin: -10mm;
    margin-bottom: -40mm;
    object-fit: cover;
    height: 70mm;
    width: 210mm;
  }


</style>
