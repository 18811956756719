import { render } from "./OpeningScheduleBlock.vue?vue&type=template&id=0d0d9567"
import script from "./OpeningScheduleBlock.vue?vue&type=script&lang=js"
export * from "./OpeningScheduleBlock.vue?vue&type=script&lang=js"
script.render = render
/* hot reload */
if (module.hot) {
  script.__hmrId = "0d0d9567"
  const api = __VUE_HMR_RUNTIME__
  module.hot.accept()
  if (!api.createRecord('0d0d9567', script)) {
    api.reload('0d0d9567', script)
  }
  
  module.hot.accept("./OpeningScheduleBlock.vue?vue&type=template&id=0d0d9567", () => {
    api.rerender('0d0d9567', render)
  })

}

script.__file = "src/components/blocks/OpeningScheduleBlock.vue"

export default script