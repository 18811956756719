import { render } from "./RestaurantPackagesPage.vue?vue&type=template&id=af4df99a"
import script from "./RestaurantPackagesPage.vue?vue&type=script&lang=js"
export * from "./RestaurantPackagesPage.vue?vue&type=script&lang=js"
script.render = render
/* hot reload */
if (module.hot) {
  script.__hmrId = "af4df99a"
  const api = __VUE_HMR_RUNTIME__
  module.hot.accept()
  if (!api.createRecord('af4df99a', script)) {
    api.reload('af4df99a', script)
  }
  
  module.hot.accept("./RestaurantPackagesPage.vue?vue&type=template&id=af4df99a", () => {
    api.rerender('af4df99a', render)
  })

}

script.__file = "src/views/RestaurantPackagesPage.vue"

export default script