<template>
  <div class="container">
    <h1 class="title is-color-info">{{ $t('restaurant-block-header') }}</h1>
    <div v-if="restaurants && restaurants.length">
      <div class="content">
        <div class="box box-with-image is-align-content-center" :style="style">
          <div class="content restaurant-image-content">
            <p class="is-size-5 has-text-white">{{ $t('restaurant-block-text') }}</p>
            <div class="mb-1">
              <div class="is-flex is-justify-content-space-between ">
                <p class="is-size-4 has-text-white mb-0">{{ $at(getCheapestMeal, 'name') }}</p>
                <p class="is-size-4 has-text-white mb-0">{{ getCheapestMeal.price }}</p>
              </div>
              <p class="is-link is-size-6 is-align-self-flex-end has-text-white">{{ getCheapestMeal.restaurant + ", " + getCheapestMeal.address }}</p>
            </div>
            <div>
              <div class="is-flex is-justify-content-space-between has-text-white">
                <p class="is-size-4 has-text-white mb-0">{{ $at(getMostExpensiveMeal, 'name') }}</p>
                <p class="is-size-4 has-text-white mb-0">{{ getMostExpensiveMeal.price }}</p>
              </div>
              <p class="is-link is-size-6 is-align-self-flex-end has-text-white">{{ getMostExpensiveMeal.restaurant + ", " + getMostExpensiveMeal.address }}</p>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="columns pt-5">
      <div class="column is-flex">
        <div class="box">
          <h2 class="subtitle">{{ $t('restaurant-block-invoice-header') }}</h2>
          <p>{{ $t('restaurant-block-invoice-text') }}</p>
        </div>
      </div>
      <div class="column is-flex">
        <div class="box">
          <h2 class="subtitle">{{ $t('restaurant-block-alcohol-header') }}</h2>
          <p>{{ $t('restaurant-block-alcohol-text') }}</p>
        </div>
      </div>
      <div class="column is-flex">
        <div class="box">
          <h2 class="subtitle">{{ $t('restaurant-block-cost-control-header') }}</h2>
          <p>{{ $t('restaurant-block-cost-control-text') }}</p>
        </div>
      </div>
      <div class="column is-flex">
        <div class="box">
          <h2 class="subtitle">{{ $t('restaurant-block-dietary-options-header') }}</h2>
          <p>{{ $t('restaurant-block-dietary-options-text') }}</p>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { store } from "../../store.js";
export default {
  name: 'RestaurantBlock',
  data() {
    return {

    }
  },
  props: {
    audience: Object
  },
  computed: {
    restaurants() {
      let restaurants = store.venue ? store.venue.partners : []
      return restaurants
    },
    style() {
      let img = '/apuz/venue/static/images/' + this.audience.landingBlocks.restaurant.illustration
      return 'background-image: url("' + img + '");'
    },
    getCheapestMeal() {
      let cheapestMeal = null
      this.restaurants.forEach((restaurant) => {
        restaurant.packages.forEach((mealPackage) => {
          if (cheapestMeal == null || mealPackage.price < cheapestMeal.price) {
            cheapestMeal = mealPackage
            cheapestMeal.restaurant = restaurant.name
            cheapestMeal.address = restaurant.streetaddress
          }
        })
      })
      return cheapestMeal
    },
    getMostExpensiveMeal() {
      let mostExpensive = null
      this.restaurants.forEach((restaurant) => {
        restaurant.packages.forEach((mealPackage) => {
          if (mostExpensive == null || mealPackage.price > mostExpensive.price) {
            mostExpensive = mealPackage
            mostExpensive.restaurant = restaurant.name
            mostExpensive.address = restaurant.streetaddress
          }
        })
      })
      return mostExpensive
    }
  },
  methods: {
    computeVisibility(audience) {
      const hasPartners = store.venue?.partners?.length > 0;
      return hasPartners && audience['menu-corporate'];
    }
  }
}
</script>
<style scoped>
.section {
  height: 75vh;
}

.box-with-image {
  height: 50vh;
  background-size: cover;
  background-position: center;
  padding: 0;
  overflow: hidden;
}

.restaurant-image-content {
  display: flex;
  flex-direction: column;
  width: 50%;
  background-color: rgb(0 0 0 / 61%);
  padding: 2rem;
  height: 100%;
  justify-content: space-around;
}

@media (max-width: 768px) {
  .restaurant-image-content {
    width: 100%;
  }
}
</style>
