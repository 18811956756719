
<template>
    <div class="content-page">

			<div v-if="!zenmode" class="cover">
				<img src="/apuz/venue/static/images/footer.png">
				<h1 class="title has-text-white has-text-centered">{{ $t('giftcard-receipt-header') }}</h1>
			</div>


			<div v-if="info" class="container" style="min-height: 80mm">

				<h1 class="title">{{ atranslate(info, 'sellingTitles') }}</h1>
				<h2 class="subtitle">{{ atranslate(info, 'purchasingTerms') }}</h2>

				<table class="table">
          <tr>
            <th>{{ $t('seller') }}</th>
            <td>{{ venue.publicdetails.businessname }}</td>
          </tr>
          <tr>
						<th>{{ $t('giftcard-purchase-date') }}</th>
						<td>{{ receipt.creation_date }}</td>
					</tr>
					<tr>
						<th>{{ $t('giftcard-buyer-name') }}</th>
						<td>{{ receipt.name }}</td>
					</tr>
					<tr>
						<th>{{ $t('giftcard-receipt-code') }}</th>
						<td>{{ code }}</td>
					</tr>
					<tr>
						<th>{{ $t('giftcard-receipt-amount') }}</th>
						<td>{{ info.sellingPrice }}{{ $t('currency') }}</td>
					</tr>
					<tr>
						<th>{{ $t('giftcard-receipt-vat') }}</th>
						<td>{{ $t('giftcard-receipt-vat-amount') }}</td>
					</tr>
					<tr v-if="info.expired">
						<th>{{ $t('giftcard-info-expired-title') }}</th>
						<td>{{ $t('giftcard-info-expired-text') }}</td>
					</tr>
					<tr v-else>
						<th>{{ $t('giftcard-info-not-expired-title') }}</th>
						<td>{{ $t('giftcard-info-not-expired-text') }}</td>
					</tr>
        </table>

				<p class="mt-3">
					{{ atranslate(info, 'purchasingTerms') }}
				</p>

        <p v-if="venue" class="is-size-7 has-text-grey">{{ $t('giftcard-right-of-withdrawal-text', {email: venue.publicdetails.email}) }}</p>
			</div>
      <div v-else>
        <h1 class="has-text-centered size-1"><i class="fa fa-spinner fa-spin"></i></h1>
      </div>

			<div v-if="zenmode">
        <a :href="viewUrl">{{ $t('giftcard-view-online-link') }}</a>
      </div>

			<div v-if="!zenmode" class="container mt-3">
				<h3 class="title">{{ $t('giftcard-customize-header') }}</h3>

				<div class="columns">
					<div class="column field">
						<label class="label">{{ $t('giftcard-title-label') }}</label>
						<input class="input" v-model="title" />
					</div>
					<div class="column field">
						<label class="label">{{ $t('giftcard-signature-label') }}</label>
						<input class="input" v-model="signature" />
					</div>
				</div>
				<div class="columns">
					<div class="column field">
						<label class="label">{{ $t('giftcard-greeting-label') }}</label>
						<textarea class="input" v-model="greeting"></textarea>
					</div>
					<div class="column field">
						<label class="label">{{ $t('giftcard-theme-label') }}</label>
						<select class="input" v-model="theme">
							<option value="christmas">{{ $t('giftcard-theme-christmas') }}</option>
							<option value="party">{{ $t('giftcard-theme-party') }}</option>
							<option value="neutral">{{ $t('giftcard-theme-neutral') }}</option>
						</select>
					</div>
				</div>

				<button @click="download" class="button is-primary mx-3">
          <i class="fa fa-file-pdf"></i>
          &nbsp;
          {{ $t('gift-certificate-download') }}
        </button>
			</div>

    </div>
</template>
<script>
  import axios from 'axios'
	import { store } from '../store'
	export default {
		name: 'GiftCertificateReceipt',
		data () {
				return {
					sent: false,
					campaign: undefined,
					info: undefined,
					title: undefined,
					greeting: undefined,
					receipt: undefined,
					theme: "party",
					signature: undefined
				}
		},
		components: {
		},
		computed: {
      ready () { return this.info && this.venue },
			campaignId: function () { return this.$route.params.campaign || this.$route.query.campaign},
			receiptId: function () { return this.$route.params.receipt || this.$route.query.receipt},
            zenmode () { return this.$route.query.zenmode == 'true' },
			venue () { return store.venue },
      viewUrl () {
        return window.location.origin + '/' + store.language + '/gift-certificate-receipt/' + this.campaignId + '/' + this.receiptId
      }
    },
		methods: {
			preview () {
				let url = '/en/gift-certificate/' + this.code
				url += '?title=' + encodeURIComponent(this.title)
				url += '&greeting=' + encodeURIComponent(this.greeting)
				url += '&signature=' + encodeURIComponent(this.signature)
				url += '&theme=' + encodeURIComponent(this.theme)

				window.open(url)
			},
			download () {
				let url = '/booking/certificate.pdf'
				url += '?code=' + this.code
				url += '&language=' + this.$route.params.language
				url += '&title=' + encodeURIComponent(this.title)
				url += '&greeting=' + encodeURIComponent(this.greeting)
				url += '&signature=' + encodeURIComponent(this.signature)
				url += '&theme=' + encodeURIComponent(this.theme)

				window.open(url)
			},
      refresh () {
        axios.get('/booking/giftcard-receipt/' + this.campaignId + '/' + this.receiptId + '.json').then(async result => {



          this.receipt = result.data

          this.code = result.data.code

        axios.get('/booking/campaign/code-info/' + this.code + '.json').then(async result => {
          if(!result.data.campaign) {
            setTimeout(() => { this.refresh() }, 1000)
            return
          }

          this.info = result.data.campaign
        })
      })
      }
		},
		mounted () {
      this.refresh()
		}
	}
</script>
<style scoped>
</style>
