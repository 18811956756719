<template>
    <div>
				<h2>You have {{ booking.slots.length }} teams</h2>
        <BookingSlot @update="updatedata" :key="slot.arena + slot.startTime" v-for="slot in booking.slots" :s="slot" :booking="booking"></BookingSlot>
        <button @click="save" class="button is-primary float-right mt-3" style="float: right;">Save</button>
        <br style="clear: both;" />
    </div>
</template>
<script>
    import axios from 'axios'
    import BookingSlot from './BookingSlot.vue'
    export default {
				props: ['booking'],
				emits: ['update'],
        components: {
            BookingSlot
				},
        methods: {
					updatedata (update) {
						console.log(update)
						const slot = update.slot
						for(const key of ['teamname', 'language', 'participants']) {
							if(update[key]) {
								slot[key] = update[key]
							}
						}
						console.log(slot)

						console.log('This is the booking objectsa')
						console.log(this.booking)
					},
            save () {
							console.log('Saving booking objects')
							console.log(this.booking)

                axios.post('/booking/' + this.booking.date + '/' + this.booking.id + '.json', {
                    action: 'update',
                    booking: this.booking
                })
            }
        }
    }
</script>
