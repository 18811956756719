
<template>
  <div v-if="format == 'small'">
    <div class="columns">
      <div v-for="a in arenas" :key="a.handle" class="column">
        <ArenaStackSmallEntry :arena="a" :format="format"></ArenaStackSmallEntry>
      </div>
    </div>
  </div>
  <div v-else class="arena-stack has-background-dark">
    <ArenaStackEntry v-for="a in arenas" :arena="a" :key="a.handle" :format="format"></ArenaStackEntry>
  </div>
</template>

<script>
import ArenaStackEntry from './ArenaStackEntry.vue'
import ArenaStackSmallEntry from './ArenaStackSmallEntry.vue'
import { store } from '../store'

export default {
  name: 'ArenaStack',
  data: function () {
    return {
    }
  },
  components: {
    ArenaStackEntry,
    ArenaStackSmallEntry
  },
  props: {
    format: String,
    msg: String
  },
  computed: {
    arenas: function () {
      return store.arenas
    },
    sortedArenas: function () {
      return Object.keys(this.arenas).sort.map((handle) => this.arenas[handle])
    }
  }
}
</script>
<style scoped>
  .arena-stack {
    margin-top: 10vh;
  }
  h1 {
    text-align: center;
    margin-bottom: 5vh;
    color: white;
  }
</style>
