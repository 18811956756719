export const faq = [
  {
    tags: ['booking'],
    en: [
      'Can I cancel a reservation?',
      'Reservations may be cancelled free of charge at any time before the scheduled visit. Cancellation is done online with a link provided in the reservation confirmation email.'
    ],
    sv: [
      'Går det att avboka?',
      'Vi har fri avbokning fram till spelstart. Avbokning görs online med hjälp av en länk i bekräftelsemailet.'
    ]
  },
  {
    tags: ['booking'],
    en: [
      'Can we change our group size?',
      'Yes, as long as the challenges in your package is suitable for your group size. You can adjust your participants online after you have made your reservation. The price will be updated, with fewer participants you pay less and if you bring more people you will pay a little more.'
    ],
    sv: [
      'Kan vi ändra antalet deltagare?',
      'Ja, så länge som banorna du bokat passar din nya gruppstorlek. Du ändrar själv deltagarantalet online efter att du bokat. Priset kommer att ändras, blir ni färre så betalar ni mindre, blir ni fler betalar ni lite mer.'
    ]
  },
  {
    tags: ['booking'],
    en: [
      'Do I have to pay immediately when making a reservation?',
      'You can wait with payment until the day of your visit, and if you are a company or an organization you can also opt to pay by invoice.'
    ],
    sv: [
      'Måste jag betala direkt vid bokning?',
      'Det går bra att vänta med betalning fram till besöket. Företag och organisationer kan dessutom välja att betala i efterskott mot faktura.'
    ]
  },
  {
    tags: ['booking'],
    en: [
      'At what time should we arrive?',
      'We aim to start the experiences at the booked time. Please arrive a few minutes early in order to store your valuables away and get yourself ready for the game!'
    ],
    sv: [
      'När bör vi vara på plats?',
      'Vi försöker starta upplevelserna på utsatt tid. Kom gärna några minuter tidigt för att ha tid att låsa in värdesaker och göra er redo för utmaningarna!'
    ]
  },
  {
    tags: ['booking'],
    en: [
      'What clothes are appropritate to wear?',
      'Ordinary casual or office attire is perfectly fine. Challenges are played indoors and no running or climbing is involved, but teams searching for an extra edge may consider swapping their Louboutin heels for sneakers ;)'
    ],
    sv: [
      'Vilken klädsel passar?',
      'Vanlig vardags- eller kontorsklädsel passar utmärkt. Utmaningarna spelas inomhus och man behöver varken klättra eller springa. Lag som vill skaffa sig en liten fördel gör klokt att byta sina Louboutin-klackar mot sneakers ;)'
    ]
  },
  {
    tags: ['booking'],
    en: [
      'Can we get a starting time not offered online?',
      'Sometimes we can make adjustments of up to 30 minutes. Please reserve the closest suggested time and then respond to the confirmation email with a request to adjust your starting time.'
    ],
    sv: [
      'Kan vi boka andra tider än de som föreslås?',
      'Ibland kan vi göra justeringar på upp till 30 minuter. Boka i så fall tiden närmast den du önskar och svara sedan på bekräftelsemailet med en förfrågan om att få ändrad starttid.'
    ]
  }
]


export const faqs = (language, tag) => {
  console.log('Filtering for ' + language + ' and ' + tag)
  return faq.filter(entry => {
    console.log(entry)
    if (tag && !entry.tags.includes(tag)) {
      console.log('Rejecting, tag mismatch')
      return false
    }

    if (!entry[language]) {
      console.log('Rejecting, language mismatch')
      return false
    }

    return true
  }).map(entry => ({
      title: entry[language][0],
      text: entry[language][1],
      expanded: false
  }))
}
