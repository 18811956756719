<template>
  <div>
    <div class="splash-header pageheader" :style="splashstyle">
			<h1 class="title is-1 has-text-white has-text-centered">
        {{ $t('menu-manage-booking') }}
			</h1>
		</div>

		<section class="section">
			<div class="container">

				<div v-if="status == 'fail'">
					{{ $t('booking-sign-in-failed') }}
				</div>


				<div class="columns">
					<div class="column field">
						<label class="label">Booking date</label>
						<input v-model="date" type="date" class="input" />
					</div>

					<div class="column field">
						<label class="label">Booking reference number</label>
						<input v-model="id" type="text" class="input" />
					</div>
				</div>

				<button class="button is-primary is-pulled-right" @click="login">{{ $t('sign-in-button') }}</button>

			</div>
		</section>
  </div>
</template>
<script>
  import axios from 'axios'
  import { store } from '../store'
	import { router } from '../main.js'

  export default {
    name: 'BookingLoginPage',
    data: function () {
        return {
					id: undefined,
					date: undefined,
					secret: undefined,
					status: undefined
        }
    },
    components: {
    },
    computed: {
        splashstyle () {
          return 'background-image: url("/apuz/venue/static/images/' + store.customization.startsplash + '");'
        },
    },
    methods: {
			login () {
				this.status = 'busy'
				axios.get('/booking/' + this.date + '/' + this.id + '.json?secret=' + this.secret).then(async result => {
					console.log(result)
					this.status = 'success'

					store.signIn(this.date, this.id)

					const destination = '/' + store.language + '/booking-details/' + this.date + '/' + this.id
					console.log('Redirecting to ' + destination)
					router.push(destination)


				}).catch((err) => {
					console.log(err)
					this.status = 'fail'
				})


			}
    },
    watch: {
    },
    mounted: function () {
			console.log('Mounted!')

			let params = new URLSearchParams(window.location.search)
			let qsDate = params.get('date')
			let qsId = params.get('id')
			let qsSecret = params.get('secret')

			if(qsDate && qsId && qsSecret) {
				console.log('Got login data from querystring!')

				this.date = qsDate
				this.id = qsId
				this.secret = qsSecret

				this.login()
			}
    }
  }
</script>
<style>
</style>
