<template>
  <div class="container mt-5 p-2">
    <div class="columns">
      <div class="column is-6">
        <div v-if="showMonth" class="panel">
          <div class="panel-heading">
            {{ $t('booking-search-date-header')}}
          </div>
          {{ monthObj }}
          <div class="panel-block">
            <div class="dropdown is-hoverable">
              <div class="dropdown-trigger">
                <button class="button is-fullwidth" aria-haspopup="true" aria-controls="booking-month">
                  <span>{{ $t(showMonth.month.toLowerCase())  + " " + showMonth.year }}</span>
                  <span class="icon is-small">
                    <i class="fas fa-angle-down" aria-hidden="true"></i>
                  </span>
                </button>
              </div>
              <div class="dropdown-menu is-fullwidth" id="booking-month" role="menu">
                <div class="dropdown-content">
                  <a v-for="monthObj in days" @click="showMonth = monthObj" :key="monthObj.month" class="dropdown-item">{{ $t(monthObj.month.toLowerCase())  + " " + monthObj.year}}</a>
                </div>

              </div>
            </div>

          </div>
          <div class="panel-block">
            <table class="table is-narrow is-fullwidth has-text-centered">
              <thead>
                <tr>
                  <th v-for="dow in 'MTWTFSS'.split('')" :key="'week-th-' + dow">{{ dow }}</th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="w in showMonth.weeks" :key="w.week + '-' + w.days.length">
                  <!-- <th>w. {{ w.week }}</th> -->
                  <td v-for="d in w.days" :class="{today: d.isSame(today, 'day'), selected: d == selectedDay}" :key="d">
                    <span v-if="d.isBefore(today)" class="has-text-grey">{{ d.date() }}</span>
                    <button v-else @click="selectDay(d)" class="button is-small is-fullwidth" :class="{'is-outlined': d.isSame(today, 'day') && d != selectedDay, 'is-primary': d == selectedDay || d.isSame(today, 'day')}">
                      {{ d.date() }}
                    </button>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>

      </div>
      <div class="column is-6">
        <ParticipantsInput :type="selectedType" @type="selectedType = $event" :participants="participants" @participants="participants = $event" />

				<div v-if="showCodeInput" class="panel">
          <div class="panel-heading">
            <button @click="showCodeInput = false; valuecode = undefined" class="button is-small is-secondary is-pulled-right">
              <i class="fa fa-trash-alt"></i>
              &nbsp;
              {{ $t('booking-remove-code') }}
            </button>
            {{ $t('booking-code-input-header') }}
          </div>
					<div class="panel-block">
						<div class="form-group">
							<label>{{ $t('booking-code-input-text')}}</label>
							<input v-model.lazy="valuecode" class="input mb-2" />

              <span v-if="codeStatus == 'checking'">
                <i class="fa fa-spinner fa-spin"></i>
                {{ $t('code-status-checking') }}
              </span>

              <div v-if="codeStatus == 'valid'" class="message is-success">
                <div class="message-body">
                  <i class="fa fa-check"></i>
                  {{ $t('giftcard-info-not-expired-title') }}
                </div>
              </div>

              <div v-if="codeStatus == 'invalid'" class="message is-danger">
                <div class="message-body">
                  <i class="fa fa-times"></i>
                  {{ $t('giftcard-info-expired-title') }}
                </div>
              </div>

              <div v-if="codeStatus == 'not-found'" class="message is-warning">
                <div class="message-body">
                  <i class="fa fa-times"></i>
                  {{ $t('booking-code-not-found-header') }}
                </div>
              </div>

            </div>
					</div>
				</div>
        <button v-else @click="showCodeInput = true" class="button is-secondary">
          {{ $t('booking-add-code-button')}}
        </button>

      </div>
    </div>

  <div v-if="teamsize > capacity.abs_max" class="container is-max-desktop">
    <div class="message is-warning">
      <div class="message-header">{{ $t('booking-max-capacity-exceeded-header') }}</div>
      <div class="message-body">
        <p>{{ $t('booking-max-capacity-exceeded-text', {max: capacity.abs_max}) }}</p>
      </div>
    </div>
  </div>

  <div v-if="childWarning" class="container is-max-desktop">
    <div class="message is-warning">
      <div class="message-header">{{ $t('booking-many-children-header') }}</div>
      <div class="message-body">
        <p>{{ $t('booking-many-children-text') }}</p>
      </div>
    </div>
  </div>


    <div class="has-text-centered mb-3 mt-3">
			<div v-if="teamsize >= capacity.abs_min">
				<button @click="submit" class="button is-primary is-large" data-testid="booking-search-button">
					<i class="fa fa-search"></i>&nbsp;
					{{ $t('booking-search-button') }}
				</button>
			</div>
			<div v-else class="notification">
				{{ $t('booking-no-participants-entered', {min: capacity.abs_min}) }}
			</div>
    </div>
  </div>
</template>

<script>
import axios from 'axios'
import dayjs from 'dayjs'
import { store } from '../store'
import weekOfYear from 'dayjs/plugin/weekOfYear';
import isoWeek from 'dayjs/plugin/isoWeek';
import ParticipantsInput from './ParticipantsInput.vue'
dayjs.extend(weekOfYear);
dayjs.extend(isoWeek);

export default {
	name: 'BookingSearchForm',
	emits: ['clear', 'submit'],
  data: function () {
    return {
			valuecode: undefined,
			showCodeInput: false,
      today: dayjs().startOf('day'),
      showAllMonths : false,
      showMonth: undefined,
      selectedDay: undefined,
      selectedType: 'private',
      codeStatus: null,
      addCode: false,
      participants: {
        employees: 0,
        adults: 0,
        students: 0,
        children: 0
      }
    }
  },
  components: {
    ParticipantsInput
  },
  mounted: function () {
    this.showMonth = this.allDays[0]
    this.selectedDay = dayjs()
  },
  watch: {
    selectedType () {
      this.participants['employees'] = 0
      this.participants['adults'] = 0
      this.participants['students'] = 0
      this.participants['children'] = 0
		},
		teamsize () {
      this.$emit('clear')
		},
    valuecode () {
      this.codeStatus = 'checking'

      axios.get('/booking/campaign/code-info/' + this.valuecode + '.json').then(async result => {
        this.codeStatus = result.data.status
        this.$emit('campaign', result.data.campaign)
      })
    }
  },
  props: {
  },
  methods: {
    selectDay: function (d) {
      if (d.isBefore(this.today)) {
        return false
      }
      this.$emit('clear')
      this.selectedDay = d
    },
    dayjs: function () { return dayjs() },
    submit: function () {
      this.plausible('booking-search', {props: this.participants})
      this.loading = true
      this.$emit('submit', {
        date: this.selectedDay.format('YYYY-MM-DD'),
        type: this.selectedType,
        participants: this.participants,
				code: this.valuecode
      })
    },
    setParticipants(type, count) {
      this[type] = count
    }
  },
  computed: {
		capacity () {
			return store.getVenueCapacity()
		},
		teamsize () {
			return Object.values(this.participants).reduce((a, b) => a+b, 0)
		},
    childWarning () {
      const grownups = this.participants.adults + this.participants.employees + this.participants.students
      const critters = this.participants.children

      if (critters == 0) {
        return false
      }

      if (grownups / critters <= 0.3) {
        return true
      }

      return false
    },
    existingDays () {
      return store.getBookingDays()
    },
    allowSearch: function () {
      return this.adults + this.children + this.employees + this.students >= 2
    },
    complete: function () {
      return true
    },
    days: function () {
      const lookAhead = (this.showAllMonths || this.showMonth) ? 6 : 2

      return this.allDays.slice(0, lookAhead)
    },
    allDays: function () {
      const days = []
      for (let i = 0; i < 10; i++) {
        const m = dayjs().add(i, 'month').startOf('month')
        let ws = []

        // startOf('isoWeek') did not work, so take an american(?) week starting on Sunday and add one day
        // let w = m.startOf('week').add(1, 'day')
        let w = m.startOf('isoWeek')

        while (w < m.add(1, 'month')) {
          let ds = []
          for (let j = 0; j <= 6; j++) {
            ds.push(w.add(j, 'day'))
          }

          ws.push({
            week: w.week(),
            days: ds
          })
          w = w.add(1, 'week')
        }

        let monthObj = {
          month: m.format('MMMM'),
          year: m.year(),
          weeks: ws
        }

        if (days.length > 0) {
          days[days.length-1].next = monthObj
          monthObj.prev = days[days.length-1]
        }
        days.push(monthObj)
      }

      console.log('These are the days')
      console.log(days)

      return days
    }
  }
}
</script>
<style>

</style>
