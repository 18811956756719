import { render } from "./ArenaPage.vue?vue&type=template&id=33f3fa6c"
import script from "./ArenaPage.vue?vue&type=script&lang=js"
export * from "./ArenaPage.vue?vue&type=script&lang=js"

import "./ArenaPage.vue?vue&type=style&index=0&lang=css"
script.render = render
/* hot reload */
if (module.hot) {
  script.__hmrId = "33f3fa6c"
  const api = __VUE_HMR_RUNTIME__
  module.hot.accept()
  if (!api.createRecord('33f3fa6c', script)) {
    api.reload('33f3fa6c', script)
  }
  
  module.hot.accept("./ArenaPage.vue?vue&type=template&id=33f3fa6c", () => {
    api.rerender('33f3fa6c', render)
  })

}

script.__file = "src/views/ArenaPage.vue"

export default script