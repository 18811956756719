import { render } from "./ArenaSelectBlock.vue?vue&type=template&id=5a3d6593&scoped=true"
import script from "./ArenaSelectBlock.vue?vue&type=script&lang=js"
export * from "./ArenaSelectBlock.vue?vue&type=script&lang=js"

import "./ArenaSelectBlock.vue?vue&type=style&index=0&id=5a3d6593&scoped=true&lang=css"
script.render = render
script.__scopeId = "data-v-5a3d6593"
/* hot reload */
if (module.hot) {
  script.__hmrId = "5a3d6593"
  const api = __VUE_HMR_RUNTIME__
  module.hot.accept()
  if (!api.createRecord('5a3d6593', script)) {
    api.reload('5a3d6593', script)
  }
  
  module.hot.accept("./ArenaSelectBlock.vue?vue&type=template&id=5a3d6593&scoped=true", () => {
    api.rerender('5a3d6593', render)
  })

}

script.__file = "src/components/blocks/ArenaSelectBlock.vue"

export default script