
<template>
  <div class="container">

<!--    <pre>{{ timeGroups }}</pre> -->

    <div v-for="g in timeGroups" :key="g.startTime" class="card mt-5">
      <button
        @click="toggleGroup(g)"
        class="card-header button is-fullwidth is-light booking-hour-button"
        :data-time="g.hour"
        :data-count="g.count"
        :class="{expanded: expandedGroups.includes(g.handle), contracted: !expandedGroups.includes(g.handle)}">
        
        <p class="card-header-title">
          <i class="fa fa-clock has-text-grey"></i>
          &nbsp;
          {{ $t('booking-offer-group-time', {start: g.hour }) }}
        </p>
        <p v-if="g.count > 1" class="card-header-title is-pulled-right">
          {{ $t('booking-offer-group-header', {count: g.count, bestPrice: g.bestPrice}) }}
        </p>
        <p v-else class="card-header-title is-pulled-right">
          {{ $t('booking-offer-group-header-single', {count: g.count, bestPrice: g.bestPrice}) }}
        </p>
        <span class="card-header-icon icon">
          <i class="fas fa-angle-down" aria-hidden="true"></i>
        </span>
      </button>

      <div v-if="g.offers.length > 0" class="card-content p-1 pb-5 pt-5">
        <table class="table is-fullwidth is-narrow">
          <tr v-for="o in g.offers" :key="o.id" class="offer" :class="{offset: o.warnings.includes('offset-slots')}">
<!--
            <td style="width: 115px;" class="has-text-centered py-2">
							<i class="fa fa-clock"></i>
							<i class="fas fa-exclamation-triangle has-text-danger" v-if="o.warnings.includes('offset-slots')"></i>
							<br />
							{{ o.start }}<span class="is-small has-text-grey"> - {{ o.end }}</span>
						</td>
-->
            <td class="py-4">
              <span v-for="s in o.slots" class="slot is-block mb-1" :key="'offer-entry-' + s.arena.arena_name">
                <span class="start-time">{{s.startTime }}</span>
                <span class="is-hidden-mobile end-time has-text-grey"> - {{s.endTime }}</span>
                <span class="arena">{{ atranslate(s.arena, ['marketing', 'brandNames'], s.arena.arena_name || s.arena.handle) }}</span>
              </span>
						</td>
						<td style="width: 75px;" class="has-text-centered py-4">
							<i class="fa fa-users"></i>
							<i class="fas fa-exclamation-triangle has-text-danger" v-if="o.warnings.includes('too-many-players')"></i>
							<i class="fas fa-exclamation-triangle has-text-danger" v-if="o.warnings.includes('too-few-players')"></i>
							<br />
							{{ o.teamsize_min_recommended }}-{{ o.teamsize_max_recommended }}
            </td>

            <td style="width: 75px;" class="has-text-centered py-4">
							<i class="fa fa-credit-card"></i>
							<br />
							{{ o.price }} {{ $t('currency') }}
            </td>

            <td class="py-4">
							<div class="buttons">
								<button @click="info = o" style="width: 75px;" class="button is-info">
									<strong>{{ $t('booking-more-info') }}</strong>
								</button>
                <button @click="booking = o" style="width: 75px;" class="button booking-slot-button" :class="{'is-success': o.warnings.length == 0}">
									<strong>{{ $t('booking-reserve') }} &raquo;</strong>
								</button>
                <button v-if="o.reservable" @click="booking = o; mode = 'offer';" class="button is-primary">
									{{ $t('booking-request-offer') }}
								</button>
							</div>
            </td>
          </tr>
        </table>
      </div>
    </div>

    <BookingForm @abort="booking = undefined; mode = undefined" v-if="booking" :mode="mode" :offer="booking"></BookingForm>

    <OfferInfo @book="booking = info; info = undefined;" @close="info = undefined" v-if="info" :offer="info"></OfferInfo>
  </div>
</template>

<script>
import BookingForm from './BookingForm.vue'
import OfferInfo from './OfferInfo.vue'
import { store } from '../store'

export default {
  name: 'BookingOfferList',
  props: ['offers'],
  data: function () {
    return {
			mode: undefined,
      filteredArenas: [],
      expandedGroups: [],
      booking: undefined,
      info: undefined,
      expanded: undefined
    }
  },
  methods: {
    filterArena: function (a) {
      const i = this.filteredArenas.indexOf(a)

      if (i == -1) {
        this.filteredArenas.push(a)
      } else if (this.filteredArenas.length == this.sortedArenas.length) {
        this.filteredArenas = [a]
      } else {
        this.filteredArenas.splice(i, 1)
      }

    },
    toggleGroup(g) {
      const handle = g.handle
      if (this.expandedGroups.includes(handle)) {
        this.expandedGroups.splice(this.expandedGroups.indexOf(handle), 1)
      } else {
        this.expandedGroups.push(handle)
      }
    }
  },
  mounted: function () {
    setTimeout(() => {
      this.filteredArenas = this.sortedArenas

			console.log(this.offers)
			for(let g of this.timeGroups) {
				console.log('Checking timegroup')
				console.log(g)
				if (g.count < 4) {
					this.expandedGroups.push(g.handle)
				}
			}
    }, 500)

  },
  components: {
    BookingForm,
    OfferInfo
  },
  watch: {
    booking () {
        this.plausible('booking-details')
    }
  },
  computed: {
    cheapest: function () {
      return (this.offers && this.offers.length > 0) ? this.offers[0] : undefined
    },
    matchingOffers: function () {
      return this.offers.filter(offer => offer.slots.some(slot => {
        let haystack = this.filteredArenas.map(a => a.handle)
        let needle = slot.arena_handle

        let found = haystack.includes(needle)
        return found
      }))
    },
    arenas: function () {
      return store.arenas
    },
    sortedArenas: function () {
      if (!this.arenas) {
        return []
      }
      const sorted = Object.values(this.arenas).sort((a, b) => a.arena_name.localeCompare(b.arena_name))
      return sorted
    },
    timeGroups: function () {
      let groups = []
      for (let o of this.offers) {
        let h = Math.round((this.hhmm2min(o.start)) / 60) // In hours from midnight

        if (!groups.includes(h)) {
          console.log('Creating group for hour ' + h)
          groups.push(h)
        }
      }

      return groups.sort((a, b) => a - b).map(h => {
        let g = {
          startTime: this.min2hhmm(h * 60 - 30),
          endTime: this.min2hhmm(h * 60 + 30),
          handle: 'group_' + h,
        }

        console.log('Preparing group')
        console.log(g)
        console.log('These are the matching offers')
        console.log(this.matchingOffers)

        const startTime = this.hhmm2min(g.startTime)
        const endTime = this.hhmm2min(g.endTime)

        g.offers = this.matchingOffers.filter(o => {
          const offerStart = this.hhmm2min(o.start)
          return offerStart >= startTime && offerStart <= endTime
        }).sort((a, b) => {
          return a.start.localeCompare(b.start)
        })

        g.count = g.offers.length
        if (g.offers.length > 0) {
          g.bestPrice = Math.min(...g.offers.map(o => o.price))
        }

        if (!this.expandedGroups.includes(g.handle)) {
          g.offers = g.offers.slice(0, 0)
        }
        g.hour = this.min2hhmm(h * 60)

        g.expanded = false

        return g
      }).filter(g => {
        console.log(g)
        console.log(g.offers.length)
        return g.count > 0
      })
    }
  }
}
</script>
<style>
.offerListWrapper {
  background: #232323;
  padding-top: 10vh;
}

.offerList {
  margin: 0 auto auto;
}

.filters button {
  padding: 10px;
  background: #323232;
  border: 1px solid #121212;
  border-radius: 2px;
  color: white;
}

.filters .active {
  background: #39aa35;
}

.offerList .filters {
  display: grid;
  grid-auto-flow: column;
  grid-column-gap: 10px;
  padding-left: 10vw;
  padding-right: 10vw;
}

.offerList .offers {
  display: grid;
  grid-gap: 20px;
  grid-template-columns: 1fr 1fr 1fr;
}

@media screen and (max-width: 800px) {
  .offerList .offers {
    grid-template-columns: 1fr;
  }
}

.offer .slot .start-time {
	font-weight: bold;
}

.offer .slot .arena {
	padding-left: 5px;
}

.offer.offset .slot:not(:first-child) .time {
	color: hsl(348, 100%, 61%);
}


</style>
