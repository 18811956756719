<template>
    <router-link :to="'/' + language + '/arena/' + arena.handle" class="arena-stack-entry-link small">
        <div class="card">
            <div class="card-image">
                <figure class="image is-16by9">
                    <img :src="'/apuz/' + arena.handle + '/illustration/titlepage.webp?w=480'"/>
                </figure>

            </div>
            <header class="card-header"><h4 class="card-header-title">{{ $at(arena, 'marketing.brandNames',arena.arena_name)}}</h4></header>
            <div class="card-content">
                <span v-if="teamsize" class="teamsize">
                    {{ teamsize }} {{ $t('participants') }}<br />
                </span>
            </div>
        </div>
    </router-link>
</template>

<script>
import { store } from '../store.js'
export default {
  name: 'ArenaStackSmallEntry',
  props: ['arena'],
  components: {
  },
  computed: {
      language: function () {
          return store.language
      },
      teamsize () {
          let min = this.arena['teamsize-min-recommended']
          let max = this.arena['teamsize-max-recommended']
          if (min && max) {
              return min + '-' + max
          }
          return ''
      }
  }
}
</script>
<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
    img {
        object-fit: cover;
    }
</style>
