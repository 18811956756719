import { render } from "./EmailUpdate.vue?vue&type=template&id=4e6b3f05"
import script from "./EmailUpdate.vue?vue&type=script&lang=js"
export * from "./EmailUpdate.vue?vue&type=script&lang=js"
script.render = render
/* hot reload */
if (module.hot) {
  script.__hmrId = "4e6b3f05"
  const api = __VUE_HMR_RUNTIME__
  module.hot.accept()
  if (!api.createRecord('4e6b3f05', script)) {
    api.reload('4e6b3f05', script)
  }
  
  module.hot.accept("./EmailUpdate.vue?vue&type=template&id=4e6b3f05", () => {
    api.rerender('4e6b3f05', render)
  })

}

script.__file = "src/components/EmailUpdate.vue"

export default script