import { render } from "./BookingOfferList.vue?vue&type=template&id=68fadc71"
import script from "./BookingOfferList.vue?vue&type=script&lang=js"
export * from "./BookingOfferList.vue?vue&type=script&lang=js"

import "./BookingOfferList.vue?vue&type=style&index=0&lang=css"
script.render = render
/* hot reload */
if (module.hot) {
  script.__hmrId = "68fadc71"
  const api = __VUE_HMR_RUNTIME__
  module.hot.accept()
  if (!api.createRecord('68fadc71', script)) {
    api.reload('68fadc71', script)
  }
  
  module.hot.accept("./BookingOfferList.vue?vue&type=template&id=68fadc71", () => {
    api.rerender('68fadc71', render)
  })

}

script.__file = "src/components/BookingOfferList.vue"

export default script