import { render } from "./ArenaStack.vue?vue&type=template&id=1ba6a51e&scoped=true"
import script from "./ArenaStack.vue?vue&type=script&lang=js"
export * from "./ArenaStack.vue?vue&type=script&lang=js"

import "./ArenaStack.vue?vue&type=style&index=0&id=1ba6a51e&scoped=true&lang=css"
script.render = render
script.__scopeId = "data-v-1ba6a51e"
/* hot reload */
if (module.hot) {
  script.__hmrId = "1ba6a51e"
  const api = __VUE_HMR_RUNTIME__
  module.hot.accept()
  if (!api.createRecord('1ba6a51e', script)) {
    api.reload('1ba6a51e', script)
  }
  
  module.hot.accept("./ArenaStack.vue?vue&type=template&id=1ba6a51e&scoped=true", () => {
    api.rerender('1ba6a51e', render)
  })

}

script.__file = "src/components/ArenaStack.vue"

export default script