import { render } from "./VueWeb.vue?vue&type=template&id=1dca59c4&scoped=true"
import script from "./VueWeb.vue?vue&type=script&lang=js"
export * from "./VueWeb.vue?vue&type=script&lang=js"

import "./VueWeb.vue?vue&type=style&index=0&id=1dca59c4&scoped=true&lang=css"
import "./VueWeb.vue?vue&type=style&index=1&lang=css"
script.render = render
script.__scopeId = "data-v-1dca59c4"
/* hot reload */
if (module.hot) {
  script.__hmrId = "1dca59c4"
  const api = __VUE_HMR_RUNTIME__
  module.hot.accept()
  if (!api.createRecord('1dca59c4', script)) {
    api.reload('1dca59c4', script)
  }
  
  module.hot.accept("./VueWeb.vue?vue&type=template&id=1dca59c4&scoped=true", () => {
    api.rerender('1dca59c4', render)
  })

}

script.__file = "src/components/VueWeb.vue"

export default script