<template>
	<div>
		<SplashHeader class="pageheader" :image="splashImage" :title="$t('today-page-header')"></SplashHeader>

		<section class="section hero has-background-primary is-spaced">
			<div class="container has-no-background">
				<h4 v-if="schedule" class="title is-size-3 is-size-4-tablet has-text-white is-pulled-right">{{ $t('today-opening-hours', {opening: schedule.opening, closing: schedule.closing}) }}</h4>
				<h2 class="title is-size-3 is-size-2-tablet has-text-white">
					<span v-if="slots.length >= 5">{{ $t('today-many-available-headline', {slots: slots.length}) }}</span>
					<span v-if="slots.length > 1 && slots.length < 5">{{ $t('today-few-available-headline', {slots: slots.length}) }}</span>
					<span v-if="slots.length == 1">{{ $t('today-one-available-headline', {slots: slots.length}) }}</span>
					<span v-if="slots.length == 0">{{ $t('today-none-available-headline', {slots: slots.length}) }}</span>
				</h2>
				<p class="has-text-white">
					{{ $t('today-page-intro') }}
				</p>
				<router-link class="button is-primary has-background-white is-outlined is-link is-medium" :to="bookingLink()">{{ $t('book-now') }}</router-link>
			</div>
		</section>


		<div class="container mt-6">
			<div class="columns">
				<div v-for="a in arenas" :key="a.handle" class="column">
					<h4 class="subtitle">{{ atranslate(a, 'marketing.brandNames', a.handle) }}</h4>

					<figure class="image is-4-by-3" style="height: 200px;">
						<img :src="'/apuz/' + a.handle + '/illustration/titlepage.webp?w=320'" style="object-fit: cover; height: 100%;" />
					</figure>

					<p style="height: 200px;">
						{{ atranslate(a, 'marketing.shortStories') }}
						<router-link :to="a.href">{{ $t('read-more') }}</router-link>
					</p>

					<div class="slots has-text-centered">
						<template v-for="s in arenaMergedSlots(a)">
							<router-link v-if="s.available" :key="a.handle + s.startTime + 'available'" :to="bookingLink()">
								<button class="button is-light m-2 is-fullwidth is-success">
									{{ s.startTime }}
								</button>
							</router-link>
							<button v-else  :key="a.handle + s.startTime + 'unavailable'" disabled class="button is-light m-2 is-fullwidth">
								{{ s.startTime }}
							</button>
						</template>
					</div>
				</div>
			</div>


			<h3 class="subtitle is-size-3">{{ weatherHeading }}</h3>
			<div v-if="weather" class="columns">
				<div v-for="w in forecast" :key="w.time.format('HH:mm')" class="column">
					{{ w.time.format('HH:mm') }}<br />
					{{ w.main }}<br />
					{{ w.temp }}&deg; C<br />
					<img :src="w.icon" />
				</div>
			</div>
		</div>


	</div>
</template>
<script>
	import { store } from '../store'
	import axios from 'axios'
	import SplashHeader from '../components/SplashHeader.vue'
	import dayjs from 'dayjs'
	export default {
		name: 'TodayPage',
		data: function () {
			return {
				weather: undefined
			}
		},
		components: {
			SplashHeader
		},
		methods: {
			arenaSlots (arena) {
				return this.slots.filter(a => a.arena == arena.handle)
			},
			arenaBookedSlots (arena) {
				return this.bookedSlots.filter(a => a.arena == arena.handle)
			},
			arenaMergedSlots (arena) {
				return this.arenaSlots(arena).concat(this.arenaBookedSlots(arena)).sort((a, b) => {
					return this.hhmm2min(a.startTime) - this.hhmm2min(b.startTime)
				})
			}
		},
		computed: {
			weatherHeading () {
				return this.$t('today-weather-heading-general')
			},
			forecast () {
				return this.weather.hourly.map(hour => {
					return {
						time: dayjs.unix(hour.dt),
						main: hour.weather[0].main,
						temp: Math.round(hour.temp - 273),
						icon: 'http://openweathermap.org/img/wn/' + hour.weather[0].icon + '@2x.png'
					}
				}).filter(h => h.time < dayjs().hour(22))
			},
			splashImage () {
				return store.customization.startsplash
			},
			arenas () {
				return store.arenas
			},
			schedule () {
				return store.today ? store.today.schedule : undefined
			},
			slots () {
				return store.today ? store.today.slots : []
			},
			bookedSlots () {
				return store.today ? store.today.bookedSlots : []
			},
		},
		mounted: function () {
			axios.get('/apuz/weather.json').then(result => {
				this.weather = result.data.weather
			})
		}
	}
</script>
<style scoped>
</style>
