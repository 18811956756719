
<template>
	<div class="content-page">
		<div class="cover">
			<img :src="splashimg">
			<h1 class="title has-text-white has-text-centered">{{ $t('gift-card-page-title') }}</h1>
		</div>

		<section class="container is-max-desktop">
			<p class="is-size-5 my-5">{{ $t('giftcard-page-header') }}</p>
		</section>

		<section class="container">


			<div v-if="purchasedCode">
				<h1>This is the code you purchased</h1>
				<p>{{ purchasedCode }}</p>
			</div>


			<div class="columns is-multiline">
				<div v-for="c in campaigns" :key="c.name" class="column is-half">
					<div class="card">
						<div class="card-content">
							<div class="content">
								<h3 class="is-3">{{ atranslate(c, 'sellingTitles') }}</h3>
								<p>
									{{ atranslate(c, 'salesPitches') }}
								</p>
							</div>
							<button @click="selectedCampaign = c" class="button is-primary">
								{{ $t('giftcard-purchase-button', {price: c.sellingPrice, currency: $t('currency')}) }}
							</button>
						</div>
					</div>
				</div>
			</div>

      <p v-if="venue" class="is-size-7 has-text-grey">{{ $t('giftcard-right-of-withdrawal-text', {email: venue.publicdetails.email}) }}</p>

			<div v-if="selectedCampaign" class="modal is-active">
				<div @click="selectedCampaign = undefined" class="modal-background"></div>
				<div class="modal-card">
					<header class="modal-card-head">
						<p class="modal-card-title">{{ atranslate(selectedCampaign, 'sellingTitles') }}</p>
						<button @click="selectedCampaign = undefined" class="delete" aria-label="close"></button>
					</header>
					<section class="modal-card-body">
						<p class="pb-3">
							{{ atranslate(selectedCampaign, 'purchasingTerms') }}
						</p>

						<div class="field">
							<label class="label">{{ $t('name-input-label') }}</label>
							<input type="text" v-model="buyerName" class="input" />
						</div>
						<div class="field">
							<label class="label">{{ $t('email-input-label') }}</label>
							<input type="text" v-model="buyerEmail" class="input" />
						</div>

						<div class="field">
							<label class="label">{{ $t('credit-card-input-label') }}</label>
							<div id="card-element"></div>
							<p class="has-text-dark pt-4">{{ $t('credit-card-processor-info') }}</p>
						</div>


            <div v-if="receiptId">
              Receipt id is: {{ receiptId }}
            </div>

					</section>
					<footer class="modal-card-foot">
						<button v-if="!paymentInProgress" @click="performPayment" class="button is-success">
							<span>
								<i class="fa fa-check"></i>
								{{ $t('giftcard-pay-button', {price: selectedCampaign.sellingPrice, currency: $t('currency') }) }}
							</span>
						</button>
						<button v-if="!paymentInProgress" class="button" @click="selectedCampaign = undefined">{{ $t('abort-button') }}</button>

            <button v-if="paymentInProgress" class="button is-secondary">
              <i class="fas fa-circle-notch fa-spin"></i>
            </button>

          </footer>
				</div>
			</div>

		</section>

	</div>
</template>
<script>
		import { store } from '../store'
		import { loadStripe } from '@stripe/stripe-js'
    import axios from 'axios'
    export default {
        name: 'PurchaseGiftCardPage',
        data: function () {
            return {
              receiptId: undefined,
							card: undefined,
							selectedCampaign: undefined,
							stripe: undefined,
							buyerName: undefined,
							buyerEmail: undefined,
							paymentInProgress: false,
							purchasedCode: undefined
            }
        },
				computed: {
          venue () { return store.venue },
          splashimg () {
						return '/apuz/venue/static/images/' + store.customization.giftcardbg
					},
					campaigns () {
						return store.venue ? store.venue['sellable-campaigns'] : []
					}
				},
				methods: {
					performPayment (event) {
						this.paymentInProgress = true
						event.preventDefault()

						let payload = {
							campaign: this.selectedCampaign.handle,
							amount: parseInt(this.selectedCampaign.sellingPrice),
							language: this.$route.params.language,
							email: this.buyerEmail,
							name: this.buyerName
						}

						axios.post('/booking/giftcard-payment.json', payload).then(result => {

              this.receiptId = result.data.receipt
              this.campaignId = result.data.campaign

							this.stripeClientSecret = result.data.client_secret

							this.stripe.confirmCardPayment(this.stripeClientSecret, {
								payment_method: {
									card: this.card,
									billing_details: {
										name: this.buyerName
									}
								}
							}).then((result) => {
									if (result.error) {
									// Show error to your customer (e.g., insufficient funds)
										console.log(result.error.message);
									} else {

                    console.log('Payment confirmed by Stripe')

										window.location = '/' + this.$route.params.language + '/gift-certificate-receipt/' + this.campaignId + '/' + this.receiptId

									// The payment has been processed!
									if (result.paymentIntent.status === 'succeeded') {
											// Show a success message to your customer
											// There's a risk of the customer closing the window before callback
											// execution. Set up a webhook or plugin to listen for the
											// payment_intent.succeeded event that handles any business critical
											// post-payment actions.
									}
									}
							})
						})
					}
				},
				watch: {
					selectedCampaign () {
						setTimeout(() => {
							this.card = this.elements.create('card')
							this.card.mount('#card-element')
							this.card.on('change', event => this.cardErrorText =event.error ? event.error.message : undefined)
						}, 250)

						if (!this.stripe) {
							loadStripe(store.venue.stripe_public_key).then((stripe) => {
								this.stripe = stripe
								console.log('This is the stripe!')
								console.log(this.stripe)
								this.elements = this.stripe.elements()
							})
						}
					}
				},
        mounted: function () {
        }
    }
</script>
<style>
</style>
