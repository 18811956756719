<template>
  <section v-for="(arena, index) in arenaDict" :class="{ 'is-active': selectedArena == index, 'is-last-active': lastActiveArena == index}" class="arena-section" :style="style(arena)" :key="'arena-slide-' + arena.handle">
  </section>
  <div class="container is-flex is-justify-content-space-between">
    <span v-if="multipleArenas" class="icon is-large is-clickable p-2 is-align-self-center" @click="changeArena(-1)">
      <i class="fas fa-arrow-alt-circle-left fa-2x has-text-white"></i>
    </span>
    <div v-if="arenaDict[selectedArena] && arenaDict[selectedArena]['arena_name']"
      class="arena-info is-flex is-flex-direction-column is-justify-content-space-around slider pt-5 has-text-white" ref="slider">
      <h1 class="is-title is-size-1 has-text-centered has-text-white">{{ selectedArenaName }}</h1>
      <div class="pb-2">
        <div class="columns">
        <div class="column has-text-white"><i class="fa fa-user-friends"></i> {{ teamsize }} {{
          $t('participants') }}</div>
        <div class="column has-text-white"><i class="far fa-clock"></i> 60 {{ $t('minutes') }}</div>
        <div class="column has-text-white"><i class="fa fa-tachometer-alt"></i> {{
          arenaDict[selectedArena].marketing.successRate }}%
          {{ $t('success-rate') }}</div>
        <div class="column has-text-white">
          <i class="fa fa-globe-europe"></i>
          {{ arenaDict[selectedArena].languages.map(l => $t('language-' + l)).join(", ") }}
        </div>
      </div>
      <p class="subtitle has-text-white">{{ selectedArenaShortStories }}</p>
      </div>
    </div>
    <span v-if="multipleArenas" class="icon is-large is-clickable p-2 is-align-self-center" @click="changeArena(1)">
      <i class="fas fa-arrow-alt-circle-right fa-2x has-text-white"></i>
    </span>
  </div>
</template>

<script>
import { store } from '../../store'

export default {
  name: 'ArenaSlidesBlock',
  data() {
    return {
      selectedArena: 0,
      currentTextIndex: 0,
      lastActiveArena: -1,
      isSliding: false
    }
  },
  methods: {
    computeVisibility(){
      const arenaCount = Object.keys(store.arenas).length;
      return arenaCount > 0
    },
    changeIndex(value) {
      this.selectedArena += value
    },
    style(arena) {
      let img = '/apuz/' + arena.handle + '/illustration/titlepage.webp?w=1920'
      return 'background-image: url("' + img + '");'
    },
    changeArena(index) {
      if (this.isSliding) return
      this.isSliding = true
      this.lastActiveArena = this.selectedArena

      const arenaCount = Object.keys(this.arenaDict).length
      const newIndex = (this.selectedArena + index + arenaCount) % arenaCount

      this.selectedArena = newIndex

      this.animateSlider(index, newIndex)

    },
    animateSlider(direction, index) {
      const slider = this.$refs.slider;
      const animationClass = direction < 0 ? 'slide-reset-left' : 'slide-reset-right'

      slider.classList.add(animationClass)
      setTimeout(() => {
        this.currentTextIndex = index

        setTimeout(() => {
          slider.classList.remove(animationClass)
          this.isSliding = false
        }, 500)
      }, 500)
    }
  },
  props: {
    audience: Object,
    blockObj: Object,
  },
  computed: {
    arenas() {
      const filteredArenas = Object.values(store.arenas).filter(arena => {
        return !isNaN(arena['teamsize-max-recommended']) && !isNaN(arena['teamsize-min-recommended']) && !arena['disabled']
      })

      return filteredArenas
    },
    arenaCount() {
      return Object.keys(this.arenas).length
    },
    multipleArenas() {
      return this.arenaCount > 1
    },
    arenaDict() {
      let dict = {};
      Object.values(this.arenas).forEach((arena, index) => {
        dict[index] = arena;
      });
      return dict;
    },
    selectedArenaName() {
      if (!this.arenaDict && !this.arenaDict[this.currentTextIndex]) return ''
      return this.$at(this.arenaDict[this.currentTextIndex], 'marketing.brandNames', this.arenaDict[this.currentTextIndex]['arena_name'])
    },
    selectedArenaShortStories() {
      if (!this.arenaDict) return ''
      return this.$at(this.arenaDict[this.currentTextIndex], 'marketing.shortStories')
    },
    teamsize() {
      if (!this.arenaDict[this.selectedArena]) return ''
      return this.arenaDict[this.selectedArena]['teamsize-min-recommended'] + ' - ' + this.arenaDict[this.selectedArena]['teamsize-max-recommended']
    }
  }
}
</script>
<style scoped>
.arena-section {
  position: absolute;
  height: 75vh;
  width: 100%;
  background-size: cover;
  background-position: center;
  transition: all 0.5s;
  opacity: 0.5;
  z-index: -1;
  box-shadow: inset 0px -3vh 20rem 20vh rgba(0, 0, 0, 0.7);
}

.is-active {
  opacity: 1;
  z-index: 1;
}

.is-last-active {
  opacity: 0.7;
  z-index: 0;
}

.container {
  z-index: 2;
  height: 75vh;
  width: 100%;
}

.arena-info {
  flex-grow: 1;
  text-align: center;
}

.slider {
  z-index: 2;
  transition: none;
}

.slide-reset-left,
.slide-reset-right {
  animation-duration: 1s;
  animation-fill-mode: forwards;
}

.slide-reset-left {
  animation-name: slideResetLeft;
}

.slide-reset-right {
  animation-name: slideResetRight;
}

@keyframes slideResetLeft {
  0% {
    opacity: 1;
  }

  50% {
    opacity: 0;
  }

  50.01% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

@keyframes slideResetRight {
  0% {
    opacity: 1;
  }

  50% {
    opacity: 0;
  }

  50.01% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}
</style>
