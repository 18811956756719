<template>
    <div v-if="ready">
      <div style="display: none;">
        <email-images>
          {{ images }}
        </email-images>
      </div>
      <EmailOfferPDF v-if="type == 'offer-pdf'" :booking="booking" :mode="mode"></EmailOfferPDF>
        <div v-else ref="email-container" :style="mainstyle">
          <email-content>
            <!DOCTYPE html PUBLIC "-//W3C//DTD XHTML 1.0 Transitional//EN" "http://www.w3.org/TR/xhtml1/DTD/xhtml1-transitional.dtd">
            <html xmlns="http://www.w3.org/1999/xhtml" lang="en-GB">
            <head>
            <meta http-equiv="Content-Type" content="text/html; charset=UTF-8" />
            <title>Demystifying Email Design</title>
            <meta name="viewport" content="width=device-width, initial-scale=1.0"/>

            </head>
            <body style="margin: 0; padding: 0;">
            <table role="presentation" border="0" cellpadding="0" cellspacing="0" width="100%">
                <tr>
                <td style="padding: 20px 0 30px 0;">

            <table align="center" border="0" cellpadding="0" cellspacing="0" width="600" style="border-collapse: collapse; border: 1px solid #cccccc;">
            <tr>
                <td align="center" bgcolor="#70bbd9" style="padding: 0;">
                <img v-if="images['header.jpg']" :src="images['header.jpg'][mode]" width="600" height="300" style="display: block;" />
                </td>
            </tr>
            <tr v-if="message && message.length > 0">
              <td bgcolor="#ffffff" style="padding: 40px 30px 40px 30px;">
                <p><em>{{ message }}</em></p>
              </td>
            </tr>
            <tr>
                <td bgcolor="#ffffff" style="padding: 40px 30px 40px 30px;">
                    <EmailConfirmation v-if="type == 'confirmation'" :booking="booking" :mode="mode"></EmailConfirmation>
                    <EmailUpdate v-if="type == 'update'" :booking="booking" :mode="mode"></EmailUpdate>
                    <EmailCancellation v-if="type == 'cancellation'" :booking="booking" :mode="mode"></EmailCancellation>
                    <EmailFollowup v-if="type == 'followup'" :booking="booking" :mode="mode"></EmailFollowup>
                    <EmailOffer v-if="type == 'offer'" :booking="booking" :mode="mode"></EmailOffer>
                    <GiftCertificateReceipt v-if="type == 'giftcertificate-receipt'" :booking="booking" :mode="mode"></GiftCertificateReceipt>
                </td>
            </tr>
            <tr>
                <td :bgcolor="primarycolor" style="padding: 30px 30px;">
                    <table border="0" cellpadding="0" cellspacing="0" width="100%" style="border-collapse: collapse;">
                    <tr>
                    <td style="color: #ffffff; font-family: Arial, sans-serif; font-size: 14px;">
                        <p v-if="venue" style="margin: 0">
                            {{ venue.name }}
                            <br />
                            {{ venue.publicdetails.streetaddress }},
                            {{ venue.publicdetails.zipcode }}
                            {{ venue.publicdetails.city }}
                            <br/>
                        </p>
                    </td>
                    <td align="right">
                        <table border="0" cellpadding="0" cellspacing="0" style="border-collapse: collapse;">
                        <tr>
                            <td>
                            </td>
                            <td style="font-size: 0; line-height: 0;" width="20">&nbsp;</td>
                            <td>
                                <img :src="images['logo.png'][mode]" width="128" height="46" />
                            </td>
                        </tr>
                        </table>
                    </td>
                    </tr>
                </table>
                </td>
            </tr>
            </table>
                </td>
                </tr>
            </table>
            </body>
            </html>
          </email-content>
        </div>
    </div>
    <div v-else>
      <p>
        A technical error in the email sender has occured. This likely means that
        the email was sent before it was fully rendered, but your booking is probably
        okay.
      </p>
    </div>
</template>
<script>
    import { store } from '../store'
    import EmailConfirmation from '../components/EmailConfirmation'
    import EmailCancellation from '../components/EmailCancellation'
    import EmailUpdate from '../components/EmailUpdate'
    import EmailOffer from '../components/EmailOffer'
    import EmailOfferPDF from '../components/EmailOfferPDF'
    import EmailFollowup from '../components/EmailFollowup'
    import GiftCertificateReceipt from '../views/GiftCertificateReceipt'
export default {
        props: ['booking', 'type', 'recipients'],
        data () {
            return {
                mode: (new URLSearchParams(window.location.search)).get('mode') == 'preview' ? 'preview' : 'cid',
                status: 'sending',
                message: (new URLSearchParams(window.location.search)).get('message'),
            }
        },
        components: {
            EmailConfirmation,
            EmailCancellation,
            EmailUpdate,
            EmailFollowup,
            EmailOffer,
            EmailOfferPDF,
            GiftCertificateReceipt
        },
        computed: {
            mainstyle () {
              return ''
            },
            firstname () {
                let parts = this.booking.booker.name.split(' ')
                return parts[0].length > 2 ? parts[0] : this.booking.booker.name
            },
            ready () {
                console.log('Emai sender checking if ready, here is the stor')
                console.log(store)
                return store && store.customization
            },
            venue: function () {
                return store.venue
            },
            headerimage () {
                return '/apuz/venue/static/images/' + store.customization.startsplash
            },
            primarycolor () {
                if (store.venue) {
                    return store.venue['graphical-profile'].primarycolor
                } else {
                    return ''
                }
            },
            bookingPageUrl () {
                return window.location.origin + '/' + store.language + '/booking-details/' + this.booking.date + '/' + this.booking.id
            },

            // THIS SHOULD BE SWAPPED OR CHECKED SINCE THE SAME LOGIC IS IN THE SUBCOMPOPNENT
            images () {
                let imgs = {'logo.png': {
                        preview: '/apuz/logo/256x96-dark.png',
                        path: '/mnt/gamerunner/venue/branding/logo/256x96-dark.png',
                        cid: 'cid:logo.png'
                    }
                }

                if (store.customization) {
                    imgs['header.jpg'] = {
                        preview: '/apuz/venue/static/images/' + store.customization.emailconfirmationheader,
                        path: '/mnt/gamerunner/venue/pubweb/images/' + store.customization.emailconfirmationheader,
                        cid: 'cid:header.jpg'
                    }
                }

                if (this.booking.slots && store.arenas) {
                    for (let s of this.booking.slots) {
                        if (s.arena) {
                            imgs[s.arena.handle + '.jpg'] = {
                                preview: '/apuz/' + s.arena.handle + '/illustration/titlepage.webp',
                                path: '/mnt/gamerunner/arenas/' + s.arena.handle + '/images/en/titlepage.jpg',
                                cid: 'cid:' + s.arena.handle + '.jpg'
                            }
                        }
                    }
                }

                console.log('Here are the images')
                console.log(imgs)

                return imgs
            },
            teamsizeInfo () {
                return {
                    min_players: this.booking.teamsize_min_recommended,
                    absolute_min_players: this.booking.teamsize_min,
                    max_players: this.booking.teamsize_max_recommended,
                    absolute_max_players: this.booking.teamsize_max
                }
            },
            practicalInfo () {
                return {
                    arrival_minutes: 10
                }
            }
        },
        mounted () {
          console.log('EmailSender mounted, this is the booking')
          console.log(this.booking)
          console.log('This is the type')
          console.log(this.type)
        }
    }
</script>
