<template>
    <div>
        <h2 class="title">{{ $t('booking-changes-title')}}</h2>
        <p>
            <span v-html="$t('booking-changes-pretext')"></span>
        </p>

        <div class="columns">
            <div class="column">
                <h2 class="subtitle">{{ $t('booking-edit-participants-header') }}</h2>
                <ParticipantsInput :participants="localBooking.participants" :type="booking.type" @participants="localBooking.participants = $event" @type="localBooking.type = $event"/>

                <p v-if="newBooking && errors.length == 0">
                    {{ $t('booking-edit-participants-new-price', newBooking)}}
                </p>

                <article class="message is-warning" v-if="errors.length == 0 && warnings.includes('too-many-players')">
                    <p class="message-body">{{ $t('too-many-players-text') }}</p>
                </article>
                <article class="message is-warning" v-if="errors.length == 0 && warnings.includes('too-few-players')">
                    <p class="message-body">{{ $t('too-few-players-text') }}</p>
                </article>
                <article class="message is-danger" v-if="errors.includes('too-many-players')">
                    <p class="message-body">{{ $t('too-many-players-error', {max: localBooking.teamsize_max}) }}</p>
                </article>
                <article class="message is-danger" v-if="errors.includes('too-few-players')">
                    <p class="message-body">{{ $t('too-few-players-error', {min: localBooking.teamsize_min}) }}</p>
                </article>

                <button v-if="newBooking && errors.length == 0" class="button is-primary" @click="updateBooking">
                    <i class="fa fa-thumbs-up"></i>&nbsp;
                    {{ $t('booking-edit-participants-confirm')}}
                </button>
            </div>
            <div class="column">
                <h2 class="subtitle">{{ $t('booking-edit-time-header')}}</h2>
                <p>{{ $t('booking-edit-time-text')}}</p>

                <h2 class="subtitle">{{ $t('booking-edit-arenas-header')}}</h2>
                <p>{{ $t('booking-edit-arenas-text')}}</p>
            </div>
        </div>

    </div>
</template>
<script>
import axios from 'axios'
import ParticipantsInput from './ParticipantsInput.vue'
export default {
    props: ['booking'],
    data () {
        return {
            newBooking: undefined,
            localBooking: undefined
        }
    },
    components: {
        ParticipantsInput
    },
    computed: {
        newTeamSize () {
            return Object.values(this.localBooking.participants).reduce((a, b) => a + b, 0)
        },
        warnings () {
            let w = []
            if (this.newTeamSize > this.localBooking.teamsize_max_recommended) {
                w.push('too-many-players')
            }
            if (this.newTeamSize < this.localBooking.teamsize_min_recommended) {
                w.push('too-few-players')
            }

            return w
        },
        errors () {
            let e = []
            if (this.newTeamSize > this.localBooking.teamsize_max) {
                e.push('too-many-players')
            }
            if (this.newTeamSize < this.localBooking.teamsize_min) {
                e.push('too-few-players')
            }

            return e
        }
    },
    beforeMount () {
        this.localBooking = this.booking
    },
    watch: {
        localBooking: {
            deep: true,
            handler () {
                console.log('localBooking has changed!'),
                this.newBooking = null,
                axios.post('/booking/' + this.localBooking.date + '/' + this.localBooking.id + '/new-price.json', this.localBooking).then((response) => {
                    console.log(response)
                    this.newBooking = response.data
                })
            }
        }
    }
}
</script>