<template>
	<div v-if="info" class="game-page content-page">
		<div class="top" :style="coverstyle">
			<h1 class="title has-text-white has-text-centered">{{ info.teamname }}</h1>
			<h2 v-if="arena" class="subtitle has-text-white has-text-centered">
				{{ atranslate(arena, 'marketing.brandNames') }}
				{{ info.summary.settings.teamSize }} players

			</h2>

			<section class="container">
				<h5 v-if="info" class="subtitle is-size-5 has-text-white">{{ $t('gamepage-faster-than', {percentage: rank}) }}</h5>
				<div class="columns" style="align-items: stretch;">
					<div class="column is-half">

						<div v-if="stats" class="chart">
							<div class="bars">
								<div v-for="(bucket, index) in distribution" :key="'bar-' + index">
									<div :style="barstyle(bucket, index)" class="bar">
									</div>
								</div>
							</div>
						</div>

					</div>
					<div class="column is-half">
						<img class="teamphoto" :src="'/teampics/' + id + '/800.jpg'" />
						<a :href="'/teampics/' + id + '.jpg'" download="teampicture.jpg">
								<button class="button is-info">{{ $t('gamepage-download-picture') }}</button>
						</a>
					</div>
				</div>

			</section>
		</div>


		<section class="container mt-5">
			<div class="columns">
				<div class="column">
					<h3 class="subtitle is-size-3">{{ $t('gamepage-toplist-title', {arena: info.arena_handle}) }}</h3>
					<div v-for="(team, index) in toplist" :key="team.id" class="columns">
						<div class="column is-one-third">
							<router-link :to="'/' + language + '/team/' + team.id + '-' + team.slug + ''">
								<img class="teamphoto" :src="'/teampics/' + team.id + '/320.jpg'" />
							</router-link>
						</div>
						<div class="column">
							<router-link :to="'/' + language + '/team/' + team.id + '-' + team.slug + ''">
								<h5 class="subtitle is-size-5">#{{ index+1 }} - {{ sec2mmss(team.duration) }} {{ team.teamName }}</h5>
								<p>
									{{ team.date }} - {{ team.teamsize }}
								</p>
							</router-link>
						</div>
					</div>
				</div>

				<div class="column">
					<h3 class="subtitle is-size-3">{{ $t('gamepage-recent-games-title', {arena: info.arena_handle}) }}</h3>
					<div v-for="team in latest" :key="'lkjlj' + team.id" class="columns">
						<div class="column is-one-third">
							<router-link :to="'/' + language + '/team/' + team.id + '-' + team.slug + ''">
								<img class="teamphoto" :src="'/teampics/' + team.id + '/320.jpg'" />
							</router-link>
						</div>
						<div>
							<router-link :to="'/' + language + '/team/' + team.id + '-' + team.slug + ''">
								<h5 class="subtitle is-size-5">{{ sec2mmss(team.duration) }} {{ team.teamName }}</h5>
							</router-link>
						</div>
					</div>
				</div>
			</div>
			<button v-if="relatedCount == 3" @click="relatedCount = 100" class="button">Show more</button>
		</section>

	</div>
</template>
<script>
		import { store } from '../store'
    import axios from 'axios'
    export default {
        name: 'GamePage',
        data: function () {
            return {
							floor: 30,
							info: undefined,
							relatedCount: 3
            }
        },
				methods: {
					barstyle (bucket, index) {
						const style = {
							height: ((bucket / this.maxtime) * 100) + '%',
						}

						if (Math.floor(this.info.duration / 60) == index + this.floor) {
							style.background = 'white'
						}

						return style
					},
					loadData () {
            axios.get('/gameinfo/' + this.id + '/all.json').then((result) => {
							this.info = result.data.game
            })
					}
				},
        computed: {
            arena () {
                if (this.info && store.arenas && store.arenas[this.info.arena_handle]) {
                    return store.arenas[this.info.arena_handle]
                }
                return undefined
            },
					stats () {
                        let stats = {}
						if (store.arenas[this.info.arena_handle]) {
							stats = store.arenas[this.info.arena_handle].stats
						}

                        console.log('THis is the arena stats')
                        console.log(stats)

                        return {
                            ...{
                                toplist: [],
                                latest: [],
                                distribution: []
                            }, ...stats
                        }
					},
					rank () {
                        console.log('Computing rank')

                        if (!this.stats) { return '-' }
                        console.log('Enough stats to go!')

						let all_buckets = this.stats.distribution
						let all_buckets_sum = all_buckets.reduce((a, b) => a + b, 0)

						let our_bucket_index = Math.floor(this.info.duration / 60)

						let later_buckets = this.stats.distribution.slice(our_bucket_index, 1000)
						let later_buckets_sum = later_buckets.reduce((a, b) => a + b, 0)

                        console.log('Later buckets are ' + later_buckets_sum)
                        console.log('All buckets ' + all_buckets_sum)

						return Math.round((later_buckets_sum / all_buckets_sum) * 100)
					},
					customization () {
						return store.customization.generalDarkBg
					},
					coverstyle () {
						return {
							// backgroundImage: 'url("/apuz/venue/static/images/' + store.customization.generalDarkBg + '")'
							backgroundImage: 'url("/apuz/' + this.info.arena_handle + '/illustration/titlepage.webp")'
						}
					},
					language () {
						return store.language
					},
					toplist () {
                        console.log('Extracting toplist from ')
                        console.log(this.stats)
                        return this.stats.toplist.slice(0, this.relatedCount)
					},
					latest () {
						if (!this.latestTen) {
							return this.stats.latest.slice(0, this.relatedCount)
						}
                        return []
					},
					distribution () {
						return this.stats.distribution.slice(this.floor)
					},
					maxtime() { return Math.max(...this.distribution) },
					id: function () { return this.$route.params.id.split('-')[0] },
        },
				watch: {
					id () {
						this.loadData()
					}
				},
        mounted: function () {
					this.loadData()
        }
    }
</script>
<style>

.game-page .top {
	background-size: cover;
}
.game-page .top h1 {
	padding-top: 200px;
}
.game-page .top h2 {
	margin-bottom: 150px;
}

.game-page .teamphoto {
    width: 100%;
}

.game-page .chart {
	height: calc(100% - 40px);
}

.game-page .chart .bars {
	display: flex;
	height: 100%;
	flex-direction: row;
}

.game-page .chart .bars > div {
	position: relative;
	flex-grow: 1;
	margin-right: 2px;
}

.game-page .chart .bars .bar {
	background: var(--primary-color);
	background: rgba(255, 255, 255, 0.5);
	border-top-left-radius: 2px;
	border-top-right-radius: 2px;
	position: absolute;
	bottom: 0;
	width: 100%;
}

</style>
