<template>
    <router-link :to="'/' + language + '/arena/' + arena.handle" class="arena-stack-entry-link">
        <div class="arena-stack-entry">
            <img :src="'/apuz/' + arena.handle + '/illustration/titlepage.webp?w=240'" />

            <span v-if="arena.teamsize_min && arena.teamsize_max" class="teamsize">
                {{ arena.teamsize_min }} - {{ arena.teamsize_max }} deltagare<br />
                &#9733; &#9733; &star; &star; &star;
            </span>

            <h3>{{ arena.arena_name }}</h3>

            <p style="height: 90px;" v-if="arena.marketing && arena.marketing.shortStories">
                {{ arena.marketing.shortStories.en }}
                Read more
            </p>

            <h3>{{ $t('schedule-today-header')}}</h3>
            <ArenaScheduleToday :arena="arena"></ArenaScheduleToday>

            <br style="clear: both;" />
        </div>
    </router-link>
</template>

<script>
import { store } from '../store.js'
import ArenaScheduleToday from '../components/ArenaScheduleToday'
export default {
  name: 'ArenaStackEntry',
  props: ['arena'],
  components: {
      ArenaScheduleToday
  },
  computed: {
      language: function () {
          return store.language
      }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style>
    .arena-stack-entry-link {
        text-decoration: none;
    }

    .arena-stack-entry {
        border-top: 1px solid #ababab;
        background: #ededed;
        padding: 30px;
        clear: both;
        margin: 10vmin;
        box-shadow: 0 0 5px rgba(0, 0, 0, 0.5);
    }

    .arena-stack-entry img {
        height: 250px;
        width: 250px;
        object-fit: cover;
        float: left;
        margin-right: 10px;
        display: block;
    }

    a .arena-stack-entry {
        color: black;
        text-decoration: none;
    }

    .arena-stack-entry .teamsize {
        float: right;
    }

</style>
