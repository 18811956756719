import { render } from "./SplashHeader.vue?vue&type=template&id=f85c4bf8"
import script from "./SplashHeader.vue?vue&type=script&lang=js"
export * from "./SplashHeader.vue?vue&type=script&lang=js"

import "./SplashHeader.vue?vue&type=style&index=0&lang=css"
script.render = render
/* hot reload */
if (module.hot) {
  script.__hmrId = "f85c4bf8"
  const api = __VUE_HMR_RUNTIME__
  module.hot.accept()
  if (!api.createRecord('f85c4bf8', script)) {
    api.reload('f85c4bf8', script)
  }
  
  module.hot.accept("./SplashHeader.vue?vue&type=template&id=f85c4bf8", () => {
    api.rerender('f85c4bf8', render)
  })

}

script.__file = "src/components/SplashHeader.vue"

export default script