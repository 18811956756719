import { render } from "./EmailOffer.vue?vue&type=template&id=55e7ca50"
import script from "./EmailOffer.vue?vue&type=script&lang=js"
export * from "./EmailOffer.vue?vue&type=script&lang=js"
script.render = render
/* hot reload */
if (module.hot) {
  script.__hmrId = "55e7ca50"
  const api = __VUE_HMR_RUNTIME__
  module.hot.accept()
  if (!api.createRecord('55e7ca50', script)) {
    api.reload('55e7ca50', script)
  }
  
  module.hot.accept("./EmailOffer.vue?vue&type=template&id=55e7ca50", () => {
    api.rerender('55e7ca50', render)
  })

}

script.__file = "src/components/EmailOffer.vue"

export default script