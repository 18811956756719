<template>
    <div>
        <div class="timelist">
            <div v-for="slot in slots" :key="slot.startTime">
                {{ slot.startTime }}
            </div>
        </div>
    </div>
</template>
<script>
    import axios from 'axios'
    import dayjs from 'dayjs'
    export default {
        name: 'ArenaScheduleToday',
        props: ['arena'],
        data: function () {
            return {
                slots: []
            }
        },
        computed: {
        },
        mounted: function () {
            axios.get('/booking/slots/' + this.arena.handle + '/' + dayjs().format('YYYY-MM-DD') + '.json').then((response) => {
                this.slots = response.data.slots
            })
        }
    }
</script>
<style scoped>
    .timelist {
        display: flex;
        margin: 0 auto auto;
        max-width: 640px;
        text-align: center;
    }
    .timelist > div {
        flex-grow: 1;
    }
</style>