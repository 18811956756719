<template>
  <div v-if="arena" class="card mt-5">
    <div class="card-header">
      <p class="card-header-title">
        {{ s.startTime }}
        {{ $at(arena, 'marketing.brandNames', arena.arena_handle) }}
      </p>
    </div>
    <div class="card-content">
      <div class="columns">
        <div class="column is-4">
          <figure class="image is-3by2">
            <img :src="'/apuz/' + arena.handle + '/illustration/titlepage.webp?w=400'" />
          </figure>
        </div>
        <div class="column is-8">
          <div class="columns">
            <div class="column field">
              <label class="label">{{ $t('teamname')}}</label>
              <input class="input" v-model="teamname" :placeholder="$t('teamname-placeholder')" />
              <p class="help">{{ $t('teamname-helptext')}}</p>
            </div>
            <div class="column field">
              <label class="label">{{ $t('language')}}</label>
              <div class="select">
                <select v-model="language">
                  <option value="">{{ $t('no-language-selected') }}</option>
                  <option v-for="l in arena.languages" :value="l" :key="'language-' + l">{{ $t('language-' + l) }}</option>
                </select>
              </div>
            </div>
          </div>

          <p v-if="arena">
            {{ $at(arena, 'marketing.shortFacts') }}
          </p>

          <div v-if="showParticipants">
            <label class="label">
              {{ $t('booking-slot-participants-heading', {recommended: recommendedTeamSize, required: requiredTeamSize}) }}
            </label>
            <textarea class="textarea" v-model="participants" rows="2"></textarea>
            <p class="help">{{ $t('participants-helptext')}}</p>
          </div>
          <div v-else-if="booking.slots.length > 1">
            <span @click="showParticipants = true" class="is-link">{{ $t('show-participants-input') }}</span>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
  import { store } from '../store'
  export default {
		props: ['s', 'booking'],
		emits: ['update'],
    name: 'BookingSlot',

    computed: {
      recommendedTeamSize () {
        return this.arena['teamsize-min-recommended'] + '-' + this.arena['teamsize-max-recommended']
      },
      requiredTeamSize () {
        return this.arena['teamsize-min'] + '-' + this.arena['teamsize-max']
      },
      arena () {
				console.log('This is the booking arena')
				console.log(this.s)
        return this.s.arena
      },
      showParticipants: {
        get: function () { return store.showParticipants },
        set: function (active) {
          store.showParticipants = active
        }
      },
      teamname: {
        get: function () { return this.s.teamname },
        set: function (teamname) {
          // this.s.teamname = teamname
          this.$emit('update', { slot: this.s, teamname })
        }
      },
      language: {
        get: function () { return this.s.language || '' },
        set: function (language) {
          // this.s.language = language
          this.$emit('update', { slot: this.s, language })
        }
      },
      participants: {
        get: function () { return (this.s.participants || '').split("\n") },
        set: function (participants) {
          this.$emit('update', { slot: this.s, participants: participants.join("\n") })
        }
      }
    }
  }
</script>
<style>
  .booking-slot {
    border: 1px solid #ededed;
    box-shadow: 0 0 25px rgba(0, 0, 0, 0.05);
    border-radius: 5px;
    background: white;
    clear: both;
    margin-top: 25px;
    height: 150px;
    padding: 25px;
  }

  .booking-slot h3 {
    margin-top: 0;
    float: right;
  }

  .booking-slot .illustration {
    height: 100%;
    float: left;
    margin-right: 25px;
  }
</style>
