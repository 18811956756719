import { render } from "./SendEmail.vue?vue&type=template&id=51b9a3ac"
import script from "./SendEmail.vue?vue&type=script&lang=js"
export * from "./SendEmail.vue?vue&type=script&lang=js"
script.render = render
/* hot reload */
if (module.hot) {
  script.__hmrId = "51b9a3ac"
  const api = __VUE_HMR_RUNTIME__
  module.hot.accept()
  if (!api.createRecord('51b9a3ac', script)) {
    api.reload('51b9a3ac', script)
  }
  
  module.hot.accept("./SendEmail.vue?vue&type=template&id=51b9a3ac", () => {
    api.rerender('51b9a3ac', render)
  })

}

script.__file = "src/views/SendEmail.vue"

export default script