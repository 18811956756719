<template>
  <article v-for="a in entries" :key="a.title" class="message">
    <div @click="a.expanded = !a.expanded" class="message-header" style="cursor: pointer;">
      {{ a.title }}
    </div>
    <div v-if="a.expanded" class="message-body">
      {{ a.text }}
    </div>
  </article>
</template>
<script>
  import { faqs } from '../faq'
  import { store } from '../store'

  export default {
    name: 'FaqList',
    props: ['tag'],
    data () {
      return {
        entries: []
      }
    },
    mounted () {
      this.entries = faqs(store.language, this.tag)
    }
  }
</script>
