import { render } from "./BookingSteps.vue?vue&type=template&id=7beee77e"
import script from "./BookingSteps.vue?vue&type=script&lang=js"
export * from "./BookingSteps.vue?vue&type=script&lang=js"

import "./BookingSteps.vue?vue&type=style&index=0&lang=css"
script.render = render
/* hot reload */
if (module.hot) {
  script.__hmrId = "7beee77e"
  const api = __VUE_HMR_RUNTIME__
  module.hot.accept()
  if (!api.createRecord('7beee77e', script)) {
    api.reload('7beee77e', script)
  }
  
  module.hot.accept("./BookingSteps.vue?vue&type=template&id=7beee77e", () => {
    api.rerender('7beee77e', render)
  })

}

script.__file = "src/components/BookingSteps.vue"

export default script