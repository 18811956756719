import { render } from "./BookingEditTab.vue?vue&type=template&id=9d86787c"
import script from "./BookingEditTab.vue?vue&type=script&lang=js"
export * from "./BookingEditTab.vue?vue&type=script&lang=js"
script.render = render
/* hot reload */
if (module.hot) {
  script.__hmrId = "9d86787c"
  const api = __VUE_HMR_RUNTIME__
  module.hot.accept()
  if (!api.createRecord('9d86787c', script)) {
    api.reload('9d86787c', script)
  }
  
  module.hot.accept("./BookingEditTab.vue?vue&type=template&id=9d86787c", () => {
    api.rerender('9d86787c', render)
  })

}

script.__file = "src/components/BookingEditTab.vue"

export default script