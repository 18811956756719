<template>
    <div class="container mt-5 p-2">
        <div class="columns">
            <div class="column is-half">
                <div class="pb-2" >
                <span class="icon-text">
                    <span class ="icon">
                    <i class="fa fa-check" aria-hidded="true"></i>
                    </span>
                    <span>{{ $t('booking-pitch-cancel-header')  }}</span>
                </span>
                </div>
                <div>
                <span class="icon-text">
                    <span class ="icon">
                    <i class="fa fa-check" aria-hidded="true"></i>
                    </span>
                    <span>{{ $t('booking-pitch-pay-later-header') }}</span>
                </span>
                </div>
            </div>
            <div class="column is-half">
                <div class="pb-2">
                <span class="icon-text">
                    <span class ="icon">
                    <i class="fa fa-check" aria-hidded="true"></i>
                    </span>
                    <span>{{ $t('booking-pitch-edit-header') }}</span>
                </span>
                </div>

                <div>
                <span class="icon-text">
                    <span class ="icon">
                    <i class="fa fa-check" aria-hidded="true"></i>
                    </span>
                    <span>{{ $t('booking-pitch-offer-header') }}</span>
                </span>
                </div>
            </div>
		</div>
    </div>
</template>

<script>
export default {
    name: 'BookingPitch'
}
</script>