import { render } from "./BookingSearchForm.vue?vue&type=template&id=cf98fd76"
import script from "./BookingSearchForm.vue?vue&type=script&lang=js"
export * from "./BookingSearchForm.vue?vue&type=script&lang=js"

import "./BookingSearchForm.vue?vue&type=style&index=0&lang=css"
script.render = render
/* hot reload */
if (module.hot) {
  script.__hmrId = "cf98fd76"
  const api = __VUE_HMR_RUNTIME__
  module.hot.accept()
  if (!api.createRecord('cf98fd76', script)) {
    api.reload('cf98fd76', script)
  }
  
  module.hot.accept("./BookingSearchForm.vue?vue&type=template&id=cf98fd76", () => {
    api.rerender('cf98fd76', render)
  })

}

script.__file = "src/components/BookingSearchForm.vue"

export default script