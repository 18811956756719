
<template>
  <div class="teamphoto-collage" v-if="games.length > 0">
    <h1 v-if="arena" class="title has-text-white has-text-centered">{{ $t('arena-latest-teams', {arena: atranslate(arena, ['marketing', 'brandNames'])}) }}</h1>
    <h1 v-else class="title has-text-white has-text-centered">{{ $t('latest-teams') }}</h1>
    <div class="photos">
      <div v-for="g in games" :key="g.id" :style="g.style">
        <router-link :to="'/' + language + '/team/' + g.id + '-' + g.slug + ''">
          <img :src="'/teampics/' + g.id + '/350.jpg'" :style="g.imgstyle" width="350" height="197" />
          <h4>{{ g.teamname }}</h4>
        </router-link>
      </div>
    </div>
  </div>
</template>

<script>
import axios from 'axios'
import { store } from '../store'

export default {
  name: 'TeamphotoCollage',
  data: function () {
    return {
      games: []
    }
  },
  props: ['arena'],
  computed: {
    language: function () {
      return store.language
    },
    arenas: function () {
      return store.arenas
    },
    sortedArenas: function () {
      return Object.keys(this.arenas).sort.map((handle) => this.arenas[handle])
    }
  },
  methods: {
    toSlug (str) {
      str = str.replace(/^\s+|\s+$/g, ''); // trim
      str = str.toLowerCase();

      // remove accents, swap ñ for n, etc
      const from = "àáäâèéëêìíïîòóöôùúüûñç·/_,:;";
      const to   = "aaaaeeeeiiiioooouuuunc------";
      for (var i=0, l=from.length ; i<l ; i++) {
          str = str.replace(new RegExp(from.charAt(i), 'g'), to.charAt(i));
      }

      str = str.replace(/[^a-z0-9 -]/g, '') // remove invalid chars
          .replace(/\s+/g, '-') // collapse whitespace and replace by -
          .replace(/-+/g, '-'); // collapse dashes

      return str;
    }
  },
  mounted: function () {
    let url = '/gameinfo/latest.json?hasTeampic=true'

    if (this.arena) {
      url += '&arena=' + this.arena.handle
    }

    axios.get(url).then((result) => {
      let zindex = 500;
      this.games = result.data.games.filter(g => g.hasTeampic).slice(0, 6).map(g => {
        g.style = 'transform: rotate(' + ((Math.random()-0.5)*15) + 'deg) scale(' + (Math.random()*0.3 + 0.9) + ');'
        g.style += ' z-index: ' + (zindex--) + ';'
        g.slug = 'here-be-teamname-slug'
        g.slug = this.toSlug(g.teamname)
        return g
      })
    })
  }
}
</script>
<style>
.teamphoto-collage {
  overflow-x: hidden;
  overflow-y: hidden;
	max-width: 1250px;
	padding: 25px;
	margin: 0 auto auto;
}

.teamphoto-collage .photos {
	padding: 20px;
  padding-top: 75px;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
}

@media only screen and (max-width: 768px) {
	.teamphoto-collage .photos {
		grid-template-columns: 1fr 1fr;
	}
}

.teamphoto-collage a {
  text-decoration: none;
  color: black;
  text-align: center;
}

.teamphoto-collage .photos div {
  padding: 7px;
  background: #ededed;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.9);
}

.teamphoto-collage .photos img {
  width: 100%;
}

</style>
