<template>
    <div class="content-page">
        <EmailSender v-if="booking" @sent="onSent" :type="type" :recipients="recipients" :booking="booking"></EmailSender>
        <p v-else>
          A technical error has occured. Booking data not yet loaded.

          ERR_BOOKING_NOT_LOADED
        </p>
    </div>
</template>
<script>
    import axios from 'axios'
    import { store } from '../store'
    import EmailSender from '../components/EmailSender'
    export default {
        name: 'SendEmail',
        data () {
            return {
                booking: undefined,
                sent: false
            }
        },
        components: {
            EmailSender
        },
        computed: {
            id: function () { return this.$route.params.id },
            date: function () { return this.$route.params.date },
            type: function () { return this.$route.query.type },
            autoclose: function () { return this.$route.query.autoclose },
            recipients: function () { return [this.$route.query.sendTo] }
        },
        methods: {
            onSent () {
                setTimeout(() => {
                    if (this.autoclose) {
                        console.log('Closing window')
                        // window.close()
                    }
                }, 2500)
            }
        },
        mounted () {
            store.zenmode = true

            if (this.id == 'no-booking') {
                this.booking = {}
                return
            }

            axios.get('/booking/' + this.date + '/' + this.id + '.json').then(async result => {
                this.booking = result.data

                store.arenasLoaded.then(() => {
                  for (let s of this.booking.slots) {
                    s.arena = store.getArenaByHandle(s.arena)

                    console.log('Got arena by handle, this is what we got')
                    console.log(s.arena)
                  }
                })
            })

        }
    }
</script>
