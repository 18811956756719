import { render } from "./PurchaseGiftCardPage.vue?vue&type=template&id=45ca1dea"
import script from "./PurchaseGiftCardPage.vue?vue&type=script&lang=js"
export * from "./PurchaseGiftCardPage.vue?vue&type=script&lang=js"

import "./PurchaseGiftCardPage.vue?vue&type=style&index=0&lang=css"
script.render = render
/* hot reload */
if (module.hot) {
  script.__hmrId = "45ca1dea"
  const api = __VUE_HMR_RUNTIME__
  module.hot.accept()
  if (!api.createRecord('45ca1dea', script)) {
    api.reload('45ca1dea', script)
  }
  
  module.hot.accept("./PurchaseGiftCardPage.vue?vue&type=template&id=45ca1dea", () => {
    api.rerender('45ca1dea', render)
  })

}

script.__file = "src/views/PurchaseGiftCardPage.vue"

export default script