<template>
  <div class="columns is-centered">
    <div class="column is-12-mobile is-8-tablet is-6-desktop" v-for="a in arenas" :key="a.handle">
      <div @click="toggleCard(a.handle)" class="card" :class="currentCombo.includes(a.handle) ? '' : 'deselectedArena'">
        <div class="card-image-container">
          <img class="card-image" :src="'/apuz/' + a.handle + '/illustration/titlepage.webp?w=480'" />
        </div>
        <div class="card-content">
          <p class="title is-4 has-text-white arena-title">
            {{ $at(a, 'marketing.brandNames', a.arena_name) }}
          </p>
          <div class="arena-information">
            <p class="icon-text">
              <span class="icon"><i class="fas fa-users"></i></span>
              <span> {{ a["teamsize-min-recommended"] + " - " + a["teamsize-max-recommended"] }} </span>
            </p>
            <p class="icon-text" v-if="a['marketing'].successRate">
              <span class="icon"><i class="fa fa-tachometer-alt"></i></span>
              <span>{{ a["marketing"].successRate }}%</span>
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="container">
    <div class="is-flex is-justify-content-center m-2">
      <div v-for="(group, index) in audience['group-templates']" :key="index">
        <div class="button is-medium mx-2 has-text-weight-medium" :class="chosenTab == index ? 'is-info' : 'is-primary'" @click="selectTab(index)">
          {{ group["label"][language] }}
        </div>
      </div>
    </div>
    <div>
      <div v-show="currentCombo.length >= 1">
        <p class="has-text-weight-medium">{{ $t('arena-select-has-players-text', { teamcount: currentCombo.length, arenas: currentArenasNames, recmax: currentSelectedArenasRecMax, absmax: currentSelectedArenasAbsMax }) }}</p>
        <p v-if="currentCombo.length > 1" class="has-text-white has-text-weight-medium">{{ $t('arena-select-deselect-if-less-text', { recmax: (currentSelectedArenasRecMax - getCurrentSmallestArena[1]), smallestarena: arenaName(getCurrentSmallestArena[0]) }) }}</p>
      </div>
      <div v-show="currentCombo.length < 1">
        <p class="has-text-weight-medium">{{ $t('arena-select-no-players-text') }}</p>
        <p class="has-text-weight-medium">{{ $t('arena-select-no-players-information') }}</p>
      </div>
    </div>
  </div>
</template>
<script>
import { store } from "../../store.js";

export default {
  name: "ArenaSelectBlock",
  data() {
    return {
      chosenTab: 0,
      currentCombo: [],
      transitioning: false,
      transitionTimeout: null,
      currentComboIndex: 0,
    };
  },

  props: {
    audience: Object
  },
  computed: {
    language() {
      return store.language;
    },
    arenas() {
      return store.arenas;
    },
    showHasPlayersText() {
      return this.currentCombo.length >= 1;
    },
    showSelectDeselectText() {
      return !this.transitioning && this.currentCombo.length < 1;
    },
    currentSelectedArenasRecMax() {
      return this.countCurrentCombo("teamsize-max-recommended")
    },
    currentSelectedArenasAbsMax() {
      return this.countCurrentCombo("teamsize-max")
    },
    currentArenasNames() {
      let string = '';
      this.currentCombo.forEach((arena, index) => {
        if (index > 0 && index === this.currentCombo.length - 1) {
          string += " and ";
        } else if (index > 0) {
          string += ", ";
        }
        string += this.arenaName(arena);
      });
      return string;
    },
    getCurrentSmallestArena() {
      let smallestArena = ''
      let smallestArenaCount = Infinity

      this.currentCombo.forEach(combo => {
        if (this.arenas[combo]['teamsize-max-recommended'] < smallestArenaCount) {
          smallestArena = combo
          smallestArenaCount = this.arenas[combo]['teamsize-max-recommended']
        }
      });
      return [smallestArena, smallestArenaCount]
    },
    combinedCombos() {
      let templates = this.audience['group-templates']

      for (let combotype of ['combos', 'badCombos']) {
        if (!templates[this.chosenTab] || !templates[this.chosenTab][combotype]) {
          console.error('Failed to find ' + combotype + ' for ' + this.chosenTab + '. audience is ')
          console.log(this.audience)
          return []
        }
      }
      return templates[this.chosenTab]["combos"].concat(templates[this.chosenTab]["badCombos"])
    }
  },
  methods: {
    computeVisibility(){
      return true
    },
    selectTab(index) {
      if (this.transitioning) {
        clearTimeout(this.transitionTimeout);
        this.transitioning = false;
      }
      this.transitioning = true;

      if (this.chosenTab !== index) {
        this.chosenTab = index;
        this.currentComboIndex = 0;
      }
      else {
        this.currentComboIndex = (this.currentComboIndex + 1) % this.combinedCombos.length;
      }

      this.currentCombo = []

      this.transitionTimeout = setTimeout(() => {
        this.currentCombo = this.combinedCombos[this.currentComboIndex];
        this.transitioning = false;
      }, 500);
    },
    countCurrentCombo(teamsizeToCount) {
      let count = 0
      this.currentCombo.forEach(combo => {
        count += this.arenas[combo][teamsizeToCount]
      });
      return count
    },
    toggleCard(handle) {
      this.chosenTab = -1;
      const indexInArray = this.currentCombo.indexOf(handle);
      if (indexInArray > -1) {
        this.currentCombo = this.currentCombo.filter((item) => item !== handle);
      } else {
        this.currentCombo = [...this.currentCombo, handle];
      }
    },
    arenaName(comboHandle) {
      return store.arenas[comboHandle]["arena_name"];
    },
  },
  mounted() {
    this.selectTab(this.chosenTab);
  },
};
</script>

<style scoped>
.column {
  flex: 1;
  max-width: 20rem;
  align-content: flex-end;
}

.columns {
  display: flex;
  justify-content: space-around;
  min-height: 35rem;
}

.card-image-container {
  position: absolute;
  width: 100%;
  height: 100%;
  z-index: 1;
}

.card-image {
  object-fit: cover;
  height: 100%;
  width: 100%;
}

.card {
  position: relative;
  height: 30rem;
  overflow: hidden;
  transition: height 0.5s ease;
}

.card-content {
  position: absolute;
  z-index: 2;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  color: white;
}

.arena-information {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  opacity: 100%;
  transition: opacity 0.5s ease;
}

.arena-title {
  align-self: center;
  padding-top: 2rem;
}

.deselectedArena {
  height: 20rem;
  filter: grayscale(0.6);
}

.deselectedArena .arena-information {
  opacity: 0;
}

.deselectedArena .arena-title {
  opacity: 0;
}

@media (max-width: 768px) {
  .section {
    padding-top: 5vh;
    padding-bottom: 5vh;
  }

  .column {
    flex: 1;
    max-width: 100%;
    margin-bottom: 1rem;
  }

  .columns {
    flex-wrap: wrap;
  }

  .card {
    height: 25rem;
  }

  .deselectedArena {
    height: 25rem;
  }

  .is-flex.is-justify-content-center {
    flex-wrap: wrap;
  }

  .button {
    width: 100%;
    margin: 0.5rem 0;
  }
}
</style>
