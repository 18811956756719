<template>
  <div>
    <Menu v-if="!zenmode"></Menu>

    <router-view></router-view>

    <div class="footer" :style="footerstyle" v-if="!zenmode && venue">
      <div class="container is-max-desktop">
        <div class="columns">
          <div class="column">
            <div class="pb-5">
              <img src="/apuz/venuelogo/banner/bright/transparent.svg" style="height: 8em; max-height: 8rem;" />
            </div>
            <div class="columns is-one-third">
              <div class="column">
                <span v-if="venue.publicdetails && venue.publicdetails.displayphonenumber">
                  <a :href="'tel:' + venue.publicdetails.phonenumber">
                    <i class="fa fa-phone-alt"></i> {{ venue.publicdetails.displayphonenumber }}
                  </a>
                </span><br />
                <span v-if="venue.publicdetails && venue.publicdetails.email">
                  <a :href="'mailto:' + venue.publicdetails.email">
                    <i class="fa fa-at"></i> {{ venue.publicdetails.email }}
                  </a>
                </span><br />
                <span v-if="venue.publicdetails && venue.publicdetails.businessname">
                  <i class="fa fa-envelope"></i> {{ venue.publicdetails.businessname }}<br />
                  <small>{{ venue.publicdetails.postaladdress }}</small>
                </span>
              </div>
            </div>
          </div>
          <div class="column is-two-thirds">
            <a :href="mapHref" target="_blank">
              <figure>
                <img :src="mapSrc" class="logo is-fullwidth" />
              </figure>
            </a>
            <div class="is-size-5 has-text-weight-semibold">
              <span class="icon">
                <i class="fas fa-map-marker-alt"></i>
              </span>
              {{ venue.publicdetails.streetaddress }},
              {{ venue.publicdetails.zipcode }}
              {{ venue.publicdetails.city }}
            </div>
            <div class="columns">
              <div class="column">
                <h4 class="title is-5 has-text-white mt-3">
                  <i class="fa fa-bus-alt"></i>
                  {{ $t('find-us-public-transport-header') }}
                </h4>
                <p>{{ $t('find-us-public-transport-text') }}</p>
              </div>
              <div class="column">
                <h4 class="title is-5 has-text-white mt-3">
                  <i class="fa fa-car"></i>
                  {{ $t('find-us-car-header') }}
                </h4>
                <p>{{ $t('find-us-car-text') }}</p>
              </div>
            </div>
          </div>
        </div>
        <div class="columns mt-6 is-centered">
          <div v-for="site in socialmedia" class="column is-centered has-text-centered" :key="site.handle">
            <a :href="site.url" target="_blank">
              <i v-if="site.faicon" class="fab" :class="site.faicon"></i>
              &nbsp; {{ site.label }}
              </a>
          </div>
        </div>
        <p class="has-text-centered">
            &copy; {{ year }} {{ venue['business-name'] }}
        </p>
      </div>
    </div>

  </div>
</template>

<script>
import Menu from './Menu.vue'
import { store } from '../store'
import dayjs from 'dayjs'

export default {
  name: 'VueWeb',
  data: function ()  {
    return {
//      googleApiKey: 'AIzaSyB_X1ULOR31XDEcMKL9UWK4PrkMSCy_fgs',
      googleApiKey: 'AIzaSyC-a9O6soxnFgOe5PcILRmzK1CcUr7WMrM'
    }
  },
  props: {
    msg: String
  },
  computed: {
    zenmode () { return store.zenmode },
    footerstyle () {
      return 'background-image: url("/apuz/venue/static/images/' + store.customization.footerbg + '");'
    },
    year () {
      return dayjs().format('YYYY')
    },
    address: function () {
      return [
        this.venue.publicdetails.streetaddress,
        this.venue.publicdetails.zipcode,
        this.venue.publicdetails.city
      ].join(' ')
    },
    venue: function () {
      return store.venue
    },
    socialmedia () {
      if (!this.venue.socialmedia) {
        console.error('This venue does not have any social media, that\'s bad!')
        return []
      }
      const brands = {
        linkedin: "LinkedIn"
      }
      const faicons = {
        'linkedin': 'fa-linkedin',
        'facebook': 'fa-facebook',
        'tripadvisor': 'fa-tripadvisor',
        'instagram': 'fa-instagram'
      }

      return Object.keys(this.venue.socialmedia).map(brand => {
        let entry = {
          url: this.venue.socialmedia[brand].url,
          handle: brand,
          label: brands[brand] || brand.replace(/^\w/, (c) => c.toUpperCase())
        }

        if (brand in faicons) {
          entry.faicon = faicons[brand]
        }

        return entry
      }).filter(b => b.url)
    },
    mapHref: function () {
      return 'https://www.google.com/maps/search/?api=1&query=' + this.address
    },
    mapSrc: function () {
      let endpoint = 'https://maps.googleapis.com/maps/api/staticmap?'
      let params = {
        zoom: 14,
        size: '600x300',
        maptype: 'roadmap',
        key: this.googleApiKey,
        markers: 'color:red|label:' + this.venue.name + '|' + this.address
      }

      return endpoint + Object.keys(params).map(k => encodeURIComponent(k) + '=' + encodeURIComponent(params[k])).join('&')
    }
  },
  components: {
    Menu
  }
}
</script>
<style scoped>
  a {
    color: white;
  }

  .introbox {
    height: 30vh;
    background: #565698;
    color: white;
    text-align: center;
    font-size: 30px;
    display: flex;
    align-items: center;
    text-align: center;
    padding: 10vw;
    padding-top: 300px;
  }

  .footer {
    background: #121212;
    background-size: cover;
    color: white;
  }

  .footer .content {
    max-width: 1200px;
    margin: 0 auto auto;
    display: flex;
  }

  .footer .content .right .logo {
    border: 4px solid white;
    width: 80%;
  }
</style>
<style>
  .error-block {
    background: #a62325;
    color: white;
    padding: 5vmin;
  }
</style>
