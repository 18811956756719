import { render } from "./GamePage.vue?vue&type=template&id=706db35b"
import script from "./GamePage.vue?vue&type=script&lang=js"
export * from "./GamePage.vue?vue&type=script&lang=js"

import "./GamePage.vue?vue&type=style&index=0&lang=css"
script.render = render
/* hot reload */
if (module.hot) {
  script.__hmrId = "706db35b"
  const api = __VUE_HMR_RUNTIME__
  module.hot.accept()
  if (!api.createRecord('706db35b', script)) {
    api.reload('706db35b', script)
  }
  
  module.hot.accept("./GamePage.vue?vue&type=template&id=706db35b", () => {
    api.rerender('706db35b', render)
  })

}

script.__file = "src/views/GamePage.vue"

export default script