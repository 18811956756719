<template>
  <div class="container mt-5 p-2">
    <div class="content has-text-centered">
      <h1 class="title">{{ $t('age-block-header') }}</h1>
    </div>
    <div class="fixed-grid has-4-cols-desktop has-1-cols-mobile">
      <div class="grid">
      <div
        class="cell"
        v-for="(ageGroup, index) in ageGroups"
        :key="index">
        <div class="box is-flex is-flex-direction-column is-justify-content-space-between">
          <h3 class="title has is-1 has-text-centered m-0 pb-2 has-text-info">
            {{ $t(ageGroup.header) + " " + $t('age-block-years') }}
          </h3>
          <div v-for="info, index in ageGroup.info" class="icon-text" :key="index">
            <div class="icon">
              <i :class="'fas fa-' + info.icon"></i>
            </div>
            <p class="is-dark is-size-7">{{ $t(info.text) }}</p>
          </div>
          <div class="icon-text">
            <div class="icon">
              <i class="fas fa-credit-card"></i>
            </div>
            <p class="is-dark">{{ $t(ageGroup.pricing) }}</p>
          </div>
        </div>
      </div>
    </div>
    </div>
    <div class="content has-text-centered has-text-black">
      <p class="subtitle">{{ $t('age-block-extra-age-info') }}</p>
      <p class="subtitle is-size-6">{{ $t('age-block-another-activity') }}</p>
    </div>
  </div>
</template>
<script>
export default {
  name: 'AgeGuideBlock',
  data() {
    return {
      ageGroups: [
        {
          class: 'infant',
          header: 'age-block-infant-header',
          pricing: 'age-block-pricing-free',
          info: [
            { icon: "check", text: "age-block-follow-along-text" },
          ]
        },
        {
          class: 'toddler',
          header: 'age-block-toddler-header',
          pricing: 'age-block-pricing-free',
          info: [
            { icon: "exclamation", text: "age-block-distracted-scared-text" },
            { icon: "exclamation", text: "age-block-hard-to-grasp-text" },
          ]
        },
        {
          class: 'kids',
          header: 'age-block-kids-header',
          pricing: 'age-block-pricing-child',
          info: [
            { icon: "exclamation", text: "age-block-struggles-problems-text" },
            { icon: "check", text: "age-block-understands-text" },
          ]
        },
        {
          class: 'teen',
          header: 'age-block-teen-header',
          pricing: 'age-block-pricing-student',
          info: [
            { icon: "exclamation", text: "age-block-lack-patience-text" },
            { icon: "check", text: "age-block-solving-independently-text" },
          ]
        }
      ]
    }
  },
  methods: {
    computeVisibility(audience) {
      for (let tpl of audience['group-templates']) {
        if (tpl.participants.children > 0) {
          return true
        }
      }
      return false
    },
    defaultTheme(){
      return 'dark'
    }
  }
}
</script>
<style scoped>
.box {
  height: 100%;
}
</style>