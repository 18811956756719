import { render } from "./AudienceLandingPage.vue?vue&type=template&id=5ce98d50"
import script from "./AudienceLandingPage.vue?vue&type=script&lang=js"
export * from "./AudienceLandingPage.vue?vue&type=script&lang=js"
script.render = render
/* hot reload */
if (module.hot) {
  script.__hmrId = "5ce98d50"
  const api = __VUE_HMR_RUNTIME__
  module.hot.accept()
  if (!api.createRecord('5ce98d50', script)) {
    api.reload('5ce98d50', script)
  }
  
  module.hot.accept("./AudienceLandingPage.vue?vue&type=template&id=5ce98d50", () => {
    api.rerender('5ce98d50', render)
  })

}

script.__file = "src/views/AudienceLandingPage.vue"

export default script