import { render } from "./StartPage.vue?vue&type=template&id=f1961e72"
import script from "./StartPage.vue?vue&type=script&lang=js"
export * from "./StartPage.vue?vue&type=script&lang=js"

import "./StartPage.vue?vue&type=style&index=0&lang=css"
script.render = render
/* hot reload */
if (module.hot) {
  script.__hmrId = "f1961e72"
  const api = __VUE_HMR_RUNTIME__
  module.hot.accept()
  if (!api.createRecord('f1961e72', script)) {
    api.reload('f1961e72', script)
  }
  
  module.hot.accept("./StartPage.vue?vue&type=template&id=f1961e72", () => {
    api.rerender('f1961e72', render)
  })

}

script.__file = "src/views/StartPage.vue"

export default script