import dayjs from 'dayjs'
import axios from 'axios'
import { store } from './store.js'

export class BookingApi {
  static search (fields) {
    fields = JSON.parse(JSON.stringify(fields))
    if (!fields.date) {
      fields.date = dayjs().format('YYYY-MM-DD')
    }

    return new Promise((resolve) => {
      console.log('Loading bookings')
      axios.get('/booking/' + fields.date + '/offers.json', { params: fields }).then((response) => {
        resolve(response.data.offerings.map(o => {
          o.slots.map(s => {
            s.arenaObj = store.arenas[s.arena]
            return s
          })
          return o
        }))
      })
    })
  }
}
