<template>
  <div class="offer-pdf">
    <div class="padded">
      <div>
        <img src="/apuz/venuelogo/banner/dark/transparent.svg" class="is-pulled-right" style="height: 4em; max-height: 5rem; max-width: 75vw;" />
        <h1 class="title" v-html="$t('offer-title', {date: booking.date })"></h1>
      </div>
      <div class="is-clearfix"></div>
      <p v-html="$t('offer-common-intro', {
          min_players: teamsizeInfo.min_players,
          max_players: teamsizeInfo.max_players,
          total_participants: teamsizeInfo.total_participants,
          absolute_max_players: teamsizeInfo.absolute_max_players
        })"></p>
      <br style="clear: both;" />
      <div class="container">
        <div class="columns">
          <div class="column is-half">
            <h2 class="subtitle">{{ $t('offer-details-header') }}</h2>
            <table>
              <tr><td>{{ $t('offer-date') }}</td><td class="has-text-right">{{ today }}</td></tr>
              <tr><td>{{ $t('offer-reference-no') }}</td><td class="has-text-right">{{ booking.id }}</td></tr>
              <tr><td>{{ $t('offer-valid-until') }}</td><td class="has-text-right">{{ booking.reservation_expiry }}</td></tr>
              <tr><td>{{ $t('offer-your-reference') }}</td><td class="has-text-right">{{ booking.booker.name }}</td></tr>
            </table>
          </div>
          <div class="column is-half">
            <h2 class="subtitle">{{ $t('offer-accept-header') }}</h2>
            <ol>
              <li v-html="$t('offer-accept-step-1', {url: venue.public_web_url})"></li>

              <li v-html="$t('offer-accept-step-2', {
                  menu: $t('menu-booking'),
                  submenu: $t('menu-manage-booking')
                })
              "></li>

              <li v-html="$t('offer-accept-step-3', {
                date: booking.date,
                id: booking.id
              })
              "></li>

              <li v-html="$t('offer-accept-step-4', { button: $t('offer-accept-confirm-button')} )"></li>
            </ol>
          </div>
        </div>
      </div>

      <hr />

      <table class="slottable">
        <thead>
          <tr>
            <th>{{ $t('start-time') }}</th>
            <th>{{ $t('end-time-approximate') }}</th>
            <th>{{ $t('challenge') }}</th>
            <th>{{ $t('arena-capacity') }}</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="s in booking.slots" :key="s.id">
            <template v-if="s.arena">
              <td>{{ s.startTime }}</td>
              <td>{{ s.endTime }}</td>
              <td>{{ atranslate(s.arena, 'marketing.brandNames') }}</td>
              <td>
                {{ s.arena['teamsize-min'] }}-{{ s.arena['teamsize-max'] }},
                allra bäst för {{ s.arena['teamsize-min-recommended'] }}-{{ s.arena['teamsize-max-recommended'] }}
              </td>
            </template>
          </tr>
        </tbody>
      </table>
      <p v-if="teamsizeInfo.total_participants > 10">
        {{ $t('arrival-info-large') }}
      </p>
      <p v-else>
        {{ $t('arrival-info-small') }}
      </p>

      <table class="pricetable">
        <tr>
          <th>{{ $t('offer-excl-vat-amount') }}</th>
          <td class="has-text-right">{{ booking.price * 0.8 }}kr</td>
          <th>{{ $t('offer-vat') }}</th>
          <td class="has-text-right">{{ booking.price * 0.2 }}kr</td>
          <th>{{ $t('offer-total-amount') }}</th>
          <td class="has-text-right">{{ booking.price }}kr</td>
        </tr>
      </table>
      <p>
        This price is valid for {{ teamsizeInfo.total_participants }} employees. Prices will be adjusted if the number of participants is changed.
      </p>

      <hr />

      <h2 class="subtitle">{{ $t('offer-expiry-header', {date: booking.reservation_expiry} )}}</h2>
      <p>
        {{ $t('offer-expiry-text', {date: booking.reservation_expiry}) }}
      </p>

      <footer>
        <h3 class="subtitle">{{ $t('offer-message-title') }}</h3>
        <div class="columns">
          <div v-if="venue.publicdetails.operationsmanagerpicture && venue.publicdetails.operationsmanagerpicture.length > 1" class="column is-one-quarter">
            <figure class="image is-square">
              <img :src="'/apuz/venue/static/images/' + venue.publicdetails.operationsmanagerpicture + '?w=240'" style="border-radius: 4px" />
            </figure>
            {{ venue.publicdetails.operationsmanager }}, {{ $t('operations-manager') }}
          </div>
          <div class="column">
            <p>
              {{ $t('offer-message-from-operationsmanager') }}
            </p>
            <div style="padding-top: 5mm;">
              {{ venue.publicdetails.email }}, {{ venue.publicdetails.displayphonenumber }}, {{ venue.public_web_url }}
            </div>
          </div>
        </div>

        <div class="columns">
          <div class="column is-half">
            <h5 class="subtitle">Besöksadress</h5>
            {{ venue.name }}<br />
            {{ venue.publicdetails.streetaddress }}<br />
            {{ $t('find-us-close-to') }}
          </div>
          <div class="column is-half">
            <h5 class="subtitle">Postadress</h5>
            {{ venue.publicdetails.businessname }}<br />
            {{ venue.publicdetails.postaladdress }}
          </div>
        </div>
      </footer>
    </div>
    <div class="bottomline"></div>
  </div>
<!--  <info-sheet /> -->
</template>
<script>
  import { store } from '../store'
  import dayjs from 'dayjs'

  export default {
      props: ['booking', 'mode'],
      computed: {
          firstname () {
            let parts = this.booking.booker.name.split(' ')
            return parts[0].length > 2 ? parts[0] : this.booking.booker.name
          },
          today () {
            return dayjs().format('YYYY-MM-DD')
          },
          ready () {
              return store && store.customization
          },
          venue: function () {
              return store.venue
          },
          primarycolor () {
              return store.venue['graphical-profile'].primarycolor
          },
          bookingPageUrl () {
              return window.location.origin + '/' + store.language + '/booking-details/' + this.booking.date + '/' + this.booking.id
          },
          images () {
              let imgs = {}
              for (let s of this.booking.slots) {
                  imgs[s.arena.handle + '.jpg'] = {
                      preview: '/apuz/' + s.arena.handle + '/illustration/titlepage.webp',
                      path: '/mnt/gamerunner/arenas/' + s.arena.handle + '/images/en/titlepage.jpg',
                      cid: 'cid:' + s.arena.handle + '.jpg'
                  }
              }
              return imgs
          },
          teamsizeInfo () {
              return {
                  min_players: this.booking.teamsize_min_recommended,
                  absolute_min_players: this.booking.teamsize_min,
                  max_players: this.booking.teamsize_max_recommended,
									absolute_max_players: this.booking.teamsize_max,
									total_participants: Object.values(this.booking.participants).reduce((a, b) => a + b, 0)
              }
          },
          practicalInfo () {
              return {
                  arrival_minutes: 10
              }
          }
      },
      components: {
      },
      methods: {
      },
      watch: {
      },
      mounted () {

      }
  }
</script>
<style scoped="true">
  @media print {
    html, body {
      margin: 0;
    }
  }


  .offer-pdf {
    font-size: 4mm;
  }

  .offer-pdf .subtitle {
    margin-bottom: 2mm !important;
  }

  .offer-pdf footer {
    position: absolute;
    bottom: 10mm;
    right: 15mm;
    left: 15mm;
  }

  .offer-pdf .bottomline {
    background: var(--primary-color);
    height: 7.5mm;
    width: 210mm;
    position: absolute;
    bottom: 0;
    left: 0;
  }

  .offer-pdf .padded {
    padding: 15mm;
  }

  .offer-pdf hr {
    border-top: 2px solid var(--primary-color);
    clear: both;
    margin-right: 0 !important;
    margin-left: 0 !important;
    margin-top: 4mm !important;
    margin-bottom: 4mm !important;
  }

  .offer-pdf .slottable {
    width: 100%;
  }

  .offer-pdf .pricetable {
    margin-top: 8mm;
  }

  .offer-pdf .pricetable th {
    padding-right: 5mm;
  }

  .offer-pdf .pricetable td {
    padding-right: 20mm;
  }

</style>
