import { render } from "./GiftCertificate.vue?vue&type=template&id=07a88106&scoped=true"
import script from "./GiftCertificate.vue?vue&type=script&lang=js"
export * from "./GiftCertificate.vue?vue&type=script&lang=js"

import "./GiftCertificate.vue?vue&type=style&index=0&lang=css"
import "./GiftCertificate.vue?vue&type=style&index=1&id=07a88106&scoped=true&lang=css"
script.render = render
script.__scopeId = "data-v-07a88106"
/* hot reload */
if (module.hot) {
  script.__hmrId = "07a88106"
  const api = __VUE_HMR_RUNTIME__
  module.hot.accept()
  if (!api.createRecord('07a88106', script)) {
    api.reload('07a88106', script)
  }
  
  module.hot.accept("./GiftCertificate.vue?vue&type=template&id=07a88106&scoped=true", () => {
    api.rerender('07a88106', render)
  })

}

script.__file = "src/views/GiftCertificate.vue"

export default script