import { render } from "./TeamPicturesBlock.vue?vue&type=template&id=6add14bc&scoped=true"
import script from "./TeamPicturesBlock.vue?vue&type=script&lang=js"
export * from "./TeamPicturesBlock.vue?vue&type=script&lang=js"

import "./TeamPicturesBlock.vue?vue&type=style&index=0&id=6add14bc&scoped=true&lang=css"
script.render = render
script.__scopeId = "data-v-6add14bc"
/* hot reload */
if (module.hot) {
  script.__hmrId = "6add14bc"
  const api = __VUE_HMR_RUNTIME__
  module.hot.accept()
  if (!api.createRecord('6add14bc', script)) {
    api.reload('6add14bc', script)
  }
  
  module.hot.accept("./TeamPicturesBlock.vue?vue&type=template&id=6add14bc&scoped=true", () => {
    api.rerender('6add14bc', render)
  })

}

script.__file = "src/components/blocks/TeamPicturesBlock.vue"

export default script