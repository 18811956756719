import { render } from "./TodayPage.vue?vue&type=template&id=8b3906b4&scoped=true"
import script from "./TodayPage.vue?vue&type=script&lang=js"
export * from "./TodayPage.vue?vue&type=script&lang=js"

import "./TodayPage.vue?vue&type=style&index=0&id=8b3906b4&scoped=true&lang=css"
script.render = render
script.__scopeId = "data-v-8b3906b4"
/* hot reload */
if (module.hot) {
  script.__hmrId = "8b3906b4"
  const api = __VUE_HMR_RUNTIME__
  module.hot.accept()
  if (!api.createRecord('8b3906b4', script)) {
    api.reload('8b3906b4', script)
  }
  
  module.hot.accept("./TodayPage.vue?vue&type=template&id=8b3906b4&scoped=true", () => {
    api.rerender('8b3906b4', render)
  })

}

script.__file = "src/views/TodayPage.vue"

export default script